import { DimensionListWithCodes } from "./DimensionListWithCodes";

const facilityTypes: Record<string, string> = {
  "1": "Limited access highway",
  "2": "Principal arterial",
  "3": "Major arterial",
  "4": "Minor arterial",
  "5": "Major collector",
  "6": "Minor collector",
  "7": "Local street",
  "8": "Service road",
  "9": "Rural road",
  "11": "Freeway ramp",
  "12": "Principal ramp",
  "13": "Major ramp",
  "14": "Minor ramp",
  "15": "Local ramp",
  "21": "Busway",
  "31": "Pedestrian",
  "32": "Non-motorized",
  "81": "Ferry",
  "97": "Abandoned",
  "98": "Construction",
};

export const screenlineCsvFileColumns = [
  {
    column: "screenline_id",
    type: "Text",
    description: "User defined screenline id",
  },
  {
    column: "screenline_name",
    type: "Text",
    description: "User defined screenline name",
  },
  {
    column: "segment_id",
    type: "Text",
    description:
      "Id of the intersecting segment. A segment may intersect the screenline more than once, in which case there is an individual row for each intersection.",
  },
  {
    column: "factype",
    type: "Integer",
    description: (
      <div>
        Facility type for the road segment. <DimensionListWithCodes elements={facilityTypes} />
      </div>
    ),
  },
  {
    column: "st_name",
    type: "Text",
    description: "Street name of the segment",
  },
  {
    column: "direction",
    type: "Text",
    description:
      'The crossing direction of the segment (such as "West-bound” with different directions or “Any” for pedestrians)',
  },
  {
    column: "longitude",
    type: "Double",
    description: "Longitude of the intersection point of the segment with the screenline",
  },
  {
    column: "latitude",
    type: "Double",
    description: "Latitude of the intersection point of the segment with the screenline",
  },
  {
    column: "volume",
    type: "Integer",
    description: "The volume at the intersection of the segment with the screenline",
  },
];
