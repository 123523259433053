import { Box } from "@mui/material";
import { Switch, SwitchProps } from "components_new";
import * as React from "react";

export interface EditorToggleControlProps extends SwitchProps {
  open: boolean;
}

export const EditorToggleControl: React.FC<EditorToggleControlProps> = ({ open, ...props }) => (
  <Box sx={(theme) => ({ borderLeft: `1px solid ${theme.palette.divider}`})}>
    <Switch
      sx={(theme) => ({
        "& .MuiFormControlLabel-root": {
          marginRight: 1,
          marginLeft: theme.spacing(0.5),
        },
        "& .MuiSwitch-root": {
          marginLeft: theme.spacing(0.2),
        },
      })}
      label="Editor"
      checked={open}
      labelPlacement="end"
      {...props}
    />
  </Box>
);
