import { DateRange, FileDownload, InsertDriveFile, Place, ShortText } from "@mui/icons-material";
import { Alert, Box, MenuItem, Stack, Tooltip, Typography, styled } from "@mui/material";
import { Button, Divider, FiltersAndLayersContainer, MapControlContainer, TextField } from "components_new";
import { ChangeEvent, MutableRefObject, useEffect, useMemo, useState } from "react";

import { NewExportDialog } from "features/export";
import { BaseMapStyles } from "features/filters/BaseMapStyles";
import { Filters } from "features/filters/Filters";

import { AreaName, FocusAreaDropdown, LeftSidebar, ToggleButtons } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";
import { globalActions, selectIsEditing } from "store/sections/global";
import { mapActions } from "store/sections/map";

import { MapVisualizationType, SelectLinkMode } from "types";

import { ModuleData } from "./ModuleManager";
import { ODMapLayers } from "./modules/od/ODMapLayers";
import { RoadIntersectionsMapLayers } from "./modules/road-intersections/RoadIntersectionsMapLayers";
import { RoadsMapLayers } from "./modules/roads/RoadsMapLayers";
import { ScreenlineMapLayers } from "./modules/screenlines/ScreenlineMapLayers";
import { getLayerFromZoom } from "./utils";

interface MapControlPanelProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  roadsModuleData: ModuleData | null;
  ODModuleData: ModuleData | null;
  screenlinesModuleData: ModuleData | null;
  isScreenlineModuleLoaded: boolean;
  isIntersectionsModuleLoaded: boolean;
  selectLinkMode: SelectLinkMode | null;
  setSelectLinkMode: (mode: SelectLinkMode) => void;
  roadIntersectionsModuleData: ModuleData | null;
  roadIntersectionsSupported: boolean;
}

const AreaNameContainer = styled(Box)`
  margin-left: 0.5rem;
  display: flex;
`;

export const MapControlPanel = ({
  map,
  roadsModuleData,
  ODModuleData,
  screenlinesModuleData,
  isScreenlineModuleLoaded,
  isIntersectionsModuleLoaded,
  selectLinkMode,
  setSelectLinkMode,
  roadIntersectionsModuleData,
  roadIntersectionsSupported,
}: MapControlPanelProps) => {
  const dispatch = useAppDispatch();

  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);

  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const timePeriod = useAppSelector((state) =>
    selectLinkMode ? state.selectLink.savedSelectLinkConfig.data?.timePeriod : state.global.timePeriod,
  );
  const mode = useAppSelector((state) => state.analytics.mapVisualizationMode);
  const measure = useAppSelector((state) => state.filters.measure);

  const isDataset = selectedFocusArea?.datasetId;

  const isODExportAllowed = useAppSelector((state) => state.analytics.ODMetadata.data?.exportPermissions.allowExport);
  const isRoadsExportAllowed = useAppSelector(
    (state) => state.analytics.roadsMetadata.data?.exportPermissions.allowExport,
  );

  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);
  const isSelectLinkExportDisabled = useAppSelector((state) => state.selectLink.isSelectLinkExportDisabled);

  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);

  const savedSelectLinkConfig = useAppSelector((state) => state.selectLink.savedSelectLinkConfig);

  const isEditing = useAppSelector(selectIsEditing);

  const loading = useMemo(
    () =>
      [focusAreas.state, ODMetadata.state, datasetMetadata.state, roadsMetadata.state].some(
        (state) => state === DataState.LOADING,
      ),
    [focusAreas.state, ODMetadata.state, datasetMetadata.state, roadsMetadata.state],
  );

  const isExportDisabled = useMemo(
    () =>
      Boolean(
        (!selectLinkMode && isODExportAllowed === false) ||
          (!selectLinkMode && isRoadsExportAllowed === false) ||
          (selectLinkMode && isSelectLinkExportDisabled) ||
          isEditing,
      ),
    [isODExportAllowed, isRoadsExportAllowed, selectLinkMode, isSelectLinkExportDisabled, isEditing],
  );

  const layers = useMemo(
    () =>
      selectedFocusArea?.datasetId ? datasetMetadata.data?.tileService.layers : ODMetadata.data?.tileService.layers,
    [selectedFocusArea?.datasetId, datasetMetadata.data?.tileService.layers, ODMetadata.data?.tileService.layers],
  );

  const isODDisabled = useMemo(
    () => ODMetadata.error?.status === 403 || datasetMetadata.error?.status === 403,
    [ODMetadata.error, datasetMetadata.error],
  );

  const isRoadsDisabled = useMemo(() => roadsMetadata.error?.status === 403, [roadsMetadata.error]);

  const filterMode = useMemo(() => {
    if (selectLinkMode && selectLinkMode === SelectLinkMode.ZONES) {
      return MapVisualizationType.OD;
    } else if (selectLinkMode && selectLinkMode === SelectLinkMode.LINKS) {
      return MapVisualizationType.ROADS;
    }

    return mode!;
  }, [mode, selectLinkMode]);

  // Save map bounds to store
  useEffect(() => {
    const currentMap = map.current;

    return () => {
      const bounds: any = currentMap?.getBounds().toArray();

      if (bounds) {
        dispatch(mapActions.setMapBounds(bounds));
      }
    };
  }, [map, dispatch]);

  const handleChangeFocusArea = (focusAreaId: string) => {
    if (focusAreaId) {
      dispatch(globalActions.setSelectedFocusAreaId({ focusAreaId }));
    }
  };

  const handleChangeTimePeriod = (event: ChangeEvent<HTMLInputElement>) => {
    const bounds: any = map.current?.getBounds().toArray();

    if (bounds) {
      dispatch(mapActions.setMapBounds(bounds));
    }

    dispatch(globalActions.setTimePeriod(event.target.value));
  };

  const handleChangeVisualizationMode = (mode: string) => {
    dispatch(analyticsActions.setMapVisualizationMode(mode as MapVisualizationType));
  };

  const handleChangeSelectLinkConfigurationMode = (mode: string) => {
    setSelectLinkMode(mode as SelectLinkMode);
  };

  return (
    <>
      <LeftSidebar>
        <Box
          sx={{
            height: "100%",
            display: "grid",
            rowGap: 2,
            gridTemplateRows: selectLinkMode ? "auto auto minmax(100px, 1fr)" : "auto minmax(100px, 1fr)",
          }}
        >
          {selectLinkMode && (
            <MapControlContainer title="Select link analysis">
              <Stack spacing={1} padding={1}>
                <Stack direction={"row"} spacing={1}>
                  <InsertDriveFile color="secondary" fontSize="inherit" />
                  <Typography fontSize={14} fontWeight={500}>
                    {savedSelectLinkConfig.data?.analysisName}
                  </Typography>
                </Stack>
                {savedSelectLinkConfig.data?.description && (
                  <Stack direction={"row"} spacing={1}>
                    <ShortText color="secondary" fontSize="inherit" />
                    <Tooltip title={savedSelectLinkConfig.data?.description}>
                      <Typography fontSize={11} noWrap maxWidth={216}>
                        {savedSelectLinkConfig.data?.description}
                      </Typography>
                    </Tooltip>
                  </Stack>
                )}
                <Divider />
                <Stack direction={"row"} spacing={1}>
                  <Place color="secondary" fontSize="inherit" />
                  <Typography fontSize={12} color={"text.secondary"}>
                    {selectedFocusArea?.label}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <DateRange color="secondary" fontSize="inherit" />
                  <Typography fontSize={12} color={"text.secondary"}>
                    {timePeriod}
                  </Typography>
                </Stack>
              </Stack>
            </MapControlContainer>
          )}
          <Stack spacing={2}>
            {!selectLinkMode && (
              <>
                <div>
                  <FocusAreaDropdown
                    loading={loading}
                    disabled={focusAreas.state === DataState.EMPTY || focusAreas.state === DataState.ERROR || isEditing}
                    options={focusAreas.data || []}
                    value={selectedFocusArea}
                    onChange={handleChangeFocusArea}
                  />
                  {selectedFocusArea?.datasetId ? (
                    <AreaNameContainer>
                      <AreaName>{selectedFocusArea?.region}</AreaName>
                    </AreaNameContainer>
                  ) : null}
                </div>

                <TextField
                  select
                  fullWidth
                  value={timePeriod || ""}
                  label="Time Period"
                  disabled={(selectedFocusArea?.timePeriods?.length ?? 1) <= 1 || isEditing}
                  onChange={handleChangeTimePeriod}
                >
                  {selectedFocusArea?.timePeriods?.map((timePeriod) => (
                    <MenuItem key={timePeriod} value={timePeriod}>
                      {timePeriod}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}

            {setIsExportDialogOpen && (
              <Button
                startIcon={<FileDownload />}
                color="secondary"
                disabled={isExportDisabled}
                onClick={() => setIsExportDialogOpen(true)}
              >
                New Export
              </Button>
            )}

            {mode && !selectLinkMode ? (
              <ToggleButtons
                leftButtonLabel="OD Matrix"
                rightButtonLabel="Volumes"
                activeIndex={mode}
                onChangeIndex={handleChangeVisualizationMode}
                leftButtonDisabled={isODDisabled || isEditing}
                rightButtonDisabled={isRoadsDisabled || isEditing}
                leftButtonIndex={MapVisualizationType.OD}
                rightButtonIndex={MapVisualizationType.ROADS}
              />
            ) : null}

            {selectLinkMode ? (
              <ToggleButtons
                leftButtonLabel="Zones"
                rightButtonLabel="Links"
                activeIndex={selectLinkMode}
                onChangeIndex={handleChangeSelectLinkConfigurationMode}
                leftButtonDisabled={selectLinkMode === SelectLinkMode.RESULTS}
                rightButtonDisabled={selectLinkMode === SelectLinkMode.RESULTS}
                leftButtonIndex={SelectLinkMode.ZONES}
                rightButtonIndex={SelectLinkMode.LINKS}
              />
            ) : null}
          </Stack>

          <FiltersAndLayersContainer>
            {selectLinkMode && selectLinkMode === SelectLinkMode.ZONES && (
              <Alert
                severity="info"
                sx={{
                  fontSize: 10,
                  fontWeight: 500,
                  padding: "2px 6px",
                  backgroundColor: "#0067b01a",
                  marginBottom: 1,
                }}
              >
                Attribute Filters in Zones selection are not applied to Select Link query
              </Alert>
            )}
            <Filters
              mode={selectLinkMode === SelectLinkMode.RESULTS ? MapVisualizationType.ROADS : filterMode}
              selectLinkMode={selectLinkMode}
              loading={loading}
              disabled={selectLinkMode === SelectLinkMode.RESULTS || isEditing}
              filterRoadSegmentsByRoadClasses={roadsModuleData?.data?.filterRoadSegmentsByRoadClasses}
            />

            {(mode === MapVisualizationType.OD && ODModuleData) ||
            (selectLinkMode && selectLinkMode === SelectLinkMode.ZONES && ODModuleData) ? (
              <ODMapLayers
                map={map}
                ODModuleData={ODModuleData}
                isSelectLinkMode={Boolean(selectLinkMode)}
                disabled={isEditing}
              />
            ) : null}
            {mode === MapVisualizationType.ROADS &&
              roadsModuleData &&
              roadIntersectionsModuleData &&
              isIntersectionsModuleLoaded &&
              !isDataset &&
              roadIntersectionsSupported && (
                <RoadIntersectionsMapLayers map={map} moduleData={roadIntersectionsModuleData} />
              )}
            {(mode === MapVisualizationType.ROADS && roadsModuleData) ||
            (selectLinkMode &&
              (selectLinkMode === SelectLinkMode.LINKS || selectLinkMode === SelectLinkMode.RESULTS) &&
              roadsModuleData) ? (
              <>
                {isScreenlineModuleLoaded && screenlinesModuleData && !isDataset && (
                  <ScreenlineMapLayers map={map} screenlinesModuleData={screenlinesModuleData} disabled={isEditing} />
                )}
                <RoadsMapLayers
                  map={map}
                  roadsModuleData={roadsModuleData}
                  isSelectLinkResults={selectLinkMode === SelectLinkMode.RESULTS}
                  disabled={isEditing}
                />
              </>
            ) : null}

            <BaseMapStyles map={map.current} disabled={roadsModuleData?.data?.isSelectLinkResults || isEditing} />
          </FiltersAndLayersContainer>
        </Box>
      </LeftSidebar>

      {isExportDialogOpen && (
        <NewExportDialog
          mode={mode}
          open={isExportDialogOpen}
          ODZoomLevel={getLayerFromZoom(map.current?.getZoom(), layers)?.level}
          measure={measure}
          selectedArea={selectedFocusArea}
          isSelectLink={Boolean(selectLinkMode)}
          onClose={() => setIsExportDialogOpen(false)}
        />
      )}
    </>
  );
};
