import { FC } from "react";

import {
  BluePoint,
  ElevatedTable,
  FooterTableCell,
  HeadTableCell,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "./Table";

export const LicenseSoftwareTable: FC = () => {
  return (
    <ElevatedTable>
      <TableHead>
        <TableRow>
          <HeadTableCell>Software licensing</HeadTableCell>
          <HeadTableCell>Basic</HeadTableCell>
          <HeadTableCell>Pro</HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>View and Filter</TableCell>
          <TableCell align="center">
            <BluePoint />
          </TableCell>
          <TableCell align="center">
            <BluePoint />
          </TableCell>
        </TableRow>
        {/* <TableRow>
          <TableCell>Interactive summaries: screenlines & polygon</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell align="center">
            <BluePoint />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Custom zones </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell align="center">
            <BluePoint />
          </TableCell>
        </TableRow> */}
        <TableRow>
          <TableCell>Export</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell align="center">
            <BluePoint />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Creation of subarea datasets based on custom zonings</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell align="center">
            <BluePoint />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Corridor Discovery</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell align="center">
            <BluePoint />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Select Link</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell align="center">
            <BluePoint />
          </TableCell>
        </TableRow>
        {/* <TableRow>
          <TableCell>Attach to Partner Databases</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell align="center">
            <BluePoint />
          </TableCell>
        </TableRow> */}
        <TableRow>
          <FooterTableCell colSpan={7}>&nbsp;</FooterTableCell>
        </TableRow>
      </TableBody>
    </ElevatedTable>
  );
};
