import { ExportFilters, FiltersType, DimensionDetails, SelectLinkAoiExportConfig } from "types";

export const getActiveFilters = (filters: FiltersType | null) => {
  if (!filters) return;

  return Object.values(filters).reduce((obj: { [key: string]: string[] }, filter) => {
    if (!filter.isChecked) {
      const activeFilters: string[] = [];
      for (let item of Object.values(filter.items)) {
        if (item.isChecked) activeFilters.push(item.label);
      }
      obj[filter.label] = activeFilters;
    }
    return obj;
  }, {});
};

export const getFiltersFromDimensions = (filters: ExportFilters | null, dimensions: DimensionDetails[]) => {
  return dimensions?.reduce((obj: { [key: string]: string[] }, dimension) => {
    const filterDimension = filters?.[dimension.value];
    if (filterDimension) {
      obj[dimension.label] = filterDimension.map(({ label }) => label);
    }
    return obj;
  }, {});
};

const EXPIRY_TIME_FRAME = 604800000; // 7 days in milliseconds

export const isExpired = (createdAt: string) => {
  const now = Date.now();
  const parsedDate = Date.parse(createdAt);
  return now - parsedDate > EXPIRY_TIME_FRAME;
};

export const getSelectLinkFilters = (config: SelectLinkAoiExportConfig) => {
  const { selectedSegmentsPredicate, selectedOriginsPredicate, selectedDestinationsPredicate, countsFilter } = config;
  const op = (isAnd: boolean): string => (isAnd ? " AND " : " OR ");
  const segmentClauses = selectedSegmentsPredicate?.clauses;
  const segmentGroupsExpr = segmentClauses?.map(({ isAnd, parts }, i) => {
    return (
      `(${parts.map(({ elementId }) => elementId).join(op(isAnd))})` +
      (i === segmentClauses.length - 1 ? "" : ` ${op(selectedSegmentsPredicate!.isAnd)}`)
    );
  });
  const originsExpr = selectedOriginsPredicate?.clauses.map(({ parts }) => parts[0].elementId);
  const destinationsExpr = selectedDestinationsPredicate?.clauses.map(({ parts }) => parts[0].elementId);
  return {
    ...(segmentGroupsExpr ? { "Segment Groups": segmentGroupsExpr } : {}),
    ...(originsExpr ? { Origins: originsExpr } : {}),
    ...(destinationsExpr ? { Destinations: destinationsExpr } : {}),
    "Min Volume": [`${countsFilter.min}`],
  };
};
