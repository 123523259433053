import { AnySourceData } from "mapbox-gl";

import { DatasetGate } from "types";

export const GATES_SOURCE_ID = "GATES_SOURCE_ID";

export const getGatesSources = (gates: DatasetGate[]) => [
  {
    id: GATES_SOURCE_ID,
    source: {
      type: "geojson",
      promoteId: "identifier",
      data: {
        type: "FeatureCollection",
        features: gates.map((gate) => ({
          type: "Feature",
          properties: {
            identifier: gate.identifier,
            count: 0,
          },
          geometry: {
            type: "Point",
            coordinates: [gate.lon, gate.lat],
          },
        })),
      },
    } as AnySourceData,
  },
];
