import { Box, Grid, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  EditorToggleControl,
  MapControlContainer,
  SeverityIcon,
  SliderControl,
  ValidationMessagesTooltip,
  VisibilityIconButton,
} from "components_new";
import { FC, MutableRefObject, useEffect, useMemo, useState } from "react";

import { ModuleData } from "features/map/ModuleManager";
import { changeScreenlineLayersWidthFactor, setScreenlineLayersRoadClasses } from "features/screenline";

import { useAppDispatch, useAppSelector } from "hooks";

import { globalActions } from "store/sections/global";
import {
  screenlinesActions,
  selectAreScreenlinePendingChanges,
  selectScreenlineIdsForCurrentNetwork,
  selectScreenlinesLoading,
  selectScreenlinesValidationSummaryMaxSeverity,
} from "store/sections/screenlines";

import { MapLayerContainerId, ValidationMessageSeverity } from "types";

import { calculateWidthFactor, valueLabelFormat } from "../../utils";

export interface ScreenlineMapLayersProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  screenlinesModuleData: ModuleData;
  disabled?: boolean;
}

export const InlineGridItem = styled(Grid)(({ theme }) => ({
  "&.MuiGrid-item": {
    paddingTop: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

function summaryValidationMessage(screenlineCount: number, withUnresolvedIntersections: boolean): string {
  const issueDescription = withUnresolvedIntersections
    ? `intersections that were not found in this road network`
    : `intersections for which there are minor differences in this road network`;

  return screenlineCount === 1
    ? `1 screenline has ${issueDescription}`
    : `${screenlineCount} screenlines have ${issueDescription}`;
}

export const ScreenlineMapLayers: FC<ScreenlineMapLayersProps> = ({ map, screenlinesModuleData, disabled }) => {
  const { changeShowScreenlines, changeScreenlinesOpacity } = screenlinesModuleData.data;

  const dispatch = useAppDispatch();
  const collapsedMapLayerContainers = useAppSelector((state) => state.global.collapsedMapLayerContainers);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const isScreenlinesEditorOpen = useAppSelector((state) => state.screenlines.isScreelineEditorOpen);
  const loading = useAppSelector(selectScreenlinesLoading);
  const screenlinesMaxCount = useAppSelector((state) => state.screenlines.maxCount);
  const arePendingChanges = useAppSelector(selectAreScreenlinePendingChanges);
  const roadClasses = useAppSelector((state) => state.filters.roadClasses);
  const showScreenlines = useAppSelector((state) => state.screenlines.showScreenlines);
  const screenlinesValidationSummaryData = useAppSelector(
    (state) => state.screenlines.screenlinesValidationSummary.data,
  );
  const screenlinesValidationSummaryMaxSeverity = useAppSelector(selectScreenlinesValidationSummaryMaxSeverity);

  const savedOpacityFactor = useAppSelector((state) => state.screenlines.opacityFactor);
  const savedWidthFactor = useAppSelector((state) => state.screenlines.widthFactor);

  const screenlineIdsForNetworkType = useAppSelector(selectScreenlineIdsForCurrentNetwork);
  const [opacityFactor, setOpacityFactor] = useState(savedOpacityFactor);
  const [widthFactor, setWidthFactor] = useState(savedWidthFactor);

  const screenlinesValidationSummaryMessage = useMemo(() => {
    if (!timePeriod || !screenlinesValidationSummaryData) {
      return null;
    }

    let withUnresolved = 0;
    let withMinorDifferences = 0;
    screenlinesValidationSummaryData
      .filter((s) => screenlineIdsForNetworkType.has(s.screenlineId)) // exclude screenlines that don't match the current network type
      .forEach((s) => {
        if (s.summary.unresolvedIntersectionCount > 0) {
          withUnresolved += 1;
        }
        if (s.summary.messages.length) {
          withMinorDifferences += 1;
        }
      });

    const messages = [];
    if (withUnresolved > 0) {
      messages.push(summaryValidationMessage(withUnresolved, true));
    }
    if (withMinorDifferences > 0) {
      messages.push(summaryValidationMessage(withMinorDifferences, false));
    }
    return messages;
  }, [screenlinesValidationSummaryData, timePeriod, screenlineIdsForNetworkType]);

  const handleToggleScreenlineEditor = () => {
    if (!arePendingChanges || window.confirm("Changes that you made may not be saved."))
      dispatch(screenlinesActions.setScreelineEditorOpen(!isScreenlinesEditorOpen));
  };

  const handleChangeScreenlineWidth = (widthFactor: number) => {
    dispatch(screenlinesActions.setWidthFactor(widthFactor));
  };

  const handleChangeLineOpacity = (opacityFactor: number) => {
    dispatch(screenlinesActions.setOpacityFactor(opacityFactor));
    changeScreenlinesOpacity(opacityFactor);
  };

  // Set screenline layers width factor after fetching counts and determine max count
  useEffect(() => {
    if (map.current) {
      changeScreenlineLayersWidthFactor(map.current, savedWidthFactor, screenlinesMaxCount || 1);
    }
  }, [map, savedWidthFactor, screenlinesMaxCount]);

  useEffect(() => {
    if (map.current) {
      setScreenlineLayersRoadClasses(map.current, roadClasses);
    }
  }, [map, roadClasses]);

  return (
    <>
      <MapControlContainer
        title="Screenlines"
        primaryAction={
          <VisibilityIconButton
            visible={showScreenlines}
            onClick={changeShowScreenlines}
            disabled={loading || disabled}
          />
        }
        secondaryAction={
          <EditorToggleControl
            disabled={loading || disabled}
            open={isScreenlinesEditorOpen}
            onClick={handleToggleScreenlineEditor}
          />
        }
        collapse
        expanded={!collapsedMapLayerContainers.includes(MapLayerContainerId.SCREENLINES)}
        onChange={() => dispatch(globalActions.toggleLayerContainerCollapsedState(MapLayerContainerId.SCREENLINES))}
        icon={
          screenlinesValidationSummaryMaxSeverity && (
            <ValidationMessagesTooltip
              title={
                <Box paddingLeft={2}>
                  <ul>
                    {screenlinesValidationSummaryMessage?.map((msg, index) => (
                      <Typography key={index} fontSize={11} component={"li"}>
                        {msg}
                      </Typography>
                    ))}
                  </ul>
                </Box>
              }
            >
              <SeverityIcon
                severity={screenlinesValidationSummaryMaxSeverity as ValidationMessageSeverity}
                fontSize="inherit"
              />
            </ValidationMessagesTooltip>
          )
        }
      >
        <Box padding={1}>
          <SliderControl
            label="Opacity"
            disabled={loading || disabled}
            value={opacityFactor}
            defaultValue={1}
            min={0}
            max={1}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            onChange={(e, value) => setOpacityFactor(value as number)}
            onChangeCommitted={(e, value) => handleChangeLineOpacity(value as number)}
          />
          <SliderControl
            label="Width"
            disabled={loading || disabled}
            value={widthFactor}
            defaultValue={1}
            min={0}
            max={2}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            scale={calculateWidthFactor}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            valueLabelDisplay="auto"
            onChange={(e, value) => setWidthFactor(value as number)}
            onChangeCommitted={(e, value) => handleChangeScreenlineWidth(value as number)}
          />
        </Box>
      </MapControlContainer>
    </>
  );
};
