import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemProps,
  Skeleton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { IntersectionDirection } from "api/analytics/index.d";
import { Button, Divider, IconButton } from "components_new";
import { LngLatLike } from "mapbox-gl";
import { FC, RefObject, createRef, useEffect, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "hooks";

import { screenlinesActions, selectCandidateIntersections, selectSelectedScreenline } from "store/sections/screenlines";

import { RoadNetworkType } from "types";

import { getFromToSegmentIndex } from "utils/parse";

import { ScreenlineIntersection } from "./ScreenlineIntersection";
import { getIntersectionGroups } from "./utils";

export interface ScreenlineIntersectionsProps {
  loading: boolean;
  intersectionDirectionFilter: IntersectionDirection | undefined;
  editMode: boolean;
  rightLabel: string;
  leftLabel: string;
  handleZoomOnPoint: (lngLat: LngLatLike, zoom?: number) => void;
  setSelectedIntersectionId: ((intersectionId: string | null) => void) | null;
}

export const IntersectionListItem = styled((props: ListItemProps) => (
  <ListItem disableGutters disablePadding {...props} />
))(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.background.paper,
}));

const IntersectionListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: 0,
}));

export const ScreenlineIntersections: FC<ScreenlineIntersectionsProps> = ({
  loading,
  intersectionDirectionFilter,
  editMode,
  leftLabel,
  rightLabel,
  handleZoomOnPoint,
  setSelectedIntersectionId,
}) => {
  const dispatch = useAppDispatch();

  const selectedScreenline = useAppSelector(selectSelectedScreenline);
  const draftScreenline = useAppSelector((state) => state.screenlines.draftScreenline);
  const selectedIntersectionId = useAppSelector((state) => state.screenlines.selectedIntersectionId);
  const candidateIntersections = useAppSelector(selectCandidateIntersections);
  const candidateSegments = useAppSelector((state) => state.screenlines.screenlineValidation.data?.candidateSegments);
  const resolvedSegments = useAppSelector((state) => state.screenlines.screenlineValidation.data?.resolvedSegments);
  const validatedSegmentIntersections = useAppSelector(
    (state) => state.screenlines.screenlineValidation.data?.validatedSegmentIntersections,
  );
  const validationMessages = useAppSelector((state) => state.screenlines.screenlineValidation.data?.validationMessages);
  const isEditorOpen = useAppSelector((state) => state.screenlines.isScreelineEditorOpen);
  const roadClasses = useAppSelector((state) => state.filters.roadClasses);

  const [showCandidates, setShowCandidates] = useState(true);
  const [showNotIncluded, setShowNotIncluded] = useState(true);
  const [showNotResolved, setShowNotResolved] = useState(true);

  const isNonDirectional = selectedScreenline?.network === RoadNetworkType.Pedestrian;

  const segmentsLength = editMode
    ? (resolvedSegments?.length || 0) + (candidateSegments?.length || 0)
    : selectedScreenline?.segmentIntersections.length || 0;

  const candidatesLength = useMemo(
    () =>
      isNonDirectional
        ? new Set(candidateSegments?.map((cs) => getFromToSegmentIndex(cs.segmentIndex)) || []).size
        : candidateSegments?.length || 0,
    [candidateSegments, isNonDirectional],
  );

  const notIncludedIntersections = useMemo(
    () => validatedSegmentIntersections?.filter((intersection) => !roadClasses?.includes(intersection.roadClass)),
    [validatedSegmentIntersections, roadClasses],
  );

  const notIncludedIntersectionsLength = useMemo(
    () =>
      isNonDirectional
        ? new Set(notIncludedIntersections?.map((ni) => ni.intersectionPoint.fraction) || []).size
        : notIncludedIntersections?.length || 0,
    [notIncludedIntersections, isNonDirectional],
  );

  const notResolvedIntersections = useMemo(
    () => validatedSegmentIntersections?.filter((intersection) => !intersection.resolvedIntersection),
    [validatedSegmentIntersections],
  );

  const notResolvedIntersectionsLength = useMemo(
    () =>
      isNonDirectional
        ? new Set(notResolvedIntersections?.map((ni) => ni.intersectionPoint.fraction) || []).size
        : notResolvedIntersections?.length || 0,
    [notResolvedIntersections, isNonDirectional],
  );

  const handleClickOnIntersection = (intersectionId: string, lat: number, lon: number) => {
    setSelectedIntersectionId?.(intersectionId);
    handleZoomOnPoint([lon, lat]);
  };

  const handleAddAllCandidates = () => {
    if (draftScreenline && candidateIntersections.length) {
      dispatch(screenlinesActions.addIntersections(candidateIntersections));
    }
  };

  const handleRemoveAllNotIncludedIntersections = () => {
    if (draftScreenline && notIncludedIntersections?.length) {
      dispatch(screenlinesActions.deleteIntersections(notIncludedIntersections.map((i) => i.id)));
    }
  };

  const handleRemoveAllNotResolvedIntersections = () => {
    if (draftScreenline && notResolvedIntersections?.length) {
      dispatch(screenlinesActions.deleteIntersections(notResolvedIntersections.map((i) => i.id)));
    }
  };

  const intersectionGroups = useMemo(() => {
    const candidates = editMode && showCandidates ? candidateSegments || [] : [];

    const validated =
      showNotIncluded && showNotResolved
        ? validatedSegmentIntersections || []
        : validatedSegmentIntersections?.filter(
            (intersection) =>
              (showNotIncluded ? true : roadClasses?.includes(intersection.roadClass)) &&
              (showNotResolved ? true : intersection.resolvedIntersection),
          ) || [];

    const messages = validationMessages || [];

    const groups = getIntersectionGroups(candidates, validated, messages);

    const refs = groups.reduce((refsObj: { [key: string]: RefObject<any> }, group) => {
      const intersectionId = group[0];
      refsObj[intersectionId] = createRef();
      return refsObj;
    }, {});

    return { groups, refs };
  }, [
    validatedSegmentIntersections,
    candidateSegments,
    validationMessages,
    editMode,
    showCandidates,
    showNotIncluded,
    showNotResolved,
    roadClasses,
  ]);

  useEffect(() => {
    if (
      selectedIntersectionId &&
      intersectionGroups.refs[selectedIntersectionId] &&
      intersectionGroups.refs[selectedIntersectionId].current &&
      isEditorOpen
    ) {
      intersectionGroups.refs[selectedIntersectionId].current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selectedIntersectionId, intersectionGroups.refs, isEditorOpen]);

  return (
    <>
      <Stack alignItems={"flex-start"} spacing={0.5} padding={0.5}>
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Grid item xs container alignItems={"center"}>
            <IconButton
              disabled={!candidatesLength || !editMode}
              size="small"
              color={"secondary"}
              sx={{ marginRight: 1 }}
              onClick={() => setShowCandidates(!showCandidates)}
            >
              {showCandidates ? <Visibility fontSize="inherit" /> : <VisibilityOff fontSize="inherit" />}
            </IconButton>
            <Typography sx={{ fontSize: "11px" }}>
              {`${candidatesLength || 0} additional intersection${candidatesLength === 1 ? "" : "s"} can be included`}
            </Typography>
          </Grid>
          <Button
            size="small"
            color="secondary"
            variant="text"
            disabled={!candidatesLength || !editMode}
            onClick={handleAddAllCandidates}
            sx={{ padding: 0, fontSize: "11px !important" }}
          >
            Include
          </Button>
        </Grid>
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Grid item xs container alignItems={"center"}>
            <IconButton
              disabled={!notIncludedIntersectionsLength}
              size="small"
              color={"secondary"}
              sx={{ marginRight: 1 }}
              onClick={() => setShowNotIncluded(!showNotIncluded)}
            >
              {showNotIncluded ? <Visibility fontSize="inherit" /> : <VisibilityOff fontSize="inherit" />}
            </IconButton>
            <Typography sx={{ fontSize: "11px" }}>
              {`${notIncludedIntersectionsLength || 0} intersection${
                (notIncludedIntersectionsLength || 0) === 1 ? "" : "s"
              } with excluded road classes`}
            </Typography>
          </Grid>
          <Button
            size="small"
            color="secondary"
            variant="text"
            disabled={!notIncludedIntersectionsLength || !editMode}
            onClick={handleRemoveAllNotIncludedIntersections}
            sx={{ padding: 0, fontSize: "11px !important" }}
          >
            Remove
          </Button>
        </Grid>
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Grid item xs container alignItems={"center"}>
            <IconButton
              disabled={!notResolvedIntersectionsLength}
              size="small"
              color={"secondary"}
              sx={{ marginRight: 1 }}
              onClick={() => setShowNotResolved(!showNotResolved)}
            >
              {showNotResolved ? <Visibility fontSize="inherit" /> : <VisibilityOff fontSize="inherit" />}
            </IconButton>
            <Typography sx={{ fontSize: "11px" }}>
              {!notResolvedIntersectionsLength
                ? "All intersections matched to a segment"
                : `${notResolvedIntersectionsLength} intersection${
                    notResolvedIntersectionsLength === 1 ? "" : "s"
                  } not matched to a segment
`}
            </Typography>
          </Grid>
          <Button
            size="small"
            color="secondary"
            variant="text"
            disabled={!notResolvedIntersectionsLength || !editMode}
            onClick={handleRemoveAllNotResolvedIntersections}
            sx={{ padding: 0, fontSize: "11px !important" }}
          >
            Remove
          </Button>
        </Grid>
      </Stack>

      <Divider />
      <List
        sx={{
          overflowY: "auto",
          height: `calc(100% - 112px)`,
          maxHeight: `calc(100% - 112px)`,
          transition: "height 0.3s",
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        {loading ? (
          Array.from({ length: selectedScreenline?.segmentIntersections.length || 5 }, (_, index) => 0 + index).map(
            (i) => <Skeleton key={i} variant="rounded" height={40} sx={{ marginBottom: 1, borderRadius: "8px" }} />,
          )
        ) : segmentsLength === 0 ? (
          <Grid container padding={1} justifyContent={"center"} color={"text.secondary"}>
            <Typography variant="caption" marginTop={2}>
              No segment intersections assigned
            </Typography>
            <br />
            <Typography variant="caption" marginTop={1} textAlign={"center"}>
              There are{" "}
              <Typography variant="caption" color={"text.primary"}>
                {candidatesLength} intersecting segment
                {candidatesLength === 1 ? "" : "s"}
              </Typography>{" "}
              matching the road class filter that can be added to the screenline by selecting them on the map, or adding
              them all
            </Typography>
          </Grid>
        ) : (
          intersectionGroups.groups.map(([intersectionGroupId, intersectionGroup], i) => {
            const { roadClass } = intersectionGroup[0].intersection;
            return (
              <IntersectionListItem key={i}>
                <IntersectionListItemButton
                  divider
                  disabled={!roadClasses?.includes(roadClass)}
                  selected={selectedIntersectionId?.toString() === intersectionGroupId.toString()}
                  onClick={() =>
                    handleClickOnIntersection(
                      intersectionGroupId,
                      intersectionGroup[0].intersection.intersection.lat,
                      intersectionGroup[0].intersection.intersection.lon,
                    )
                  }
                >
                  <ScreenlineIntersection
                    key={i}
                    ref={intersectionGroups.refs[intersectionGroupId]}
                    intersectionGroup={intersectionGroup}
                    leftLabel={leftLabel}
                    rightLabel={rightLabel}
                    selected={selectedIntersectionId === intersectionGroupId}
                    intersectionDirectionFilter={intersectionDirectionFilter}
                    editMode={editMode}
                  />
                </IntersectionListItemButton>
              </IntersectionListItem>
            );
          })
        )}
      </List>
      <Divider />
    </>
  );
};
