export const roadVolumesFileColumns = [
  {
    column: "segment_id",
    type: "Text",
    description: "Alphanumerical ID of the road segment",
  },
  {
    column: "from_node",
    type: "Integer",
    description: "OpenStreetMap from node ID",
  },
  {
    column: "A",
    type: "Integer",
    description: "Sequential From Node ID, with values starting at 32,001",
  },
  {
    column: "to_node",
    type: "Integer",
    description: "OpenStreetMap to node ID",
  },
  {
    column: "B",
    type: "Integer",
    description: "Sequential To Node ID, with values starting at 32,001",
  },
  {
    column: "volume",
    type: "Integer",
    description: "Annual average traffic (filters applied in the UI or in the select link configuration)",
  },
];
