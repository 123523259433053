import { Grid, Typography } from "@mui/material";
import { IntersectionDirection } from "api/analytics/index.d";
import { CountBadge } from "components_new";
import { FC } from "react";

import { useAppSelector } from "hooks";

import { RoadNetworkType } from "types";

export interface ScreenlinePopupContentProps {
  toLeft: number;
  toRight: number;
  leftLabel: string;
  rightLabel: string;
  segmentIntersectionsLength: number;
  totalSegmentIntersections: number;
  intersectioDirectionFilter: IntersectionDirection | undefined;
  network: RoadNetworkType;
}

export const ScreenlinePopupContent: FC<ScreenlinePopupContentProps> = ({
  toLeft,
  toRight,
  leftLabel,
  rightLabel,
  segmentIntersectionsLength,
  totalSegmentIntersections,
  intersectioDirectionFilter,
  network,
}) => {
  const roadClasses = useAppSelector((state) => state.filters.roadClasses);
  return (
    <Grid>
      <Grid container justifyContent={"center"} sx={{ padding: "8px 12px", borderRadius: "8px", gap: 2 }}>
        {network === RoadNetworkType.Pedestrian ? (
          <CountBadge label={toLeft + toRight} />
        ) : (
          <>
            {intersectioDirectionFilter !== IntersectionDirection.right && (
              <Grid item>
                <Typography fontSize={10}>{leftLabel}</Typography>
                <CountBadge label={toLeft} />
              </Grid>
            )}
            {intersectioDirectionFilter !== IntersectionDirection.left && (
              <Grid item>
                <Typography fontSize={10}>{rightLabel}</Typography>
                <CountBadge label={toRight} />
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Grid
        container
        justifyContent={"flex-start"}
        sx={(theme) => ({ padding: "4px 8px", borderTop: `1px solid ${theme.palette.divider}` })}
      >
        <Typography fontSize={10}>
          {segmentIntersectionsLength === totalSegmentIntersections
            ? `${totalSegmentIntersections} intersecting segment${totalSegmentIntersections !== 1 ? "s" : ""} included`
            : `${segmentIntersectionsLength} of ${totalSegmentIntersections} intersecting segment${
                totalSegmentIntersections !== 1 ? "s" : ""
              } included for ${roadClasses?.length} selected road class${roadClasses?.length !== 1 ? "es" : ""}`}
        </Typography>
      </Grid>
    </Grid>
  );
};
