export const gatesFileColums = [
  {
    column: "gate_id",
    type: "Integer",
    description: "Gate ID",
  },
  {
    column: "gate_idseq",
    type: "Integer",
    description: (
      <div>
        {" "}
        Renumbered gate ID, mapped into 1-32,000 range, following highest zone ID (zone_idseq) as Bentley CUBE Voyager
        allows maximum value for OD zone IDs of 32,000. CUBE 2024 removes this limitation using CubePy.{" "}
      </div>
    ),
  },
  {
    column: "from_node",
    type: "Integer",
    description: "FROM node ID for the gate segment",
  },
  {
    column: "to_node",
    type: "Integer",
    description: "TO node ID for the gate segment",
  },
  {
    column: "longitude",
    type: "Double",
    description: "Longitude of the gate marker",
  },
  {
    column: "latitude",
    type: "Double",
    description: "Latitude of the gate marker",
  },
  {
    column: "description",
    type: "Text",
    description: "Optional gate description, if defined by user",
  },
];
