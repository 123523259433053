import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import memoryStore, { MemoryStoreKeys } from "api/memoryStore";
import { Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, RefObject, SetStateAction, useEffect, useMemo, useRef, useState } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { useAppSelector, useStateRef } from "hooks";

import { DataState } from "store/interfaces";

import { MeasureType } from "types";

import {
  useChangeRoadIntersectionsOpacity,
  useChangeShowRoadIntersections,
  useClearRoadIntersectionVolumeDetails,
  useCloseRoadIntersectionsAnalyticsPanel,
  useFetchRoadIntersectionClusterIds,
  useFetchRoadIntersectionClusterVolumes,
  useFetchRoadIntersectionIds,
  useFetchRoadIntersectionVolumeDetails,
  useFetchRoadIntersectionVolumes,
  useSelectIntersectionId,
  useUpdateFeatureStateForRoadIntersections,
} from "./ControllerCallbacks";
import { getRoadIntersectionClusterLayers, getRoadIntersectionsLayers } from "./map-data/layers";
import { getRoadIntersectionClusterSources, getRoadIntersectionsSources } from "./map-data/sources";

interface Props {
  map: MutableRefObject<mapboxgl.Map | null>;
  measure: MeasureType;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  setRoadIntersectionsModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  updateVolumesRef: MutableRefObject<(() => void) | null>;
  closeAnalyticsPanelRef: RefObject<() => void>;
}

export const MapController = ({
  map,
  measure,
  layerManagerRef,
  setRoadIntersectionsModuleData,
  updateVolumesRef,
  closeAnalyticsPanelRef,
}: Props) => {
  const [baseLayerName, setBaseLayerName] = useState<string>("");

  const mapboxRoadIntersectionHoverPopupRef = useRef<Popup | null>(null);

  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);

  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const roadFilters = useAppSelector((state) => state.filters.roadFilters);

  const baseLevel = useMemo(
    () => roadsMetadata.data?.intersectionLevels?.[measure]?.baseLevel,
    [roadsMetadata.data, measure],
  );

  const clusterLevels = useMemo(
    () => roadsMetadata.data?.intersectionLevels?.[measure]?.clusterLevels,
    [roadsMetadata.data, measure],
  );

  const allLevels = useMemo(
    () => [...(baseLevel ? [baseLevel] : []), ...(clusterLevels || [])],
    [baseLevel, clusterLevels],
  );

  //Screenlines
  const selectedScreenlineId = useAppSelector((state) => state.screenlines.selectedScreenlineId);
  const isScreelineEditorOpen = useAppSelector((state) => state.screenlines.isScreelineEditorOpen);

  const showRoadIntersections = useAppSelector((state) => state.roadIntersections.showRoadIntersections);
  const showRoadIntersectionsRef = useStateRef(showRoadIntersections);
  const changeShowRoadIntersections = useChangeShowRoadIntersections(layerManagerRef, allLevels);

  const changeRoadIntersectionsOpacity = useChangeRoadIntersectionsOpacity(map, allLevels, layerManagerRef);

  const roadIntersectionVolumes = useAppSelector((state) => state.roadIntersections.intersectionVolumes);
  const roadIntersectionIds = useAppSelector((state) => state.roadIntersections.intersectionIds);
  const selectedIntersectionId = useAppSelector((state) => state.roadIntersections.selectedIntersectionId);
  const selectedIntersectionIdRef = useStateRef(selectedIntersectionId);
  const roadIntersectionVolumeDetails = useAppSelector((state) => state.roadIntersections.intersectionVolumeDetails);

  const roadIntersectionClusterVolumes = useAppSelector((state) => state.roadIntersections.intersectionClusterVolumes);
  const roadIntersectionClusterIds = useAppSelector((state) => state.roadIntersections.intersectionClusterIds);

  // TODO: delay fetching volumes and ids to when pedestrian measure is actually selected
  const fetchRoadIntersectionVolumes = useFetchRoadIntersectionVolumes(timePeriod, measure, roadFilters);
  const fetchRoadIntersectionIds = useFetchRoadIntersectionIds(timePeriod);
  const fetchRoadIntersectionVolumeDetails = useFetchRoadIntersectionVolumeDetails(
    roadsMetadata.data,
    roadFilters,
    timePeriod,
  );
  const clearRoadIntersectionVolumeDetails = useClearRoadIntersectionVolumeDetails();
  const selectRoadIntersectionId = useSelectIntersectionId();
  const fetchRoadIntersectionClusterIds = useFetchRoadIntersectionClusterIds(timePeriod);
  const fetchRoadIntersectionClusterVolumes = useFetchRoadIntersectionClusterVolumes(timePeriod, measure, roadFilters);

  const updateFeatureStateForRoadIntersections = useUpdateFeatureStateForRoadIntersections(map, baseLayerName);
  const closeRoadIntersectionsAnalyticsPanel = useCloseRoadIntersectionsAnalyticsPanel(
    map,
    selectedIntersectionId,
    selectRoadIntersectionId,
    updateFeatureStateForRoadIntersections,
  );

  // Close roads analytics panel on measure change
  useEffect(() => {
    if (measure) {
      closeAnalyticsPanelRef.current?.();
    }
  }, [measure, closeAnalyticsPanelRef]);

  useEffect(() => {
    if (roadsMetadata.state === DataState.AVAILABLE && roadIntersectionIds.state === DataState.EMPTY) {
      fetchRoadIntersectionIds();
    }
  }, [roadsMetadata.state, roadIntersectionIds.state, fetchRoadIntersectionIds]);

  useEffect(() => {
    if (roadsMetadata.state === DataState.AVAILABLE) {
      if (roadIntersectionVolumes.state === DataState.EMPTY) {
        fetchRoadIntersectionVolumes();
      }

      if (roadIntersectionVolumes.state === DataState.AVAILABLE) {
        updateVolumesRef.current?.();
      }
    }
  }, [roadsMetadata.state, roadIntersectionVolumes.state, fetchRoadIntersectionVolumes, updateVolumesRef]);

  useEffect(() => {
    if (roadsMetadata.state === DataState.AVAILABLE && roadIntersectionClusterIds.state === DataState.EMPTY) {
      fetchRoadIntersectionClusterIds();
    }
  }, [roadsMetadata.state, roadIntersectionClusterIds.state, fetchRoadIntersectionClusterIds]);

  useEffect(() => {
    if (roadsMetadata.state === DataState.AVAILABLE) {
      if (roadIntersectionClusterVolumes.state === DataState.EMPTY) {
        fetchRoadIntersectionClusterVolumes();
      }

      if (roadIntersectionClusterVolumes.state === DataState.AVAILABLE) {
        updateVolumesRef.current?.();
      }
    }
  }, [
    roadsMetadata.state,
    roadIntersectionClusterVolumes.state,
    fetchRoadIntersectionClusterVolumes,
    updateVolumesRef,
  ]);

  // Fetch intersection volume details for selected node
  useEffect(() => {
    if (
      roadsMetadata.state === DataState.AVAILABLE &&
      selectedIntersectionId &&
      roadIntersectionVolumeDetails.state === DataState.EMPTY
    ) {
      fetchRoadIntersectionVolumeDetails(Number(selectedIntersectionId), measure);
    }
  }, [
    roadsMetadata.state,
    roadIntersectionVolumes.state,
    roadIntersectionVolumeDetails.state,
    measure,
    selectedIntersectionId,
    fetchRoadIntersectionVolumeDetails,
  ]);

  // Clear details state when intersection is selected or unselected
  useEffect(() => {
    clearRoadIntersectionVolumeDetails();
  }, [selectedIntersectionId, clearRoadIntersectionVolumeDetails]);

  // Update analytics panel ref function
  useEffect(() => {
    (closeAnalyticsPanelRef as MutableRefObject<() => void>).current = closeRoadIntersectionsAnalyticsPanel;
  }, [closeAnalyticsPanelRef, closeRoadIntersectionsAnalyticsPanel]);

  useEffect(() => {
    if (selectedScreenlineId || isScreelineEditorOpen) {
      closeAnalyticsPanelRef.current?.();
    }
  }, [selectedScreenlineId, isScreelineEditorOpen, closeAnalyticsPanelRef]);

  // Set module data once, source will be turned on/off based on the selected measure
  useEffect(() => {
    if (
      selectedFocusArea &&
      roadsMetadata.state === DataState.AVAILABLE &&
      roadIntersectionVolumes.data &&
      roadIntersectionIds.data &&
      roadIntersectionClusterVolumes.data &&
      roadIntersectionClusterIds.data
    ) {
      // TODO: make filtering by ids dynamic, update layer filter in a separate useEffect
      const ids = memoryStore.getItem(MemoryStoreKeys.ROAD_INTERSECTION_IDS) as number[];

      const clusterIds = memoryStore.getItem(MemoryStoreKeys.ROAD_INTERSECTION_CLUSTER_IDS) as Map<
        number,
        Map<number, number>
      >;

      const areClustersAvailable = clusterIds && clusterIds.size > 0;
      const clusterSources = areClustersAvailable ? getRoadIntersectionClusterSources(roadsMetadata.data, measure) : [];

      const sources = [...getRoadIntersectionsSources(roadsMetadata.data, measure), ...clusterSources];

      const baseLayers = getRoadIntersectionsLayers(
        showRoadIntersectionsRef,
        baseLevel?.minZoomLevel || 15,
        baseLevel?.maxZoomLevel || 22,
        baseLevel,
        ids,
      );

      const clusterLayers = areClustersAvailable
        ? getRoadIntersectionClusterLayers(showRoadIntersectionsRef, clusterLevels, clusterIds)
        : [];

      const layers = [...baseLayers, ...clusterLayers];

      setRoadIntersectionsModuleData((data) => {
        if (data && data.data.tileService.url === roadsMetadata.data!.tileService.url) {
          return data;
        } else {
          closeAnalyticsPanelRef.current?.();

          return {
            sources,
            layers,
            data: {
              tileService: roadsMetadata.data!.tileService,
              changeShowRoadIntersections,
              changeRoadIntersectionsOpacity,
              levels: allLevels,
              volumesDetails: roadIntersectionVolumes.data,
              clusterVolumesDetails: roadIntersectionClusterVolumes.data,
              mapboxRoadIntersectionHoverPopupRef,
              selectRoadIntersectionId,
              selectedIntersectionIdRef,
              closeAnalyticsPanelRef,
            },
          };
        }
      });

      if (baseLevel?.tileService.layerName) {
        setBaseLayerName(baseLevel.tileService.layerName);
      }
    }
  }, [
    selectedFocusArea,
    measure,
    showRoadIntersectionsRef,
    roadsMetadata.state,
    roadsMetadata.data,
    roadIntersectionVolumes.data,
    roadIntersectionIds.data,
    roadIntersectionClusterVolumes.data,
    roadIntersectionClusterIds.data,
    baseLevel,
    clusterLevels,
    allLevels,
    changeShowRoadIntersections,
    changeRoadIntersectionsOpacity,
    setRoadIntersectionsModuleData,
    selectRoadIntersectionId,
    closeAnalyticsPanelRef,
    selectedIntersectionIdRef,
  ]);

  return null;
};
