import { Close, DirectionsCar, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Button, Checkbox, Divider, IconButton, TextField } from "components_new";
import React, { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "hooks";

import { globalActions } from "store/sections/global";

import { RoadClass } from "types";

export interface RoadClassSelectorProps {
  selectedRoadClasses: RoadClass[] | null;
  savedRoadClasses: number[] | null;
  loading?: boolean;
  disabled?: boolean;
  error?: boolean;
  setSelectedRoadClasses: Dispatch<SetStateAction<RoadClass[] | null>>;
}

const StyledAccordion = styled((props: AccordionProps) => <Accordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-of-type)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }),
);

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: 0,
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,
}));

export const RoadClassSelector: FC<RoadClassSelectorProps> = ({
  selectedRoadClasses,
  savedRoadClasses,
  loading,
  disabled,
  error,
  setSelectedRoadClasses,
}) => {
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled && !loading) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const roadCategories = useAppSelector((state) => state.analytics.roadsMetadata.data?.roadCategories);
  const roadClasses = useAppSelector((state) => state.analytics.roadsMetadata.data?.roadClasses);
  const collapsedRoadClassContainers = useAppSelector((state) => state.global.collapsedRoadclassContainers);

  const groupedRoadClasses = useMemo(
    () =>
      roadClasses?.reduce((obj: { [key: number]: RoadClass[] }, rc) => {
        if (!obj[rc.category]) obj[rc.category] = [rc];
        else obj[rc.category].push(rc);
        return obj;
      }, {}),
    [roadClasses],
  );

  const handleChangeRoadClasses = (roadClasses: RoadClass[], wasChecked: boolean) => {
    const newSelectedRoadClasses =
      (wasChecked
        ? selectedRoadClasses?.filter((rc) => !roadClasses.find(({ id }) => id === rc.id))
        : [...(selectedRoadClasses || []), ...roadClasses]) || [];
    setSelectedRoadClasses(newSelectedRoadClasses);
  };

  const getSelectorLabel = () => {
    if (!selectedRoadClasses?.length) return "None";
    if (selectedRoadClasses.length === roadClasses?.length) return "All";
    return `${selectedRoadClasses.sort((a, b) => a.id - b.id)[0].label} + ${
      (selectedRoadClasses.length || 0) - 1
    } more`;
  };

  const toggleRoadClassesContainerCollapsedState = (roadCategoryId: string) => {
    dispatch(globalActions.toggleRoadclassesContainerCollapsedState(roadCategoryId));
  };

  useEffect(() => {
    if (selectedRoadClasses === null && roadClasses) {
      const initialRoadClasses = roadClasses.filter((rc) =>
        savedRoadClasses ? savedRoadClasses?.includes(rc.id) : true,
      );
      setSelectedRoadClasses(initialRoadClasses);
    }
  }, [selectedRoadClasses, roadClasses, savedRoadClasses, setSelectedRoadClasses]);

  return (
    <>
      <TextField
        fullWidth
        select
        disabled={disabled || loading}
        error={error}
        label={"Road Classes"}
        value={1}
        onClick={handleClick}
        SelectProps={{ open: false }}
      >
        <MenuItem value={1}>
          <Grid container alignItems={"center"} wrap="nowrap">
            <ListItemIcon sx={{ minWidth: "0 !important", marginRight: "8px" }}>
              {<DirectionsCar fontSize="small" color="secondary" />}
            </ListItemIcon>
            <ListItemText
              primary={getSelectorLabel()}
              primaryTypographyProps={{ noWrap: true, fontSize: 14 }}
              sx={{ margin: 0 }}
            />
          </Grid>
        </MenuItem>
      </TextField>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box width={240}>
          <Grid container justifyContent={"space-between"} padding={1}>
            <Typography fontSize={14} fontWeight={700} maxWidth={200} marginLeft={1} noWrap>
              Road Classes
            </Typography>
            <IconButton size="small" onClick={handleClose}>
              <Close fontSize="inherit" />
            </IconButton>
          </Grid>
          <Divider />
          <Box sx={{ overflowY: "auto", maxHeight: "560px" }}>
            <Stack justifyContent={"center"}>
              {roadCategories?.map((roadCategory) => {
                const roadClassesInCategory = groupedRoadClasses?.[roadCategory.id] || [];
                const selectedRoadClassesByCategory = roadClassesInCategory.filter((rc) =>
                  selectedRoadClasses?.includes(rc),
                );
                const areAllRoadClassesSelected = selectedRoadClassesByCategory.length === roadClassesInCategory.length;
                const areSomeRoadClassesSelected =
                  selectedRoadClassesByCategory.length > 0 &&
                  selectedRoadClassesByCategory.length < roadClassesInCategory.length;

                return (
                  <StyledAccordion
                    key={roadCategory.id}
                    expanded={!collapsedRoadClassContainers.includes(roadCategory.id.toString())}
                    onChange={() => toggleRoadClassesContainerCollapsedState(roadCategory.id.toString())}
                  >
                    <StyledAccordionSummary expandIcon={<ExpandMore />}>
                      <Grid container justifyContent={"space-between"} alignItems={"center"} paddingRight={1}>
                        <Checkbox
                          label={
                            <Typography fontSize={12} fontWeight={700}>
                              {roadCategory.label}
                            </Typography>
                          }
                          color="secondary"
                          checked={areAllRoadClassesSelected}
                          indeterminate={areSomeRoadClassesSelected}
                          onChange={() =>
                            handleChangeRoadClasses(roadClassesInCategory, Boolean(areAllRoadClassesSelected))
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                        <Typography fontSize={11} color={"text.secondary"}>
                          {selectedRoadClassesByCategory.length} / {roadClassesInCategory.length}
                        </Typography>
                      </Grid>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                      <Stack spacing={1} justifyContent={"center"} paddingLeft={2} marginBottom={1}>
                        {groupedRoadClasses?.[roadCategory.id]?.map((rc) => {
                          const isChecked = Boolean(selectedRoadClasses?.find(({ id }) => rc.id === id));
                          return (
                            <Checkbox
                              key={rc.id}
                              label={rc.label}
                              color="secondary"
                              checked={isChecked}
                              onChange={() => handleChangeRoadClasses([rc], isChecked)}
                            />
                          );
                        })}
                      </Stack>
                    </StyledAccordionDetails>
                  </StyledAccordion>
                );
              })}
            </Stack>
          </Box>
          <Divider />

          <Stack direction={"row"} spacing={1} justifyContent={"space-between"} paddingX={1} paddingY={0.5}>
            <Button variant="text" onClick={() => setSelectedRoadClasses([])}>
              Clear all
            </Button>
            <Button variant="text" onClick={() => setSelectedRoadClasses(roadClasses || [])}>
              Select all
            </Button>
          </Stack>
        </Box>
      </Popover>
    </>
  );
};
