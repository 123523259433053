import styled from "@emotion/styled";
import { Grid, Stack } from "@mui/material";
import { CircularProgress, CountBadge } from "components_new";
import { FC } from "react";

import { useAppSelector } from "hooks";

import { selectRoadIntersectionsLoading } from "store/sections/roadIntersections";

export interface RoadIntersectionPopupContentProps {
  legs?: number;
  size?: number;
  volume: number;
}

export const IntersectionDetails = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding: 0 0.5rem;
  white-space: nowrap;
`;

export const RoadIntersectionPopupContent: FC<RoadIntersectionPopupContentProps> = ({ legs, size, volume }) => {
  const loading = useAppSelector(selectRoadIntersectionsLoading);

  return (
    <>
      {loading ? (
        <Grid container alignItems={"center"} justifyContent={"center"} minWidth={200} minHeight={32}>
          <CircularProgress size={16} color="secondary" />
        </Grid>
      ) : (
        <Stack alignItems="center" direction="row" padding={"4px 8px"}>
          {legs && <IntersectionDetails>{legs}-leg intersection</IntersectionDetails>}
          {size && <IntersectionDetails>{size}-points cluster</IntersectionDetails>}
          <CountBadge label={volume} />
        </Stack>
      )}
    </>
  );
};
