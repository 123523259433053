import { Box } from "@mui/material";
import { MapControlContainer, SliderControl, VisibilityIconButton } from "components_new";
import { FC, MutableRefObject, useEffect, useState } from "react";

import { ModuleData } from "features/map/ModuleManager";
import { calculateWidthFactor, changeRoadIntersectionsLayersPaintExpressions } from "features/road-intersections";

import { useAppDispatch, useAppSelector } from "hooks";

import { globalActions } from "store/sections/global";
import { roadIntersectionsActions, selectRoadIntersectionsLoading } from "store/sections/roadIntersections";

import { MapLayerContainerId } from "types";

export interface RoadIntersectionsMapLayersProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  moduleData: ModuleData;
}

function valueLabelFormat(value: number) {
  return `x ${Math.round(value * 100) / 100}`;
}

export const RoadIntersectionsMapLayers: FC<RoadIntersectionsMapLayersProps> = ({ map, moduleData }) => {
  const { changeShowRoadIntersections, changeRoadIntersectionsOpacity, levels } = moduleData.data;

  const dispatch = useAppDispatch();
  const collapsedMapLayerContainers = useAppSelector((state) => state.global.collapsedMapLayerContainers);
  const loading = useAppSelector(selectRoadIntersectionsLoading);

  const showRoadIntersections = useAppSelector((state) => state.roadIntersections.showRoadIntersections);
  const maxVolumes = useAppSelector((state) => state.roadIntersections.maxVolumes);

  const savedOpacityFactor = useAppSelector((state) => state.roadIntersections.opacityFactor);
  const savedWidthFactor = useAppSelector((state) => state.roadIntersections.widthFactor);

  const [widthFactor, setWidthFactor] = useState(savedWidthFactor);
  const [opacityFactor, setOpacityFactor] = useState(savedOpacityFactor);

  const handleChangeWidth = (newValue: number) => {
    dispatch(roadIntersectionsActions.setWidthFactor(newValue));
  };

  const handleChangeOpacity = (newValue: number) => {
    dispatch(roadIntersectionsActions.setOpacityFactor(newValue));
    changeRoadIntersectionsOpacity(newValue);
  };

  const handleToggleRoadIntersections = () => {
    dispatch(roadIntersectionsActions.setShowRoadIntersections(!showRoadIntersections));
    changeShowRoadIntersections(!showRoadIntersections);
  };

  // Set layers width factor after fetching counts and determining max volumes
  useEffect(() => {
    if (map.current) {
      changeRoadIntersectionsLayersPaintExpressions(map.current, levels, savedWidthFactor, maxVolumes);
    }
  }, [map, levels, savedWidthFactor, maxVolumes]);

  return (
    <>
      <MapControlContainer
        title="Intersection Volumes"
        primaryAction={<VisibilityIconButton visible={showRoadIntersections} onClick={handleToggleRoadIntersections} />}
        collapse
        expanded={!collapsedMapLayerContainers.includes(MapLayerContainerId.ROAD_INTERSECTIONS)}
        onChange={() =>
          dispatch(globalActions.toggleLayerContainerCollapsedState(MapLayerContainerId.ROAD_INTERSECTIONS))
        }
      >
        <Box padding={1}>
          <SliderControl
            label="Opacity"
            disabled={loading}
            value={opacityFactor}
            defaultValue={1}
            min={0}
            max={1}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            onChange={(e, value) => setOpacityFactor(value as number)}
            onChangeCommitted={(e, value) => handleChangeOpacity(value as number)}
          />
          <SliderControl
            label="Width"
            disabled={loading}
            value={widthFactor}
            defaultValue={1}
            min={0}
            max={2}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            scale={calculateWidthFactor}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            valueLabelDisplay="auto"
            onChange={(e, value) => setWidthFactor(value as number)}
            onChangeCommitted={(e, value) => handleChangeWidth(value as number)}
          />
        </Box>
      </MapControlContainer>
    </>
  );
};
