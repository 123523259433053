import { Screenline, ScreenlineValidationResponse } from "api/analytics/index.d";
import { Feature, Point } from "geojson";
import { isEqual } from "lodash";
import { Map, MapLayerMouseEvent, Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, RefObject, SetStateAction } from "react";

import { closeAllMapboxPopups } from "features/map/utils";
import {
  IntersectionPopupContentProps,
  ScreenlineGeometryPopupContentProps,
  ScreenlinePopupContentProps,
} from "features/screenline";

import { RoadNetworkType } from "types";

import { SCREENLINES_INTERSECTIONS_LAYER_ID, SCREENLINES_LAYER_ID, SCREENLINES_SEGMENTS_LAYER_ID } from "./layers";
import { SCREENLINES_INTERSECTIONS_SOURCE_ID, SCREENLINES_SEGMENTS_SOURCE_ID, SCREENLINES_SOURCE_ID } from "./sources";

export const getScreenlineHandler = (
  map: Map,
  draw: any,
  screenlinePopupRef: MutableRefObject<HTMLDivElement | null>,
  setScreenlinePopupPropsRef: MutableRefObject<Dispatch<SetStateAction<ScreenlinePopupContentProps | null>> | null>,
  intersectionPopupRef: MutableRefObject<HTMLDivElement | null>,
  setIntersectionPopupPropsRef: MutableRefObject<Dispatch<SetStateAction<IntersectionPopupContentProps | null>> | null>,
  screenlinGeometryPopupRef: MutableRefObject<HTMLDivElement | null>,
  setScreenlineGeometryPopupPropsRef: MutableRefObject<Dispatch<
    SetStateAction<ScreenlineGeometryPopupContentProps | null>
  > | null>,
  mapboxScreenlineHoverPopupRef: RefObject<Popup>,
  mapboxIntersectionClickPopupRef: RefObject<Popup>,
  mapboxGeometryClickPopupRef: MutableRefObject<Popup>,
  selectedScreenlineIdRef: MutableRefObject<string | null>,
  selectedIntersectionIdRef: MutableRefObject<string | null>,
  isEditScreenlineModeRef: MutableRefObject<boolean | undefined>,
  screenlinesRef: MutableRefObject<Screenline[]>,
  screenlineValidationDataRef: MutableRefObject<ScreenlineValidationResponse | null>,
  isDrawModeRef: MutableRefObject<boolean | null>,
  networkTypeRef: MutableRefObject<RoadNetworkType | null>,
  setSelectedScreenlineId: (id: string | null) => void,
  setSelectedIntersectionId: (intersectionId: string | null) => void,
  setDraftFeature: (feature: Feature | null) => void,
  setDrawMode: (isDrawMode: boolean) => void,
) => {
  let clickedScreenline: { id: string; source: string } | null = null;
  let clickedIntersection: { id: string; source: string } | null = null;
  let hoveredItem: { id: string; source: string } | null = null;
  let clickedVertex: any | null = null;

  const setHoveredItem = (itemId: string | null, sourceId: string) => {
    if (
      map &&
      map.getSource(SCREENLINES_SOURCE_ID) &&
      map.getSource(SCREENLINES_SEGMENTS_SOURCE_ID) &&
      map.getSource(SCREENLINES_INTERSECTIONS_SOURCE_ID)
    ) {
      if (hoveredItem) {
        map.setFeatureState(
          {
            source: hoveredItem.source,
            id: hoveredItem.id,
          },
          { hover: false },
        );
      }

      if (itemId) {
        map.getCanvas().style.cursor = "default";
        map.setFeatureState(
          {
            source: sourceId,
            id: itemId,
          },
          { hover: true },
        );
        hoveredItem = { id: itemId, source: sourceId };
      }
    }
  };

  const setHoveredScreenline = (screenlineId: string | null) => {
    setHoveredItem(screenlineId, SCREENLINES_SOURCE_ID);
  };

  const setHoveredSegment = (segmentId: string | null) => {
    setHoveredItem(segmentId, SCREENLINES_SEGMENTS_SOURCE_ID);
  };

  const setHoveredIntersection = (intersectionId: string | null) => {
    setHoveredItem(intersectionId, SCREENLINES_INTERSECTIONS_SOURCE_ID);
  };

  const setClickedScreenline = (screenlineId: string | null) => {
    if (map && map.getSource(SCREENLINES_SOURCE_ID)) {
      if (clickedScreenline) {
        map.setFeatureState(
          {
            source: clickedScreenline.source,
            id: clickedScreenline.id,
          },
          { click: false },
        );
      }

      if (screenlineId) {
        map.setFeatureState(
          {
            source: SCREENLINES_SOURCE_ID,
            id: screenlineId,
          },
          { click: true },
        );

        clickedScreenline = { id: screenlineId, source: SCREENLINES_SOURCE_ID };
      }
    }

    setSelectedScreenlineId(screenlineId);
  };

  const setClickedIntersection = (intersectionId: string | null) => {
    if (mapboxIntersectionClickPopupRef.current) {
      mapboxIntersectionClickPopupRef.current.remove();
    }

    if (map && map.getSource(SCREENLINES_INTERSECTIONS_SOURCE_ID)) {
      if (clickedIntersection) {
        map.setFeatureState(
          {
            source: clickedIntersection.source,
            id: clickedIntersection.id,
          },
          { click: false },
        );
      }

      if (intersectionId) {
        map.setFeatureState(
          {
            source: SCREENLINES_INTERSECTIONS_SOURCE_ID,
            id: intersectionId,
          },
          { click: true },
        );

        clickedIntersection = { id: intersectionId, source: SCREENLINES_INTERSECTIONS_SOURCE_ID };
      }
    }
    setSelectedIntersectionId(intersectionId);
  };

  const handleMouseMove = (e: MapLayerMouseEvent) => {
    map.getCanvas().style.cursor = "default";
    const feature = e.features?.[0];
    const featureId = feature?.id?.toString();

    if (mapboxScreenlineHoverPopupRef.current) {
      mapboxScreenlineHoverPopupRef.current.remove();
      // setScreenlinePopupPropsRef.current?.(null);
    }

    if (featureId) {
      if (feature?.layer.id === SCREENLINES_INTERSECTIONS_LAYER_ID) {
        setHoveredIntersection(featureId);
        return;
      }

      if (feature?.layer.id === SCREENLINES_SEGMENTS_LAYER_ID) {
        setHoveredSegment(featureId);
        return;
      }
      if (feature?.layer.id === SCREENLINES_LAYER_ID) {
        const {
          toLeft,
          toRight,
          leftLabel,
          rightLabel,
          segmentIntersectionsLength,
          totalSegmentIntersections,
          network,
        } = feature?.properties as any;

        if (networkTypeRef.current !== network) return;

        const hoveredScreenline = screenlinesRef.current?.find((s) => s.id === featureId);

        setHoveredScreenline(featureId);
        setScreenlinePopupPropsRef.current?.({
          toLeft,
          toRight,
          leftLabel,
          rightLabel,
          segmentIntersectionsLength,
          totalSegmentIntersections,
          intersectioDirectionFilter: hoveredScreenline?.intersectionDirectionFilter,
          network,
        });
        if (screenlinePopupRef.current) {
          (mapboxScreenlineHoverPopupRef.current as mapboxgl.Popup) = new Popup({
            closeButton: false,
            closeOnClick: false,
            offset: 15,
          })
            .setLngLat(e.lngLat)
            .setDOMContent(screenlinePopupRef.current as Node)
            .addTo(map);
        }
      }
    }
  };

  const handleMouseLeave = () => {
    if (
      hoveredItem &&
      map &&
      map.getSource(SCREENLINES_SOURCE_ID) &&
      map.getSource(SCREENLINES_SEGMENTS_SOURCE_ID) &&
      map.getSource(SCREENLINES_INTERSECTIONS_SOURCE_ID)
    ) {
      map.getCanvas().style.cursor = "";

      if (mapboxScreenlineHoverPopupRef.current) {
        mapboxScreenlineHoverPopupRef.current.remove();
        // setScreenlinePopupPropsRef.current?.(null);
      }

      map.setFeatureState(
        {
          source: hoveredItem.source,
          id: hoveredItem.id,
        },
        { hover: false },
      );
      hoveredItem = null;
    }
  };

  const handleClick = (e: MapLayerMouseEvent) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: [SCREENLINES_LAYER_ID, SCREENLINES_SEGMENTS_LAYER_ID, SCREENLINES_INTERSECTIONS_LAYER_ID],
    });
    const feature = features?.[0];
    const featureId = feature?.id?.toString();

    if (mapboxIntersectionClickPopupRef.current) {
      mapboxIntersectionClickPopupRef.current.remove();
    }

    if (featureId && feature?.properties) {
      if (feature?.layer.id === SCREENLINES_INTERSECTIONS_LAYER_ID) {
        setClickedIntersection(featureId);
        // if (isEditScreenlineModeRef.current) {
        //   const geometry = feature.geometry as Point;
        //   const coordinates = geometry.coordinates;
        //   setIntersectionPopupPropsRef.current?.({
        //     featuresProps: features
        //       .filter((f) => f.layer.id === SCREENLINES_INTERSECTIONS_LAYER_ID)
        //       .map((f) => f.properties),
        //   });
        //   if (intersectionPopupRef.current) {
        //     (mapboxIntersectionClickPopupRef.current as Popup) = new Popup({
        //       closeButton: false,
        //       closeOnClick: false,
        //       offset: 15,
        //     })
        //       .setLngLat(coordinates as any)
        //       .setDOMContent(intersectionPopupRef.current as Node)
        //       .addTo(map);
        //   }
        // }

        return;
      }

      if (feature?.layer.id === SCREENLINES_SEGMENTS_LAYER_ID) {
        return;
      }

      if (feature?.layer.id === SCREENLINES_LAYER_ID && !isEditScreenlineModeRef.current && !isDrawModeRef.current) {
        if (networkTypeRef.current !== feature.properties.network) return;

        setClickedScreenline(featureId);
        return;
      }
    }

    if (selectedIntersectionIdRef.current) setClickedIntersection(null);
    if (!isEditScreenlineModeRef.current && !isDrawModeRef.current && selectedScreenlineIdRef.current)
      setClickedScreenline(null);
  };

  const handleSetDraftFeature = (e: any) => {
    if (mapboxGeometryClickPopupRef.current) {
      mapboxGeometryClickPopupRef.current.remove();
    }

    setDraftFeature(e.features[0]);
  };

  const handleClickOnDraftScreenline = (e: any) => {
    const point = e.points[0];
    const feature = e.features[0];

    if (mapboxGeometryClickPopupRef.current) {
      mapboxGeometryClickPopupRef.current.remove();
    }

    if (isEqual(clickedVertex, point)) {
      mapboxGeometryClickPopupRef.current?.remove();
      clickedVertex = null;
    } else if (point) {
      const geometry = point.geometry as Point;
      const coordinates = geometry.coordinates;
      const featureCoords = feature.geometry.coordinates;
      const firstPointCoords = featureCoords[0];
      const lastPointCoords = featureCoords[featureCoords.length - 1];

      if (isEqual(coordinates, firstPointCoords) || isEqual(coordinates, lastPointCoords)) return;

      mapboxGeometryClickPopupRef.current = new Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 15,
      })
        .setLngLat(coordinates as any)
        .setDOMContent(screenlinGeometryPopupRef.current as Node)
        .addTo(map);

      clickedVertex = point;
      setScreenlineGeometryPopupPropsRef.current?.({
        draw: null as any,
        onClose: () => mapboxGeometryClickPopupRef.current.remove(),
      });
    }
  };

  const handleModeChange = (e: any) => {
    const drawFeatures = draw.getAll();
    if (!drawFeatures.features.length) setDrawMode(false);
  };

  map.on(
    "mousemove",
    [SCREENLINES_LAYER_ID, SCREENLINES_SEGMENTS_LAYER_ID, SCREENLINES_INTERSECTIONS_LAYER_ID],
    handleMouseMove,
  );
  map.on(
    "mouseleave",
    [SCREENLINES_LAYER_ID, SCREENLINES_SEGMENTS_LAYER_ID, SCREENLINES_INTERSECTIONS_LAYER_ID],
    handleMouseLeave,
  );
  map.on("click", handleClick);

  map.on("draw.selectionchange", handleClickOnDraftScreenline);
  map.on("draw.create", handleSetDraftFeature);
  map.on("draw.update", handleSetDraftFeature);
  map.on("draw.modechange", handleModeChange);

  return {
    cleanScreenlineHandlers: () => {
      if (mapboxGeometryClickPopupRef.current) {
        mapboxGeometryClickPopupRef.current.remove();
      }

      closeAllMapboxPopups(map);

      map.off(
        "mousemove",
        [SCREENLINES_LAYER_ID, SCREENLINES_SEGMENTS_LAYER_ID, SCREENLINES_INTERSECTIONS_LAYER_ID],
        handleMouseMove,
      );
      map.off(
        "mouseleave",
        [SCREENLINES_LAYER_ID, SCREENLINES_SEGMENTS_LAYER_ID, SCREENLINES_INTERSECTIONS_LAYER_ID],
        handleMouseLeave,
      );

      map.off("click", handleClick);

      map.off("draw.selectionchange", handleClickOnDraftScreenline);
      map.off("draw.create", handleSetDraftFeature);
      map.off("draw.update", handleSetDraftFeature);
      map.off("draw.modechange", handleModeChange);
    },

    setHoveredScreenline,
    setClickedScreenline,
    setClickedIntersection,
  };
};
