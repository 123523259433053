import { Grid } from "@mui/material";
import { CircularProgress } from "components_new";
import { FC, memo, useMemo } from "react";
import { CartesianGrid, ReferenceLine, Scatter, ScatterChart, XAxis, YAxis, ZAxis } from "recharts";

import { MapErrorPage } from "components";

import { AreaAccuracyScatterPlotItem } from "types";

import { roadClassGroups } from "./roadClassGroups";

export interface AreaAccuracyScatterPlotProps {
  data: AreaAccuracyScatterPlotItem[];
  selectedRoadClasses: number[] | null;
  loading?: boolean;
  error?: boolean;
}

export const AreaAccuracyScatterPlot: FC<AreaAccuracyScatterPlotProps> = memo(
  ({ data, selectedRoadClasses, loading, error }) => {
    const [roadClassScattersData, domainMax] = useMemo(() => {
      let aadtMax = 0;
      let refCountMax = 0;

      const roadClassScattersData = data.reduce((obj: { [key: string]: AreaAccuracyScatterPlotItem[] }, item) => {
        aadtMax = Math.max(aadtMax, item.aadt);
        refCountMax = Math.max(refCountMax, item.refCount);

        if (!obj[item.roadClassGroup]) {
          obj[item.roadClassGroup] = [item];
        } else {
          obj[item.roadClassGroup].push(item);
        }
        return obj;
      }, {});

      return [roadClassScattersData, Math.max(aadtMax, refCountMax) + 1000];
    }, [data]);

    return (
      <Grid container alignItems={"center"} justifyContent={"center"}>
        {error ? (
          <Grid container padding={2}>
            <MapErrorPage size="sm" />
          </Grid>
        ) : loading ? (
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        ) : (
          <ScatterChart width={700} height={400}>
            <CartesianGrid />
            {Object.keys(roadClassScattersData).length && (
              <ReferenceLine
                stroke="#E6E6E6"
                ifOverflow="hidden"
                segment={[
                  { x: 0, y: 0 },
                  { x: 100000000, y: 100000000 },
                ]}
              />
            )}
            <XAxis
              type="number"
              dataKey="refCount"
              name="Observed traffic counts"
              fontSize={8}
              label={{ value: "Observed traffic counts", position: "bottom", offset: -10 }}
              domain={[0, domainMax]}
            />
            <YAxis
              type="number"
              dataKey="aadt"
              name="Patterns AADT"
              fontSize={8}
              label={{ value: "Patterns AADT", angle: -90, position: "left", offset: -15 }}
              domain={[0, domainMax]}
            />
            <ZAxis
              dataKey={(entry) => roadClassGroups[entry.roadClassGroup].label}
              type="category"
              name="Road class"
              range={[30, 30]}
            />
            {Object.entries(roadClassScattersData).map(([key, value]) => (
              <Scatter
                key={key}
                hide={
                  !roadClassGroups[key].classes
                    .map((c) => c.id)
                    .some((roadClass) => selectedRoadClasses?.includes(roadClass))
                }
                name={roadClassGroups[key].label}
                data={value}
                fill={roadClassGroups[key].color}
                fillOpacity={0.25}
                isAnimationActive={false}
                shape={roadClassGroups[key].symbol}
                legendType={roadClassGroups[key].symbol}
              />
            ))}
          </ScatterChart>
        )}
      </Grid>
    );
  },
);
