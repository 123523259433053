import { Stack, styled } from "@mui/material";
import { AnalyticsContainer, Badge, MapControlContainer } from "components_new";
import find from "lodash/find";
import React, { FC } from "react";

// import { FlowsSettingsControls } from "../filters/FlowsSettingsControls";
import { TopFlowsChart } from "features/map-visualization/TopFlowsChart";
import { getCustomFlowColor, getFlowPattern } from "features/map/utils";

import { BarChart, Legend } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { globalActions } from "store/sections/global";

import {
  Dimension,
  FlowPattern,
  FlowsSettings,
  HoveredFlow,
  QueryType,
  SelectedArea,
  ZoneDetails,
  ZoningLevel,
} from "types";

interface ODAnalyticsProps {
  isCustomDataset: boolean;
  zoneDetails: ZoneDetails;
  selectedZone: SelectedArea;
  flowsDirection: QueryType;
  flowsSettings: FlowsSettings;
  zoningLevels: ZoningLevel[] | undefined;
  setFlowsSettings: (settings: FlowsSettings) => void;
  hoveredFlow: HoveredFlow | null;
  setHoveredFlow: (hoveredTopFlow: HoveredFlow | null) => void;
  odMetadataDimensions: Dimension[] | undefined;
}

const TopFlowCountBadge = styled(Badge)`
  width: 50px;
  height: 22px;
`;

const EmptyState = styled("div")`
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 20px;
  margin: 20px;
  font-size: var(--font-size-sm);
  font-weight: bold;
`;

const TopFlowLegend = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 20px 20px 20px;

  & > div {
    display: flex;
    flex-direction: row;

    & > h5 {
      margin: 0 4px 0 4px;
    }
  }
`;

const LegendTitle = styled("h5")`
  font-weight: 500;
  padding: 0 4px 0 6px;
  margin-left: 4px;
  border-left: 1.5px solid #abb3bf;
`;

// TODO move to utils (used from roads, vmt etc.)
export const getLabelByDimension = (dimensionName: string, dimensions: Dimension[]): string => {
  return find(dimensions, { columnName: dimensionName })?.label || dimensionName;
};

// TODO move to utils (used from roads, vmt etc.)
export const getLabelByCategories = (category: string, dimensionName: string, dimensions: Dimension[]): string => {
  const categories = find(dimensions, {
    columnName: dimensionName,
  })?.categories;

  if (categories && categories.length > 0) {
    return find(categories, { value: category })?.label || category;
  }

  return category;
};

const getFlowLegendLabel = (flowPattern: FlowPattern) => {
  switch (flowPattern) {
    case FlowPattern.internal:
      return "Internal";
    case FlowPattern.gate:
      return "Gates";
    case FlowPattern.counties:
      return "Counties";
    case FlowPattern.internalRound:
      return "Internal & Round";
    default:
      return "Unknown";
  }
};

const ODAnalytics: FC<ODAnalyticsProps> = ({
  isCustomDataset,
  selectedZone,
  flowsDirection,
  flowsSettings,
  setFlowsSettings,
  zoneDetails,
  hoveredFlow,
  odMetadataDimensions,
  zoningLevels,
  setHoveredFlow,
}) => {
  const dispatch = useAppDispatch();
  const collapsedDimensionContainers = useAppSelector((state) => state.global.collapsedDimensionContainers);

  const flowTitleByDimension = `Top ${flowsDirection.charAt(0).toUpperCase() + flowsDirection.slice(1)} ${
    flowsDirection === QueryType.INCOMING ? "Origins" : "Destinations"
  }`;

  const flowData = zoneDetails?.topFlows?.[flowsDirection]?.flows.map((flow, index) => ({
    id: flow.id,
    name: `${index + 1}`,
    value: flow.value,
    flowPattern: getFlowPattern(flow, selectedZone.id, selectedZone.itemType),
    level: flow.level,
    isGate: !!flow.isGate,
    areaName: flow.areaName || flow.id,
    levelName: flow.levelName,
    countryName: flow.countryName,
    external: !!flow.external,
  }));

  const breakdowns = zoneDetails?.summaries?.[flowsDirection]?.breakdowns;

  const getTopFlowsLegend = () => (
    <>
      <Legend
        key={FlowPattern.internal}
        label={getFlowLegendLabel(FlowPattern.internal)}
        height={15}
        color={getCustomFlowColor(FlowPattern.internal)}
        isSquare
      />
      <LegendTitle>External:</LegendTitle>
      {isCustomDataset && (
        <Legend
          key={FlowPattern.gate}
          label={getFlowLegendLabel(FlowPattern.gate)}
          height={15}
          color={getCustomFlowColor(FlowPattern.gate)}
          isSquare
        />
      )}
      {
        <Legend
          key={FlowPattern.counties}
          label={getFlowLegendLabel(FlowPattern.counties)}
          height={15}
          color={getCustomFlowColor(FlowPattern.counties)}
          isSquare
        />
      }
    </>
  );

  return (
    <AnalyticsContainer>
      <MapControlContainer
        collapse
        expanded={!collapsedDimensionContainers.includes("top_flows")}
        onChange={() => dispatch(globalActions.toggleDimensionContainerCollapsedState("top_flows"))}
        title={flowTitleByDimension}
        secondaryAction={
          <Stack direction={"row"} spacing={1} marginRight={1}>
            <TopFlowCountBadge
              clickable
              onClick={() =>
                setFlowsSettings({
                  ...flowsSettings,
                  n: 10,
                })
              }
              variant={flowsSettings.n === 10 ? "filled" : "outlined"}
              label={"10"}
            />
            <TopFlowCountBadge
              clickable
              onClick={() =>
                setFlowsSettings({
                  ...flowsSettings,
                  n: 20,
                })
              }
              variant={flowsSettings.n === 20 ? "filled" : "outlined"}
              label={"20"}
            />
          </Stack>
        }
      >
        {flowData && flowData.length > 0 ? (
          <TopFlowsChart
            data={flowData}
            flowsDirection={flowsDirection}
            hoveredFlow={hoveredFlow}
            zoningLevels={zoningLevels}
            setHoveredFlow={setHoveredFlow}
            getCustomColor={getCustomFlowColor}
          />
        ) : (
          <EmptyState>No Trips Available</EmptyState>
        )}
        <TopFlowLegend style={{ marginBottom: 16 }}>{getTopFlowsLegend()}</TopFlowLegend>
      </MapControlContainer>

      {breakdowns.map((breakdown, index) => {
        const dimension = breakdown.dimensions[0];
        const data = breakdown.rows.map((row) => ({
          name: getLabelByCategories(row.categories[0], dimension, odMetadataDimensions || []),
          value: row.value,
        }));

        return data.length > 0 ? (
          <MapControlContainer
            key={index}
            title={getLabelByDimension(dimension, odMetadataDimensions || [])}
            collapse
            expanded={!collapsedDimensionContainers.includes(`od_${dimension}`)}
            onChange={() => dispatch(globalActions.toggleDimensionContainerCollapsedState(`od_${dimension}`))}
          >
            <BarChart data={data} layout="vertical" />
          </MapControlContainer>
        ) : null;
      })}
    </AnalyticsContainer>
  );
};

export { ODAnalytics };
