import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import {
  Bar,
  CartesianGrid,
  Cell,
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface DataChart {
  name: string;
  value: number;
}

interface BarChartProps {
  data: DataChart[];
  layout: "vertical" | "horizontal";
  getCustomColor?: (pattern: string) => string;
}

const ChartWrapper = styled.div`
  width: 100%;
  padding: 0 15px 0 5px;
`;

const TooltipWrapper = styled.div`
  padding: 0.5rem;
  background-color: #ffffff;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  font-size: 12px;
`;

const CustomToolTip = ({ active, payload, label }: any) => {
  if (active) {
    return (
      <TooltipWrapper>
        <p>{label}</p>
        <b>{payload[0].value.toLocaleString("en-US")}</b>
      </TooltipWrapper>
    );
  }
  return null;
};

const BLUE_CHART_COLOR = "#4584D1";
const CHART_FOCUS_COLOR = "#139EEC";
const BAR_DEFAULT_HEIGHT = 20;
const BAR_SEPARATION = 7;
const BAR_CATEGORY_GAP = 4;
const CHART_PADDING_TOP = 12;
const CHART_PADDING_BOTTOM = 2;
const CHART_PADDING_LEFT = 6;
const CHART_PADDING_RIGHT = 2;
const CHART_CANVAS_VERTICAL_PADDING = 30; // additional vertical padding for horizontal axis/labels and internal padding at the top
const DEFAULT_HORIZONATAL_LAYOUT_HEIGHT = "170";

export const BarChart: FC<BarChartProps> = ({ data, layout, getCustomColor }) => {
  const [focusBar, setFocusBar] = useState(null);

  const isVerticalLayout = layout === "vertical";

  const getCellFill = (entry: DataChart, index: number) => {
    if (getCustomColor) {
      return getCustomColor("hover");
    }

    return focusBar !== null ? (focusBar === index ? CHART_FOCUS_COLOR : BLUE_CHART_COLOR) : BLUE_CHART_COLOR;
  };

  return (
    <ChartWrapper
      style={{
        height: `${
          isVerticalLayout
            ? (BAR_DEFAULT_HEIGHT + BAR_SEPARATION) * data.length + CHART_PADDING_BOTTOM + CHART_PADDING_TOP + CHART_CANVAS_VERTICAL_PADDING
            : DEFAULT_HORIZONATAL_LAYOUT_HEIGHT
        }px`, 
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <RechartsBarChart
          data={data}
          layout={layout}
          barSize={BAR_DEFAULT_HEIGHT}
          barCategoryGap={BAR_CATEGORY_GAP}
          margin={{
            top: CHART_PADDING_TOP,
            bottom: CHART_PADDING_BOTTOM,
            left: CHART_PADDING_LEFT,
            right: CHART_PADDING_RIGHT,
          }}
          onMouseMove={(state: any) => {
            if (state.isTooltipActive) {
              setFocusBar(state.activeTooltipIndex);
            } else {
              setFocusBar(null);
            }
          }}
          onMouseLeave={() => {
            setFocusBar(null);
          }}
        >
          <CartesianGrid horizontal={!isVerticalLayout} vertical={isVerticalLayout} style={{ opacity: 0.5 }} />
          <XAxis
            type={isVerticalLayout ? "number" : "category"}
            dataKey={isVerticalLayout ? "value" : "name"}
            padding={{ right: 20 }}
            axisLine={false}
            tickLine={false}
            style={{
              fontSize: "0.62rem",
              fontFamily: "Inter",
            }}
          />
          <YAxis
            type={isVerticalLayout ? "category" : "number"}
            dataKey={isVerticalLayout ? "name" : "value"}
            axisLine={false}
            tickLine={false}
            style={{
              fontSize: "0.62rem",
              fontFamily: "Inter",
            }}
            width={isVerticalLayout ? 100 : 50}
          />
          <Bar dataKey="value" stackId="a" radius={isVerticalLayout ? [0, 5, 5, 0] : [5, 5, 0, 0]}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getCellFill(entry, index)} />
            ))}
          </Bar>
          <Tooltip cursor={false} content={<CustomToolTip />} />
        </RechartsBarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};
