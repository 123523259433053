import { DimensionListWithCodes } from "./DimensionListWithCodes";

function generateDefaultCodes(dimensionValues: string[]): Record<string, string> {
  const tuples = dimensionValues.map((value, index) => {
    return [(index + 1).toString(), value];
  });
  return Object.fromEntries(tuples);
}

export const tripPurposes: Record<string, string> = generateDefaultCodes([
  "Home to Work (H-W)",
  "Work to Home (W-H)",
  "Home to Other (H-O)",
  "Other to Home (O-H)",
  "Work to Other (W-O)",
  "Other to Work (O-W)",
  "Non-Home Based (NHB)",
]);

export const mainTripPurposes: Record<string, string> = generateDefaultCodes([
  "Home Based Work (HBW)",
  "Home Based Other (HBO)",
  "Non-Home Based (NHB)",
]);

export const dayParts: Record<string, string> = generateDefaultCodes(["00-06", "06-10", "10-15", "15-19", "19-24"]);

export const dayTypes: Record<string, string> = generateDefaultCodes(["Mon-Thu", "Fri", "Sat", "Sun"]);

export const OdCsvCommonColumnsRows = [
  {
    column: "main_purpose",
    type: "",
    description: <div> Textual attribute value for grouped trip purpose (e.g. "HBW") </div>,
  },
  {
    column: "main_purpose_code",
    type: "",
    description: (
      <div>
        Numeric attribute value for grouped trip purpose (e.g. 1 for "HBW" for "Main Purpose"){" "}
        <DimensionListWithCodes elements={mainTripPurposes} />
      </div>
    ),
  },
  {
    column: "day_part",
    type: "",
    description: <div> Textual attribute value as interval of hour of the day (e.g. "00-06") </div>,
  },
  {
    column: "day_part_code",
    type: "",
    description: (
      <div>
        Numeric attribute value (e.g. 1 for "00-06" for day part) <DimensionListWithCodes elements={dayParts} />
      </div>
    ),
  },
  {
    column: "purpose",
    type: "",
    description: <div> Textual attribute value (H-W, W-H, W-O, O-W, O-H, H-O, NHB) </div>,
  },
  {
    column: "purpose_code",
    type: "",
    description: (
      <div>
        Numeric attribute value (e.g. 7 for "NHB") <DimensionListWithCodes elements={tripPurposes} />
      </div>
    ),
  },
  {
    column: "day_type",
    type: "",
    description: <div>Textual attribute value as day of week range (e.g. "Mon-Thu") </div>,
  },
  {
    column: "day_type_code",
    type: "",
    description: (
      <div>
        Numeric attribute value (e.g. 1 for "Mon-Thu" for day type) <DimensionListWithCodes elements={dayTypes} />
      </div>
    ),
  },
];
