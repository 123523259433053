import { styled } from "@mui/material";
import { MeasureSummaryResults } from "api/analytics";
import { AnalyticsContainer, CircularProgress } from "components_new";
import { FC, MutableRefObject, memo, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { AnalyticsBreakdowns } from "features/analytics/AnalyticsBreakdowns";
import { getCurrentMeasure } from "features/filters/utils";

import { RightSidebarPanel } from "components";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";

import { MapVisualizationType } from "types";

interface RoadIntersectionsAnalyticsPanelProps {
  closeAnalyticsPanelRef: MutableRefObject<(() => void) | null>;
}

const RightSidebarLoaderWrapper = styled("div")`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getSubtitle = (summary: MeasureSummaryResults | undefined) => {
  return `Annual average daily volume ${summary ? (summary.filteredTotal ?? 0).toLocaleString("en-US") : "..."}`;
};

export const RoadIntersectionsAnalyticsPanel: FC<RoadIntersectionsAnalyticsPanelProps> = memo(
  ({ closeAnalyticsPanelRef }) => {
    const [searchParams] = useSearchParams();

    const measure = useAppSelector((state) => state.filters.measure);

    const intersectionVolumeDetails = useAppSelector((state) => state.roadIntersections.intersectionVolumeDetails);
    const selectedIntersectionId = useAppSelector((state) => state.roadIntersections.selectedIntersectionId);
    const intersectionVolumesState = useAppSelector((state) => state.roadIntersections.intersectionVolumes.state);
    const roadMeasures = useAppSelector((state) => state.analytics.roadsMetadata.data?.measures);

    const currentRoadMeasure = useMemo(() => getCurrentMeasure(roadMeasures, measure), [roadMeasures, measure]);
    const roadsMetadataDimensions = useMemo(() => currentRoadMeasure?.dimensions || [], [currentRoadMeasure]);

    const mode = useMemo(() => searchParams.get("mode") as MapVisualizationType, [searchParams]);
    const isLoading = useMemo(() => {
      return intersectionVolumesState === DataState.LOADING || intersectionVolumeDetails.state === DataState.LOADING;
    }, [intersectionVolumesState, intersectionVolumeDetails.state]);

    const isShowRightSidebar = useMemo(
      () =>
        Boolean(
          selectedIntersectionId &&
            intersectionVolumeDetails.state === DataState.AVAILABLE &&
            intersectionVolumeDetails?.data &&
            mode === MapVisualizationType.ROADS,
        ),
      [mode, selectedIntersectionId, intersectionVolumeDetails],
    );

    const isDetailsLoading = useMemo(
      () => Boolean(selectedIntersectionId && isLoading),
      [selectedIntersectionId, isLoading],
    );

    const handleClosePanel = () => {
      if (typeof closeAnalyticsPanelRef.current === "function") {
        closeAnalyticsPanelRef.current();
      }
    };

    const breakdowns = useMemo(
      () =>
        intersectionVolumeDetails.data && selectedIntersectionId
          ? intersectionVolumeDetails.data?.summary.breakdowns
          : [],
      [intersectionVolumeDetails.data, selectedIntersectionId],
    );

    return (
      <RightSidebarPanel
        title={isDetailsLoading ? "Loading..." : "Intersection Details"}
        subtitle={getSubtitle(intersectionVolumeDetails.data?.summary)}
        onClose={handleClosePanel}
        isOpen={isShowRightSidebar || isDetailsLoading}
      >
        {!isDetailsLoading && selectedIntersectionId && roadsMetadataDimensions && intersectionVolumeDetails.data ? (
          <AnalyticsContainer heightOperand={102}>
            <AnalyticsBreakdowns
              breakdowns={breakdowns}
              dimensions={roadsMetadataDimensions}
              containersIdPrefix="road_intersections"
            />
          </AnalyticsContainer>
        ) : null}
        {isDetailsLoading && (
          <RightSidebarLoaderWrapper>
            <CircularProgress />
          </RightSidebarLoaderWrapper>
        )}
      </RightSidebarPanel>
    );
  },
);
