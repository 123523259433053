import { AnySourceData } from "mapbox-gl";

import { MeasureType, RoadIntersectionLevel, RoadsMetadataResponse } from "types";

export const ROAD_INTERSECTIONS_SOURCE_ID = "ROAD_INTERSECTIONS_SOURCE_ID";

export const getRoadIntersectionSourceNameForLevel = (level: RoadIntersectionLevel) => {
  return level.maxRadius ? `${ROAD_INTERSECTIONS_SOURCE_ID}_${level.maxRadius}` : ROAD_INTERSECTIONS_SOURCE_ID;
};

export const getRoadIntersectionsSources = (roadsMetadata: RoadsMetadataResponse, supportingMeasure: MeasureType) => {
  if (!roadsMetadata?.intersectionLevels) return [];

  const baseLevel = roadsMetadata.intersectionLevels?.[supportingMeasure]?.baseLevel;
  if (!baseLevel) return [];

  const tilesetUrl = baseLevel.tileService.url;

  const sources = [
    {
      id: ROAD_INTERSECTIONS_SOURCE_ID,
      source: {
        type: "vector",
        tiles: [tilesetUrl + "/{z}/{x}/{y}.pbf"],
        promoteId: baseLevel.tileService.idField,
        maxzoom: baseLevel.minZoomLevel, // not an error, let Mapbox-GL scale tiles beyond this zoom level
      } as AnySourceData,
    },
  ];

  return sources;
};

export const getRoadIntersectionClusterSources = (
  roadsMetadata: RoadsMetadataResponse,
  supportingMeasure: MeasureType,
) => {
  if (!roadsMetadata?.intersectionLevels) return [];

  const clusterLevels = roadsMetadata.intersectionLevels?.[supportingMeasure]?.clusterLevels;
  if (!clusterLevels) return [];

  const sources = clusterLevels.map((level) => {
    const tilesetUrl = level.tileService.url;

    return {
      id: `${ROAD_INTERSECTIONS_SOURCE_ID}_${level.maxRadius}`,
      source: {
        type: "vector",
        tiles: [tilesetUrl + "/{z}/{x}/{y}.pbf"],
        promoteId: level.tileService.idField,
        minzoom: level.minZoomLevel,
        maxzoom: level.maxZoomLevel,
      } as AnySourceData,
    };
  });

  return sources;
};
