import { GeoJSONSource } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useMemo } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";

import { getGatesLayers } from "./map-data/layers";
import { getGatesSources } from "./map-data/sources";

interface Props {
  map: MutableRefObject<mapboxgl.Map | null>;
  isModeLoaded: boolean;
  setGatesModuleData: Dispatch<SetStateAction<ModuleData | null>>;
}

export const MapController = ({ map, isModeLoaded, setGatesModuleData }: Props) => {
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const gates = useAppSelector((state) => state.analytics.datasetGates);
  const gatesCounts = useAppSelector((state) => state.analytics.datasetCounts.data?.gates);
  const gatesCountsByZone = useAppSelector((state) => state.analytics.datasetCountsByZoneId.data?.counts.gates);
  const selectedZone = useAppSelector((state) => state.analytics.selectedZone);

  const counts = useMemo(
    () => (selectedZone ? gatesCountsByZone : gatesCounts),
    [gatesCounts, gatesCountsByZone, selectedZone],
  );

  useEffect(() => {
    if (!isModeLoaded) return;

    const source = map.current?.getSource("GATES_SOURCE_ID") as GeoJSONSource;

    if (source && gates.state === DataState.AVAILABLE && counts) {
      const featuresCollection: GeoJSON.FeatureCollection = {
        type: "FeatureCollection",
        features: gates.data.map((gate) => ({
          type: "Feature",
          properties: {
            identifier: gate.identifier,
            count: counts?.get(gate.identifier) || 0,
          },
          geometry: {
            type: "Point",
            coordinates: [gate.lon, gate.lat],
          },
        })),
      };

      source.setData(featuresCollection);
    }
  }, [map, gates, counts, selectedZone, isModeLoaded]);

  // Set module data
  useEffect(() => {
    if (selectedFocusArea && gates.state === DataState.AVAILABLE) {
      const commonSources = getGatesSources(gates.data);
      const commonLayers = getGatesLayers();

      setGatesModuleData({
        sources: commonSources,
        layers: commonLayers,
        data: { gates },
      });
    }
  }, [selectedFocusArea, gates, setGatesModuleData]);

  return null;
};
