import { DirectionsWalk, DriveEta } from "@mui/icons-material";
import { SxProps } from "@mui/material";
import { FC } from "react";

import { RoadNetworkType } from "types";

export interface NetworkTypeIconProps {
  networkType: RoadNetworkType | undefined;
  sx?: SxProps;
}

export const NetworkTypeIcon: FC<NetworkTypeIconProps> = ({ networkType, sx }) => {
  return networkType === RoadNetworkType.Pedestrian ? (
    <DirectionsWalk fontSize="inherit" color="secondary" sx={sx} />
  ) : (
    <DriveEta fontSize="inherit" color="secondary" sx={sx} />
  );
};
