import { useEffect } from "react";
import { unstable_useBlocker as useBlocker } from "react-router-dom";

// @TODO in version above 6.16.0 of react-router-dom the unstable_useBlocker object is removed

export function usePrompt(message: string, when = true) {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname && when,
  );

  useEffect(() => {
    if (when) window.onbeforeunload = (event) => message;

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (window.confirm(message)) blocker.proceed();
      else blocker.reset();
    }
  }, [blocker, message]);
}
