import { useAuth0 } from "@auth0/auth0-react";
import { Chip, ChipProps, MenuItem, Stack, Typography, styled } from "@mui/material";
import { Button, Dialog, DialogProps, Divider, Switch, TextField } from "components_new";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";

import { buildFilters } from "features/filters/utils";

import { RoadClassSelector } from "components";

import { buildSegmentsPredicate, buildZonesPredicate } from "components/pages/analytics/select-link/utils";

import { useAppDispatch, useAppSelector } from "hooks";

import { selectRoadNetworkType } from "store/sections/analytics";
import { exportActions } from "store/sections/export";
import { selectVisibleScreenlines } from "store/sections/screenlines";

import {
  AoiExportRequest,
  FocusAreaItem,
  MapVisualizationMode,
  MapVisualizationType,
  MeasureType,
  ODDatasetExportRequest,
  RoadClass,
  SelectLinkAoiExportConfig,
} from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

import { FilterList, List, ListItem, getActiveFilters, getSelectLinkFilters } from "./";

export interface NewExportDialogProps extends DialogProps {
  mode: MapVisualizationMode | null;
  ODZoomLevel: string | undefined;
  measure: MeasureType;
  selectedArea: FocusAreaItem | null;
  isSelectLink: boolean;
  onClose: () => void;
}

const Label = styled("p")`
  margin-bottom: 0.25rem;
  font-size: 12px;
  font-weight: 600;
`;

const OptionsContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: theme.spacing(2),
  rowGap: theme.spacing(1),
  alignItems: "bottom",
}));

// reduce bottom padding due to the contained list having a bottom margin of 0.5rem
const ExportCard = styled("div")`
  height: calc(100% - 24px);
  min-height: 55px;
  max-height: 180px;
  overflow-y: auto;
  padding: 1rem 1rem 0.5rem 1rem;
  border: 1px solid var(--color-gray-100);
  border-radius: 8px;
  background: var(--color-text-field-gray);
`;

const ExportContentCard = styled(ExportCard)<{ height: number }>`
  height: ${(props) => props.height}px;
`;

const ModuleHeader = styled(Divider)(({ theme }) => ({
  fontWeight: 600,
}));

const MeasureChip = styled((props: ChipProps) => <Chip size="small" variant="outlined" {...props} />)(({ theme }) => ({
  fontSize: "12px",
}));

const getMeasureLabel = (measure: MeasureType) => {
  switch (measure) {
    case MeasureType.AADT:
      return "Vehicles";
    case MeasureType.PEDESTRIANS:
      return "Pedestrians";
    default:
      return "Vehicles";
  }
};

export const NewExportDialog: FC<NewExportDialogProps> = ({
  mode,
  ODZoomLevel,
  measure,
  selectedArea,
  isSelectLink,
  onClose,
  ...props
}) => {
  const { user } = useAuth0();
  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const dispatch = useAppDispatch();

  const numZones = useAppSelector((state) => state.export.numZones);
  const currentODFilters = useAppSelector((state) => state.filters.ODFilters);
  const currentRoadFilters = useAppSelector((state) => state.filters.roadFilters);
  const currentDatasetFilters = useAppSelector((state) => state.filters.datasetFilters);
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);
  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const timePeriod = useAppSelector((state) =>
    isSelectLink ? state.selectLink.savedSelectLinkConfig.data?.timePeriod : state.global.timePeriod,
  );
  const visibleScreenlines = useAppSelector(selectVisibleScreenlines);
  const networkType = useAppSelector(selectRoadNetworkType);
  const savedRoadClasses = useAppSelector((state) => state.filters.roadClasses);

  const [exportDescription, setExportDescription] = useState("");
  const [exportZoneLevel, setExportZoneLevel] = useState(ODZoomLevel);
  const [selectedRoadClasses, setSelectedRoadClasses] = useState<RoadClass[] | null>(null);

  // exportable screenlines: only those that are visible and are defined for the network type of the selected road measure
  const exportableScreenlines = useMemo(
    () => visibleScreenlines.filter((screenline) => screenline.network === networkType),
    [visibleScreenlines, networkType],
  );

  const [exportOptions, setExportOptions] = useState({
    shouldExportVolumeBreakdowns: true,
    shouldExportScreenlines: exportableScreenlines.length > 0,
  });

  // should export the results of the last run analysis, not of the saved configuration
  const resultsSelectLinkConfig = useAppSelector((state) => state.selectLink.resultsSelectLinkConfig);
  // saved config needed to pass some additional params for metadata
  const savedSelectLinkConfig = useAppSelector((state) => state.selectLink.savedSelectLinkConfig);

  const selectLinkExportConfig: SelectLinkAoiExportConfig | null = useMemo(() => {
    const metadataOptions =
      savedSelectLinkConfig && savedSelectLinkConfig.data
        ? {
            configId: savedSelectLinkConfig.data?.configId,
            analysisName: savedSelectLinkConfig.data?.analysisName,
            analysisDescription: savedSelectLinkConfig.data?.description,
          }
        : null;
    return resultsSelectLinkConfig
      ? {
          ...metadataOptions,
          selectedSegmentsPredicate: buildSegmentsPredicate(
            resultsSelectLinkConfig.segmentsGroups,
            resultsSelectLinkConfig.segmentsGroupsOp,
          ),
          selectedOriginsPredicate: buildZonesPredicate(resultsSelectLinkConfig.origins),
          selectedDestinationsPredicate: buildZonesPredicate(resultsSelectLinkConfig.destinations),
          countsFilter: {
            min: resultsSelectLinkConfig.minCount,
            max: 1e9,
          },
        }
      : null;
  }, [resultsSelectLinkConfig, savedSelectLinkConfig]);

  const selectedFocusArea = useMemo(
    () => focusAreas.data?.find((area) => area.id === selectedFocusAreaId) || null,
    [focusAreas.data, selectedFocusAreaId],
  );

  // Determine height of the export content card. The calculated height allows for all selectable options without excessive
  // whitespace at the end, while making sure that no scrollbar is shown.
  // Note that the list length changes based on options (screenlines exported or not), so the height cannot be automatic,
  // otherwise the dialog would resize when the user toggles the screenlines option.
  const exportContentHeight = (exportableScreenlines.length > 0 ? 30 : 0) + (selectedFocusArea?.datasetId ? 140 : 110);

  const datasetZoningLevels = useMemo(() => {
    if (datasetMetadata.data?.zoningLevels) {
      return selectedFocusArea?.zoningLevel === "Custom" && ODMetadata.data?.zoningLevels?.[0]
        ? [...datasetMetadata.data.zoningLevels, ODMetadata.data?.zoningLevels?.[0]]
        : datasetMetadata.data.zoningLevels;
    }
  }, [ODMetadata.data?.zoningLevels, datasetMetadata.data?.zoningLevels, selectedFocusArea?.zoningLevel]);

  const zoningLevels = useMemo(
    () => (selectedFocusArea?.datasetId ? datasetZoningLevels : ODMetadata.data?.zoningLevels),
    [selectedFocusArea?.datasetId, datasetZoningLevels, ODMetadata.data?.zoningLevels],
  );

  const isExportPermitted =
    mode === MapVisualizationType.OD
      ? selectedArea?.datasetId
        ? datasetMetadata.data?.exportPermissions.allowExport
        : ODMetadata.data?.exportPermissions?.allowExport
      : roadsMetadata.data?.exportPermissions?.allowExport;

  const isODDisabled = useMemo(() => ODMetadata.error?.status === 403, [ODMetadata.error?.status]);

  const isDatasetDisabled = useMemo(() => datasetMetadata.error?.status === 403, [datasetMetadata.error?.status]);

  const isRoadsDisabled = useMemo(() => roadsMetadata.error?.status === 403, [roadsMetadata.error?.status]);

  const currentRoadMeasure = roadsMetadata.data?.measures.find((m) => m.columnName === measure);

  const getZoneLevelDisplayName = (zoneLevelId: string = exportZoneLevel as string) => {
    return (zoningLevels || []).find(({ id }) => id === zoneLevelId)?.longNameSingular;
  };

  const onNewExport = () => {
    if (selectedArea && focusAreas.data && timePeriod) {
      const aoiExportRequest: AoiExportRequest = {
        exportDescription,
        areaOfInterestName: selectedArea.region,
        timePeriod,
        areaOfInterest: selectedArea?.areas || [],
        shouldGenerateSeqIds: true,
        ...(isODDisabled || isSelectLink
          ? {}
          : {
              od: {
                measures: {
                  aadt: {
                    filter: buildFilters(currentODFilters),
                  },
                },
                level: exportZoneLevel,
                shouldExportZones: true,
              },
            }),
        ...(isRoadsDisabled
          ? {}
          : {
              road: {
                measures: {
                  [measure]: {
                    filter: buildFilters(currentRoadFilters),
                  },
                },
                shouldExportSegments: true,
                shouldExportVolumeBreakdowns: exportOptions.shouldExportVolumeBreakdowns,
                shouldExportVolumes: !isSelectLink,
                roadClassesToExport: isSelectLink ? null : selectedRoadClasses?.map((roadClass) => roadClass.id) || [],
                screenlinesToExport: exportOptions.shouldExportScreenlines ? exportableScreenlines : null,
              },
            }),
        ...(isSelectLink && selectLinkExportConfig
          ? {
              selectLink: selectLinkExportConfig,
            }
          : {}),
      };

      const datasetExportRequest: ODDatasetExportRequest = {
        exportDescription,
        areaOfInterestName: selectedArea.region,
        measure: "aadt",
        level: exportZoneLevel,
        ...(isDatasetDisabled ? {} : { filter: buildFilters(currentDatasetFilters) }),
        ...(isRoadsDisabled ? {} : { roadFilter: buildFilters(currentRoadFilters) }),
        areaOfInterest: selectedArea?.areas || null,
        compression: "gzip",
        shouldExportZones: true,
        shouldGenerateSeqIds: true,
        shouldExportVolumeBreakdowns: exportOptions.shouldExportVolumeBreakdowns,
        shouldExportOdBreakdowns: true,
        roadClassesToExport: selectedRoadClasses?.map((roadClass) => roadClass.id) || [],
        roadMeasure: measure,
        screenlinesToExport: exportOptions.shouldExportScreenlines ? exportableScreenlines : null,
      };

      if (selectedArea.datasetId) {
        addCustomGAEvent("new_export", "start_new_export", "dataset_export", user, userOrganizationName);
        dispatch(exportActions.addDatasetExportJob(selectedArea.datasetId, datasetExportRequest));
      } else {
        addCustomGAEvent("new_export", "start_new_export", "aoi_export", user, userOrganizationName);
        dispatch(exportActions.addAOIExportJob(aoiExportRequest));
      }

      onClose();
    }
  };

  const handleChangeOptions = (event: ChangeEvent<HTMLInputElement>) => {
    setExportOptions({
      ...exportOptions,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    if (timePeriod && !isODDisabled) {
      dispatch(
        exportActions.fetchNumZones({
          timePeriod,
          areaOfInterest: selectedArea?.areas || null,
          datasetId: selectedArea?.datasetId,
        }),
      );
    }
  }, [selectedArea?.areas, selectedArea?.datasetId, isODDisabled, timePeriod, dispatch]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      title="New export"
      onClose={undefined}
      actions={
        <Stack direction={"row"} spacing={1}>
          <Button size="medium" color="secondary" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button size="medium" color="secondary" disabled={!isExportPermitted} onClick={onNewExport}>
            Start export
          </Button>
        </Stack>
      }
      {...props}
    >
      <Stack spacing={2}>
        {/* fix card height so that all currently supported elements can fit without changing dialog size o scrolling: */}
        <ExportContentCard height={exportContentHeight}>
          <Label>Export will include:</Label>
          <List>
            {!isSelectLink && (
              <>
                <ListItem>OD data (csv)</ListItem>
                <ListItem>OD zones (shapefile)</ListItem>
              </>
            )}
            {!isSelectLink && <ListItem>Road volumes (csv)</ListItem>}
            {!isSelectLink && (
              <ListItem>
                {exportOptions.shouldExportVolumeBreakdowns
                  ? "Road network with detailed volumes by category (shapefile)"
                  : "Road network with total volumes (shapefile)"}
              </ListItem>
            )}
            {isSelectLink && <ListItem>Select link analysis road network with volumes (shapefile)</ListItem>}
            {exportOptions.shouldExportScreenlines && (
              <>
                <ListItem>Screenlines (shapefile)</ListItem>
                <ListItem>Screenline segments (csv)</ListItem>
              </>
            )}
            {selectedArea?.datasetId && (
              <>
                <ListItem>Subarea (shapefile)</ListItem>
                <ListItem>Gates (csv)</ListItem>
              </>
            )}
            {isSelectLink && (
              <>
                <ListItem>Select link analysis road volumes (csv)</ListItem>
                <ListItem>Select link analysis OD data (csv)</ListItem>
                <ListItem>OD zones (shapefile)</ListItem>
              </>
            )}
          </List>
        </ExportContentCard>

        <TextField
          fullWidth
          multiline
          rows={2}
          id="exportDescription"
          label="Export Description"
          value={exportDescription}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setExportDescription(e.target.value)}
        />

        <OptionsContainer>
          <ModuleHeader>{isSelectLink ? "Select Link" : "OD"}</ModuleHeader>
          <ModuleHeader>Volumes</ModuleHeader>

          {!isSelectLink && (
            <>
              <MeasureChip label={getMeasureLabel("aadt" as MeasureType)} />
              <MeasureChip label={getMeasureLabel(currentRoadMeasure?.columnName as MeasureType)} />

              <TextField
                fullWidth
                select
                label="Zoning level"
                disabled={isODDisabled || isDatasetDisabled}
                value={exportZoneLevel}
                sx={{ marginTop: 1 }}
              >
                {(numZones.data || []).map((item) => (
                  <MenuItem
                    key={item.num}
                    value={item.level}
                    onClick={() => setExportZoneLevel(item.level)}
                  >{`${getZoneLevelDisplayName(item.level)} (${item.num})`}</MenuItem>
                ))}
              </TextField>

              <RoadClassSelector
                selectedRoadClasses={selectedRoadClasses}
                savedRoadClasses={savedRoadClasses}
                setSelectedRoadClasses={setSelectedRoadClasses}
              />

              <div />

              <Stack>
                {/* arrange the options vertically */}
                <Label>Options</Label>

                <Switch
                  size="small"
                  color="secondary"
                  name="shouldExportVolumeBreakdowns"
                  checked={exportOptions.shouldExportVolumeBreakdowns}
                  onChange={handleChangeOptions}
                  label={
                    <Typography fontSize={12} maxWidth={220} ml={1}>
                      Road network with detailed volumes by category in shapefile
                    </Typography>
                  }
                />

                {exportableScreenlines.length > 0 && (
                  <Switch
                    size="small"
                    color="secondary"
                    name="shouldExportScreenlines"
                    checked={exportOptions.shouldExportScreenlines}
                    onChange={handleChangeOptions}
                    label={
                      <Typography fontSize={12} maxWidth={220} ml={1}>
                        Screenlines and screenline segments
                      </Typography>
                    }
                  />
                )}
              </Stack>
            </>
          )}

          {isSelectLink && selectLinkExportConfig ? (
            <div>
              <Label>Filters</Label>
              <ExportCard>
                <FilterList filters={getSelectLinkFilters(selectLinkExportConfig)} />
              </ExportCard>
            </div>
          ) : (
            <div>
              <Label>Filters</Label>
              <ExportCard>
                <FilterList
                  filters={
                    selectedArea?.datasetId
                      ? getActiveFilters(currentDatasetFilters)
                      : getActiveFilters(currentODFilters)
                  }
                />
              </ExportCard>
            </div>
          )}

          <div>
            <Label>Filters</Label>
            <ExportCard>
              <FilterList filters={getActiveFilters(currentRoadFilters)} />
            </ExportCard>
          </div>
        </OptionsContainer>
      </Stack>
    </Dialog>
  );
};
