import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { Dispatch, MutableRefObject, SetStateAction, useEffect } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { MeasureType } from "types";

import { MapController } from "./MapController";
import { RoadIntersectionsAnalyticsPanel } from "./RoadIntersectionsAnalyticsPanel";

interface RoadIntersectionsModuleComponentProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  measure: MeasureType;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  setRoadIntersectionsModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  updateVolumesRef: MutableRefObject<(() => void) | null>;
  closeAnalyticsPanelRef: MutableRefObject<(() => void) | null>;
}

export const RoadIntersectionsModuleComponent = ({
  map,
  measure,
  layerManagerRef,
  setRoadIntersectionsModuleData,
  updateVolumesRef,
  closeAnalyticsPanelRef,
}: RoadIntersectionsModuleComponentProps) => {
  useEffect(() => {
    const closeAnalyticsPanel = closeAnalyticsPanelRef.current;

    return () => {
      if (closeAnalyticsPanel) {
        closeAnalyticsPanel();
      }

      setRoadIntersectionsModuleData(null);
    };
  }, [setRoadIntersectionsModuleData, closeAnalyticsPanelRef]);

  return (
    <>
      <MapController
        map={map}
        measure={measure}
        layerManagerRef={layerManagerRef}
        setRoadIntersectionsModuleData={setRoadIntersectionsModuleData}
        updateVolumesRef={updateVolumesRef}
        closeAnalyticsPanelRef={closeAnalyticsPanelRef}
      />

      <RoadIntersectionsAnalyticsPanel closeAnalyticsPanelRef={closeAnalyticsPanelRef} />
    </>
  );
};
