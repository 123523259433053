import { Layer } from "mapbox-gl";
import { MutableRefObject } from "react";

import theme from "theme";

import { RoadNetworkType } from "types";

import { calculateWidthFactor } from "../../../utils";
import { SCREENLINES_INTERSECTIONS_SOURCE_ID, SCREENLINES_SEGMENTS_SOURCE_ID, SCREENLINES_SOURCE_ID } from "./sources";

export const SCREENLINES_LAYER_ID = "SCREENLINES_LAYER_ID";
export const SCREENLINES_VOLUMES_LAYER_ID = "SCREENLINES_VOLUMES_LAYER_ID";
export const SCREENLINES_SEGMENTS_LAYER_ID = "SCREENLINES_SEGMENTS_LAYER_ID";
export const SCREENLINES_INTERSECTIONS_LAYER_ID = "SCREENLINES_INTERSECTIONS_LAYER_ID";
const SCREENLINE_WIDTH_MULTIPLIER = 300;

export const getScreenlinesRightVolumesLayerOffsetExpression = (widthFactor: number, maxVolume: number) => [
  "let",
  "rightVolume",
  [
    "number",
    [
      "/",
      [
        "*",
        [
          "*",
          [
            "/",
            [
              "case",
              ["==", ["get", "network"], RoadNetworkType.Pedestrian],
              ["/", ["+", ["get", "toRight"], ["get", "toLeft"]], 2],
              ["get", "toRight"],
            ],
            maxVolume,
          ],
          SCREENLINE_WIDTH_MULTIPLIER,
        ],
        calculateWidthFactor(widthFactor),
      ],
      2,
    ],
    0,
  ],
  [
    "interpolate",
    ["exponential", 2],
    ["zoom"],
    0,
    ["*", ["var", "rightVolume"], ["^", 2, -16]],
    24,
    ["*", ["var", "rightVolume"], ["^", 2, 8]],
  ],
];

export const getScreenlinesRightVolumesLayerWidthExpression = (widthFactor: number, maxVolume: number) => [
  "let",
  "rightVolume",
  [
    "number",
    [
      "*",
      [
        "*",
        [
          "/",
          [
            "case",
            ["==", ["get", "network"], RoadNetworkType.Pedestrian],
            ["/", ["+", ["get", "toRight"], ["get", "toLeft"]], 2],
            ["get", "toRight"],
          ],
          maxVolume,
        ],
        SCREENLINE_WIDTH_MULTIPLIER,
      ],
      calculateWidthFactor(widthFactor),
    ],
    0,
  ],
  [
    "interpolate",
    ["exponential", 2],
    ["zoom"],
    0,
    ["*", ["var", "rightVolume"], ["^", 2, -16]],
    24,
    ["*", ["var", "rightVolume"], ["^", 2, 8]],
  ],
];

export const getScreenlinesLeftVolumesLayerOffsetExpression = (widthFactor: number, maxVolume: number) => [
  "let",
  "leftVolume",
  [
    "number",
    [
      "-",
      [
        "/",
        [
          "*",
          [
            "*",
            [
              "/",
              [
                "case",
                ["==", ["get", "network"], RoadNetworkType.Pedestrian],
                ["/", ["+", ["get", "toRight"], ["get", "toLeft"]], 2],
                ["get", "toLeft"],
              ],
              maxVolume,
            ],
            SCREENLINE_WIDTH_MULTIPLIER,
          ],
          calculateWidthFactor(widthFactor),
        ],
        2,
      ],
    ],
    0,
  ],
  [
    "interpolate",
    ["exponential", 2],
    ["zoom"],
    0,
    ["*", ["var", "leftVolume"], ["^", 2, -16]],
    24,
    ["*", ["var", "leftVolume"], ["^", 2, 8]],
  ],
];

export const getScreenlinesLeftVolumesLayerWidthExpression = (widthFactor: number, maxVolume: number) => [
  "let",
  "leftVolume",
  [
    "number",
    [
      "*",
      [
        "*",
        [
          "/",
          [
            "case",
            ["==", ["get", "network"], RoadNetworkType.Pedestrian],
            ["/", ["+", ["get", "toRight"], ["get", "toLeft"]], 2],
            ["get", "toLeft"],
          ],
          maxVolume,
        ],
        SCREENLINE_WIDTH_MULTIPLIER,
      ],
      calculateWidthFactor(widthFactor),
    ],
    0,
  ],
  [
    "interpolate",
    ["exponential", 2],
    ["zoom"],
    0,
    ["*", ["var", "leftVolume"], ["^", 2, -16]],
    24,
    ["*", ["var", "leftVolume"], ["^", 2, 8]],
  ],
];

export const getSegmentsLayerLineColorExpression = (roadClasses: number[] | null) => [
  "case",
  ["boolean", ["get", "candidate"], false],
  `${theme.palette.screenline.candidate}`,
  ["boolean", ["get", "deprecated"], false],
  `${theme.palette.grey[500]}`,
  ["boolean", ["!", ["in", ["get", "roadClass"], ["literal", roadClasses || []]]], false],
  `${theme.palette.grey[500]}`,
  `${theme.palette.screenline.line}`,
];

export const getIntersectionsLayerCircleColorExpression = (roadClasses: number[] | null) => [
  "case",
  ["boolean", ["!", ["in", ["get", "roadClass"], ["literal", roadClasses || []]]], false],
  `${theme.palette.grey[600]}`,
  ["boolean", ["!", ["get", "resolved"]], false],
  `${theme.palette.screenline.unresolved}`,
  `${theme.palette.screenline.circle}`,
];

export const getScreenlinesVolumesOpacityExpression = (opacityFactor: number) => [
  "*",
  [
    "case",
    ["boolean", ["feature-state", "network-mismatch"], false],
    0,
    ["boolean", ["feature-state", "edit"], false],
    0,
    ["boolean", ["feature-state", "hover"], false],
    0.8,
    ["boolean", ["feature-state", "click"], false],
    0.7,
    0.35,
  ],
  opacityFactor,
];

export const getScreenlinesLayers = (
  showScreenlinesRef: MutableRefObject<boolean | null>,
  roadClassesRef: MutableRefObject<number[] | null>,
  opacityFactor: number,
  widthFactor: number,
) =>
  [
    {
      id: `${SCREENLINES_VOLUMES_LAYER_ID}_right`,
      type: "line",
      source: SCREENLINES_SOURCE_ID,

      paint: {
        "line-color": `${theme.palette.screenline.volume}`,
        "line-offset": getScreenlinesRightVolumesLayerOffsetExpression(widthFactor, 1),
        "line-width": getScreenlinesRightVolumesLayerWidthExpression(widthFactor, 1),
        "line-opacity": getScreenlinesVolumesOpacityExpression(opacityFactor),
      },
      layout: {
        visibility: showScreenlinesRef.current ? "visible" : "none",
      },
    },
    {
      id: `${SCREENLINES_VOLUMES_LAYER_ID}_left`,
      type: "line",
      source: SCREENLINES_SOURCE_ID,

      paint: {
        "line-color": `${theme.palette.screenline.volume}`,
        "line-offset": getScreenlinesLeftVolumesLayerOffsetExpression(widthFactor, 1),
        "line-width": getScreenlinesLeftVolumesLayerWidthExpression(widthFactor, 1),
        "line-opacity": getScreenlinesVolumesOpacityExpression(opacityFactor),
      },
      layout: {
        visibility: showScreenlinesRef.current ? "visible" : "none",
      },
    },
    {
      id: SCREENLINES_LAYER_ID,
      type: "line",
      source: SCREENLINES_SOURCE_ID,
      layout: {
        visibility: showScreenlinesRef.current ? "visible" : "none",
        "line-cap": "round",
      },
      paint: {
        "line-color": [
          "case",
          ["boolean", ["feature-state", "network-mismatch"], false],
          `${theme.palette.grey[500]}`,
          ["boolean", ["feature-state", "editGeometry"], false],
          `${theme.palette.grey[500]}`,
          `${theme.palette.screenline.line}`,
        ],
        "line-width": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          4,
          ["boolean", ["feature-state", "click"], false],
          1.5,
          ["boolean", ["feature-state", "edit"], false],
          1.5,
          1.5,
        ],
        "line-opacity": 1,
      },
    },
    {
      id: SCREENLINES_SEGMENTS_LAYER_ID,
      type: "line",
      source: SCREENLINES_SEGMENTS_SOURCE_ID,
      paint: {
        "line-color": getSegmentsLayerLineColorExpression(roadClassesRef.current),
        "line-offset": [
          "interpolate",
          ["exponential", 1.6],
          ["zoom"],
          6,
          ["case", ["boolean", ["get", "isNonDirectional"], false], 0, 0.25],
          20,
          ["case", ["boolean", ["get", "isNonDirectional"], false], 0, 20],
        ],
        "line-width": [
          "interpolate",
          ["exponential", 1.6],
          ["zoom"],
          6,
          ["case", ["boolean", ["feature-state", "hover"], false], 2, 0.5],
          20,
          ["case", ["boolean", ["feature-state", "hover"], false], 35, 30],
        ],
        "line-opacity": 1,
      },
      layout: {
        visibility: showScreenlinesRef.current ? "visible" : "none",
        "line-cap": "round",
      },
    },

    {
      id: SCREENLINES_INTERSECTIONS_LAYER_ID,
      type: "circle",
      source: SCREENLINES_INTERSECTIONS_SOURCE_ID,
      paint: {
        "circle-color": getIntersectionsLayerCircleColorExpression(roadClassesRef.current),
        "circle-stroke-width": ["case", ["boolean", ["feature-state", "click"], false], 3, 1.5],
        "circle-stroke-color": ["case", ["boolean", ["feature-state", "click"], false], "white", "transparent"],
        "circle-opacity": 1,
        "circle-radius": [
          "interpolate",
          ["exponential", 1.6],
          ["zoom"],
          6,
          ["case", ["boolean", ["feature-state", "hover"], false], 4, 2],
          20,
          ["case", ["boolean", ["feature-state", "hover"], false], 25, 20],
        ],
      },
      layout: {
        visibility: showScreenlinesRef.current ? "visible" : "none",
      },
    },
  ] as Layer[];
