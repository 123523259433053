import { Box, Typography, styled } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector, useFetch } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions, selectFocusAreas } from "store/sections/analytics";

import { RoadsMetadataResponse } from "types";

import { AreaAccuracyScatterPlot } from "./AreaAccuracyScatterPlot";
import { AreaAccuracyTable } from "./AreaAccuracyTable";
import { DataQualityMetricsControls } from "./DataQualityMetricsControls";
import { roadClassGroups } from "./roadClassGroups";

export const DataQualityMetricsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1200px",
  marginBottom: theme.spacing(2),
}));

export const ControlsScatterContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "minmax(auto, 480px) 1fr",
  gap: theme.spacing(2),

  marginBottom: theme.spacing(2),
}));

export const DataQualityMetrics: FC = () => {
  const dispatch = useAppDispatch();

  const globalTimePeriod = useAppSelector((state) => state.global.timePeriod);
  const areas = useAppSelector(selectFocusAreas);
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const accuracyTableData = useAppSelector((state) => state.analytics.areaAccuracyTableData);
  const accuracyScatterPlotData = useAppSelector((state) => state.analytics.areaAccuracyScatterPlotData);

  const [selectedAreaId, setSelectedAreaId] = useState("");
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(globalTimePeriod || "");
  const [selectedRoadClasses, setSelectedRoadClasses] = useState<number[] | null>(null);

  const [metadataRoadClassesIds, roadMetadataLoading, roadMetadataError, setRoadMetadata] = useFetch<number[]>(
    "/analytics/roads/metadata",
    {
      method: "POST",
      body: JSON.stringify({ timePeriod: selectedTimePeriod }),
      headers: {
        "licensed-area-id": selectedAreaId,
      },
    },
    Boolean(selectedTimePeriod && selectedAreaId),
    (roadMetadata: RoadsMetadataResponse) => roadMetadata?.roadClasses?.map((c) => c.id) || [],
    (roadClassesIds) => setSelectedRoadClasses(roadClassesIds),
  );

  const loading = useMemo(
    () =>
      accuracyTableData.state === DataState.LOADING ||
      accuracyScatterPlotData.state === DataState.LOADING ||
      roadMetadataLoading,
    [accuracyTableData.state, accuracyScatterPlotData.state, roadMetadataLoading],
  );

  const error = useMemo(
    () =>
      accuracyTableData.state === DataState.ERROR ||
      accuracyScatterPlotData.state === DataState.ERROR ||
      Boolean(roadMetadataError),
    [accuracyTableData.state, accuracyScatterPlotData.state, roadMetadataError],
  );

  const availableTimePeriods = useMemo(
    () => areas?.find((area) => area.id === selectedAreaId)?.timePeriods || [],
    [areas, selectedAreaId],
  );

  const handleChangeSelectedAreaId = (areaId: string) => {
    const newAreaTimePeriods = areas?.find((area) => area.licensedAreaId === areaId)?.timePeriods || [];
    const isTimePeriodAvailableInNewArea = newAreaTimePeriods.includes(selectedTimePeriod);
    if (newAreaTimePeriods.length && !isTimePeriodAvailableInNewArea) {
      setSelectedTimePeriod(newAreaTimePeriods[0]);
    }
    setRoadMetadata(null);
    setSelectedAreaId(areaId);
  };

  const handleChangeSelectedTimePeriod = (timePeriod: string) => {
    setRoadMetadata(null);
    setSelectedTimePeriod(timePeriod);
  };

  const handleRoadClassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const roadClassGroupKey = event.target.name;
    const roadClassGroupIds = roadClassGroups[roadClassGroupKey].classes
      .map((c) => c.id)
      .filter((c) => metadataRoadClassesIds?.includes(c));

    if (event.target.checked) {
      setSelectedRoadClasses([...(selectedRoadClasses || []), ...roadClassGroupIds]);
    } else {
      setSelectedRoadClasses(selectedRoadClasses?.filter((roadClass) => !roadClassGroupIds.includes(roadClass)) || []);
    }
  };

  useEffect(() => {
    if (selectedFocusArea && !selectedAreaId) {
      setSelectedAreaId(selectedFocusArea.licensedAreaId);
    }
  }, [selectedFocusArea, selectedAreaId]);

  useEffect(() => {
    if (selectedAreaId && selectedTimePeriod && metadataRoadClassesIds) {
      dispatch(
        analyticsActions.fetchAreaAccuracyTableData({
          licensedAreaId: Number(selectedAreaId),
          timePeriod: selectedTimePeriod,
          roadClassFilter: selectedRoadClasses || [],
        }),
      );
    }
  }, [selectedAreaId, selectedTimePeriod, selectedRoadClasses, metadataRoadClassesIds, dispatch]);

  useEffect(() => {
    if (selectedAreaId && selectedTimePeriod && metadataRoadClassesIds) {
      const roadClassMapping = Object.entries(roadClassGroups).reduce(
        (obj: { [key: string]: number[] }, [key, value]) => {
          const roadClassGroupIds = value.classes.map((c) => c.id).filter((c) => metadataRoadClassesIds.includes(c));
          if (roadClassGroupIds.length) obj[key] = roadClassGroupIds;
          return obj;
        },
        {},
      );

      dispatch(
        analyticsActions.fetchAreaAccuracyScatterPlotData({
          licensedAreaId: Number(selectedAreaId),
          timePeriod: selectedTimePeriod,
          roadClassMapping,
        } as any),
      );
    }
  }, [areas, selectedAreaId, selectedTimePeriod, metadataRoadClassesIds, dispatch]);

  return (
    <DataQualityMetricsContainer>
      <Typography variant="h5" fontWeight={700} marginBottom={2} textAlign={"center"}>
        Data Quality Metrics - Area of Interest
      </Typography>
      <ControlsScatterContainer>
        <DataQualityMetricsControls
          areas={areas}
          selectedAreaId={selectedAreaId}
          selectedTimePeriod={selectedTimePeriod}
          selectedRoadClasses={selectedRoadClasses || []}
          metadataRoadClassesIds={metadataRoadClassesIds || []}
          availableTimePeriods={availableTimePeriods}
          handleChangeSelectedAreaId={handleChangeSelectedAreaId}
          handleChangeSelectedTimePeriod={handleChangeSelectedTimePeriod}
          handleRoadClassChange={handleRoadClassChange}
        />
        <AreaAccuracyScatterPlot
          data={accuracyScatterPlotData?.data || []}
          selectedRoadClasses={selectedRoadClasses}
          loading={loading}
          error={error}
        />
      </ControlsScatterContainer>
      <AreaAccuracyTable data={accuracyTableData?.data} loading={loading} error={error} />
    </DataQualityMetricsContainer>
  );
};
