import { Api } from "api";
import { produce } from "immer";
import { Reducer } from "redux";
import { all, call, getContext, put, takeLatest } from "redux-saga/effects";

import { Action, ActionsUnion, createAction } from "store/actionHelpers";
import { DataState, LoadingErrorData, ResponseError } from "store/interfaces";

import {
  CorridorEdge,
  CorridorEdgeCountStats,
  CorridorHeatmapConfiguration,
  CorridorHeatmapConfigurationRequestParams,
  CorridorMetadata,
  CorridorNodeCountStats,
  EdgesRangeRequest,
  FiltersType,
  MeasureRangeResponse,
  ServiceOverlay,
} from "types";

import { reportAboutErrorState } from "utils/reports";

import { CorridorActionType } from "./actionTypes";

export interface CorridorState {
  corridorMetadata: LoadingErrorData<CorridorMetadata>;
  corridorEdgeIds: LoadingErrorData<boolean>;
  corridorEdgeCounts: LoadingErrorData<CorridorEdgeCountStats>;
  corridorEdgeAvailabeRange: LoadingErrorData<MeasureRangeResponse>;
  corridorEdgeDetails: LoadingErrorData<any>;
  corridorNodeIds: LoadingErrorData<boolean>;
  corridorNodeCounts: LoadingErrorData<CorridorNodeCountStats>;
  corridorHeatmapConfiguration: LoadingErrorData<any>;
  serviceLayers: LoadingErrorData<ServiceOverlay[]>;
  filters: FiltersType | null;
  corridorEdgeRange: [number, number] | null;
  selectedCorridorEdge: CorridorEdge | null;
  selectedCorridorEdgeDirectionId: string | null;
  edgesOpacityFactor: number;
  edgesWidthFactor: number;
  heatmapOpacityFactor: number;
  heatmapRadiusFactor: number;
  heatmapIntensityFactor: number;
}

export type CorridorAction = ActionsUnion<typeof corridorActions>;

export const corridorActions = {
  fetchCorridorMetadata: (config: any) => createAction(CorridorActionType.FETCH_CORRIDOR_METADATA, config),
  fetchCorridorMetadataSucceeded: (corridorMetadata: CorridorMetadata) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_METADATA_SUCCEEDED, {
      corridorMetadata,
    }),
  fetchCorridorMetadataFailed: (error: ResponseError) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_METADATA_FAILED, error),

  fetchCorridorEdgeIds: (config: any) => createAction(CorridorActionType.FETCH_CORRIDOR_EDGE_IDS, config),
  fetchCorridorEdgeIdsSucceeded: (status: boolean) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_EDGE_IDS_SUCCEEDED, {
      status,
    }),
  fetchCorridorEdgeIdsFailed: (error: ResponseError) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_EDGE_IDS_FAILED, error),

  fetchCorridorEdgeCounts: (config: any) => createAction(CorridorActionType.FETCH_CORRIDOR_EDGE_COUNTS, config),
  fetchCorridorEdgeCountsSucceeded: (corridorEdgeCounts: CorridorEdgeCountStats) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_EDGE_COUNTS_SUCCEEDED, {
      corridorEdgeCounts,
    }),
  fetchCorridorEdgeCountsFailed: (error: ResponseError) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_EDGE_COUNTS_FAILED, error),

  fetchCorridorEdgeAvailableRange: (config: EdgesRangeRequest) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE, config),
  fetchCorridorEdgeAvailableRangeSucceeded: (corridorEdgeAvailableRange: MeasureRangeResponse) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE_SUCCEEDED, {
      corridorEdgeAvailableRange,
    }),
  fetchCorridorEdgeAvailableRangeFailed: (error: ResponseError) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE_FAILED, error),

  fetchCorridorNodeIds: (config: any) => createAction(CorridorActionType.FETCH_CORRIDOR_NODE_IDS, config),
  fetchCorridorNodeIdsSucceeded: (status: boolean) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_NODE_IDS_SUCCEEDED, {
      status,
    }),
  fetchCorridorNodeIdsFailed: (error: ResponseError) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_NODE_IDS_FAILED, error),

  fetchCorridorNodeCounts: (config: any) => createAction(CorridorActionType.FETCH_CORRIDOR_NODE_COUNTS, config),
  fetchCorridorNodeCountsSucceeded: (corridorNodeCounts: CorridorNodeCountStats) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_NODE_COUNTS_SUCCEEDED, {
      corridorNodeCounts,
    }),
  fetchCorridorNodeCountsFailed: (error: ResponseError) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_NODE_COUNTS_FAILED, error),

  fetchServiceOverlayLayers: () => createAction(CorridorActionType.FETCH_SERVICE_OVERLAY_LAYERS),
  fetchServiceOverlayLayersSucceeded: (serviceLayers: ServiceOverlay[]) =>
    createAction(CorridorActionType.FETCH_SERVICE_OVERLAY_LAYERS_SUCCEEDED, {
      serviceLayers,
    }),
  fetchServiceOverlayLayersFailed: (error: ResponseError) =>
    createAction(CorridorActionType.FETCH_SERVICE_OVERLAY_LAYERS_FAILED, error),
  clearServiceOverlayLayers: () => createAction(CorridorActionType.CLEAR_SERVICE_OVERLAY_LAYERS),

  fetchCorridorEdgeDetails: (config: any) => createAction(CorridorActionType.FETCH_CORRIDOR_EDGE_DETAILS, config),
  fetchCorridorEdgeDetailsSucceeded: (corridorEdgeDetails: any) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_EDGE_DETAILS_SUCCEEDED, {
      corridorEdgeDetails,
    }),
  fetchCorridorEdgeDetailsFailed: (error: ResponseError) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_EDGE_DETAILS_FAILED, error),

  fetchCorridorHeatmapConfiguration: (config: CorridorHeatmapConfigurationRequestParams) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_HEATMAP_CONFIGURATION, config),
  fetchCorridorHeatmapConfigurationSucceeded: (corridorHeatmapConfiguration: CorridorHeatmapConfiguration) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_HEATMAP_CONFIGURATION_SUCCEEDED, {
      corridorHeatmapConfiguration,
    }),
  fetchCorridorHeatmapConfigurationFailed: (error: ResponseError) =>
    createAction(CorridorActionType.FETCH_CORRIDOR_HEATMAP_CONFIGURATION_FAILED, error),

  updateCurrentFilters: (filters: FiltersType | null) => createAction(CorridorActionType.SET_CORRIDOR_FILTERS, filters),

  setCorridorEdgeRange: (corridorEdgeRange: [number, number] | null) =>
    createAction(CorridorActionType.SET_CORRIDOR_EDGE_RANGE, corridorEdgeRange),

  setSelectedCorridorEdge: (selectedCorridorEdge: CorridorEdge | null) =>
    createAction(CorridorActionType.SET_SELECTED_CORRIDOR_EDGE, selectedCorridorEdge),

  setSelectedCorridorEdgeDirectionId: (selectedCorridorEdgeDirectionId: string | null) =>
    createAction(CorridorActionType.SET_SELECTED_CORRIDOR_EDGE_DIRECTION_ID, selectedCorridorEdgeDirectionId),
  clearCorridorMetadata: () => createAction(CorridorActionType.CLEAR_CORRIDOR_METADATA),

  setEdgesOpacityFactor: (edgesOpacityFactor: number) =>
    createAction(CorridorActionType.SET_EDGES_OPACITY_FACTOR, edgesOpacityFactor),
  setEdgesWidthFactor: (edgesWidthFactor: number) =>
    createAction(CorridorActionType.SET_EDGES_WIDTH_FACTOR, edgesWidthFactor),
  setHeatmapOpacityFactor: (heatmapOpacityFactor: number) =>
    createAction(CorridorActionType.SET_HEATMAP_OPACITY_FACTOR, heatmapOpacityFactor),
  setHeatmapRadiusFactor: (heatmapRadiusFactor: number) =>
    createAction(CorridorActionType.SET_HEATMAP_RADIUS_FACTOR, heatmapRadiusFactor),
  setHeatmapIntensityFactor: (heatmapIntensityFactor: number) =>
    createAction(CorridorActionType.SET_HEATMAP_INTENSITY_FACTOR, heatmapIntensityFactor),
};

const initialState: CorridorState = {
  corridorMetadata: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  corridorEdgeIds: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  corridorEdgeCounts: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  corridorEdgeAvailabeRange: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  corridorNodeIds: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  corridorNodeCounts: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  corridorHeatmapConfiguration: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  serviceLayers: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  corridorEdgeDetails: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  filters: null,
  corridorEdgeRange: null,
  selectedCorridorEdge: null,
  selectedCorridorEdgeDirectionId: null,
  edgesOpacityFactor: 1,
  edgesWidthFactor: 1,
  heatmapOpacityFactor: 1,
  heatmapRadiusFactor: 1,
  heatmapIntensityFactor: 1,
};

const corridorReducer: Reducer<CorridorState, CorridorAction> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CorridorActionType.FETCH_CORRIDOR_METADATA: {
        draft.corridorMetadata = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_METADATA_SUCCEEDED: {
        draft.corridorMetadata = {
          state: DataState.AVAILABLE,
          data: action.payload.corridorMetadata,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_METADATA_FAILED: {
        draft.corridorMetadata = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_EDGE_IDS: {
        draft.corridorEdgeIds = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_EDGE_IDS_SUCCEEDED: {
        draft.corridorEdgeIds = {
          state: DataState.AVAILABLE,
          data: action.payload.status,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_EDGE_IDS_FAILED: {
        draft.corridorEdgeIds = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_EDGE_COUNTS: {
        draft.corridorEdgeCounts = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_EDGE_COUNTS_SUCCEEDED: {
        draft.corridorEdgeCounts = {
          state: DataState.AVAILABLE,
          data: action.payload.corridorEdgeCounts,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_EDGE_COUNTS_FAILED: {
        draft.corridorEdgeCounts = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE: {
        draft.corridorEdgeAvailabeRange = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE_SUCCEEDED: {
        const { range, quantile } = action.payload.corridorEdgeAvailableRange;
        const newAvailableMin = quantile || range.min;
        const newAvailableMax = range.max;
        const previousAvailableMin =
          state.corridorEdgeAvailabeRange.data?.quantile || state.corridorEdgeAvailabeRange?.data?.range.min;
        const previousAvailableMax = state.corridorEdgeAvailabeRange.data?.range.max;
        const currentCorridorMinRange = state.corridorEdgeRange?.[0] || quantile || 0;
        const currentCorridorMaxRange = state.corridorEdgeRange?.[1] || Infinity;

        const corridorMinRange =
          currentCorridorMinRange === previousAvailableMin
            ? newAvailableMin
            : Math.max(currentCorridorMinRange, range.min);
        const corridorMaxRange =
          currentCorridorMaxRange === previousAvailableMax
            ? newAvailableMax
            : Math.min(currentCorridorMaxRange, range.max);

        draft.corridorEdgeRange = [corridorMinRange, corridorMaxRange];
        draft.corridorEdgeAvailabeRange = {
          state: DataState.AVAILABLE,
          data: action.payload.corridorEdgeAvailableRange,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE_FAILED: {
        draft.corridorEdgeAvailabeRange = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_NODE_IDS: {
        draft.corridorNodeIds = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_NODE_IDS_SUCCEEDED: {
        draft.corridorNodeIds = {
          state: DataState.AVAILABLE,
          data: action.payload.status,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_NODE_IDS_FAILED: {
        draft.corridorNodeIds = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_NODE_COUNTS: {
        draft.corridorNodeCounts = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_NODE_COUNTS_SUCCEEDED: {
        draft.corridorNodeCounts = {
          state: DataState.AVAILABLE,
          data: action.payload.corridorNodeCounts,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_NODE_COUNTS_FAILED: {
        draft.corridorNodeCounts = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_HEATMAP_CONFIGURATION: {
        draft.corridorHeatmapConfiguration = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_HEATMAP_CONFIGURATION_SUCCEEDED: {
        draft.corridorHeatmapConfiguration = {
          state: DataState.AVAILABLE,
          data: action.payload.corridorHeatmapConfiguration,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_HEATMAP_CONFIGURATION_FAILED: {
        draft.corridorHeatmapConfiguration = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case CorridorActionType.FETCH_SERVICE_OVERLAY_LAYERS: {
        draft.serviceLayers = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_SERVICE_OVERLAY_LAYERS_SUCCEEDED: {
        draft.serviceLayers = {
          state: DataState.AVAILABLE,
          data: action.payload.serviceLayers,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_SERVICE_OVERLAY_LAYERS_FAILED: {
        draft.serviceLayers = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case CorridorActionType.CLEAR_SERVICE_OVERLAY_LAYERS: {
        draft.serviceLayers = initialState.serviceLayers;
        return;
      }
      case CorridorActionType.SET_CORRIDOR_FILTERS: {
        draft.filters = action.payload;
        draft.corridorEdgeCounts = initialState.corridorEdgeCounts;
        draft.corridorNodeCounts = initialState.corridorNodeCounts;
        draft.corridorHeatmapConfiguration = initialState.corridorHeatmapConfiguration;
        draft.corridorEdgeDetails = initialState.corridorEdgeDetails;
        draft.corridorEdgeRange = initialState.corridorEdgeRange;
        return;
      }
      case CorridorActionType.SET_CORRIDOR_EDGE_RANGE: {
        draft.corridorEdgeRange = action.payload;
        return;
      }
      case CorridorActionType.SET_SELECTED_CORRIDOR_EDGE: {
        draft.selectedCorridorEdge = action.payload;
        draft.selectedCorridorEdgeDirectionId = action.payload?.id.toString() || null;
        draft.corridorEdgeDetails = initialState.corridorEdgeDetails;
        return;
      }
      case CorridorActionType.SET_SELECTED_CORRIDOR_EDGE_DIRECTION_ID: {
        if (state.selectedCorridorEdgeDirectionId !== action.payload) {
          draft.selectedCorridorEdgeDirectionId = action.payload;
        }

        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_EDGE_DETAILS: {
        draft.corridorEdgeDetails = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_EDGE_DETAILS_SUCCEEDED: {
        draft.corridorEdgeDetails = {
          state: DataState.AVAILABLE,
          data: action.payload.corridorEdgeDetails,
          error: null,
        };
        return;
      }
      case CorridorActionType.FETCH_CORRIDOR_EDGE_DETAILS_FAILED: {
        draft.corridorEdgeDetails = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case CorridorActionType.SET_EDGES_OPACITY_FACTOR: {
        draft.edgesOpacityFactor = action.payload;
        return;
      }
      case CorridorActionType.SET_EDGES_WIDTH_FACTOR: {
        draft.edgesWidthFactor = action.payload;
        return;
      }
      case CorridorActionType.SET_HEATMAP_OPACITY_FACTOR: {
        draft.heatmapOpacityFactor = action.payload;
        return;
      }
      case CorridorActionType.SET_HEATMAP_RADIUS_FACTOR: {
        draft.heatmapRadiusFactor = action.payload;
        return;
      }
      case CorridorActionType.SET_HEATMAP_INTENSITY_FACTOR: {
        draft.heatmapIntensityFactor = action.payload;
        return;
      }
      case CorridorActionType.CLEAR_CORRIDOR_METADATA: {
        draft.corridorMetadata = initialState.corridorMetadata;
        draft.corridorEdgeIds = initialState.corridorEdgeIds;
        draft.corridorEdgeCounts = initialState.corridorEdgeCounts;
        draft.corridorNodeIds = initialState.corridorNodeIds;
        draft.corridorNodeCounts = initialState.corridorNodeCounts;
        draft.corridorHeatmapConfiguration = initialState.corridorHeatmapConfiguration;
        draft.corridorEdgeDetails = initialState.corridorEdgeDetails;
        draft.selectedCorridorEdge = initialState.selectedCorridorEdge;
        draft.selectedCorridorEdgeDirectionId = initialState.selectedCorridorEdgeDirectionId;

        return;
      }

      default:
        return state;
    }
  });

export default corridorReducer;

function* fetchCorridorMetadata(action: Action<string, { timePeriod: string }>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchCorridorMetadata },
    } = api as Api;
    const corridorMetadata: any = yield call(fetchCorridorMetadata, action.payload);

    const corridorLevels: any = {};

    if (corridorMetadata?.corridorLevels && corridorMetadata.corridorLevels?.length > 0) {
      corridorMetadata.corridorLevels.forEach((corridorLevel: any) => {
        const zoningLevelId = corridorLevel.zoningLevel.id;

        corridorLevels[zoningLevelId] = corridorLevel;
      });
    }

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_METADATA_SUCCEEDED,
      payload: { corridorMetadata: { ...corridorMetadata, corridorLevels } },
    });
  } catch (e) {
    reportAboutErrorState(e, CorridorActionType.FETCH_CORRIDOR_METADATA_FAILED);

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_METADATA_FAILED,
      payload: e,
    });
  }
}

function* fetchCorridorEdgeIds(action: Action<string, { timePeriod: string }>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchCorridorEdgeIds },
    } = api as Api;
    const status = yield call(fetchCorridorEdgeIds, action.payload);

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_EDGE_IDS_SUCCEEDED,
      payload: { status },
    });
  } catch (e) {
    reportAboutErrorState(e, CorridorActionType.FETCH_CORRIDOR_EDGE_IDS_FAILED);

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_EDGE_IDS_FAILED,
      payload: e,
    });
  }
}

function* fetchCorridorEdgeCounts(action: Action<string, { timePeriod: string }>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchCorridorEdgeCounts },
    } = api as Api;
    const corridorEdgeCounts = (yield call(fetchCorridorEdgeCounts, action.payload)) as CorridorEdgeCountStats;

    const corridorEdgeAvailableRange = {
      range: { min: corridorEdgeCounts.minVolume, max: corridorEdgeCounts.maxVolume },
      quantile: corridorEdgeCounts.quantile,
    };

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE_SUCCEEDED,
      payload: { corridorEdgeAvailableRange },
    });

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_EDGE_COUNTS_SUCCEEDED,
      payload: { corridorEdgeCounts },
    });
  } catch (e) {
    reportAboutErrorState(e, CorridorActionType.FETCH_CORRIDOR_EDGE_COUNTS_FAILED);

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_EDGE_COUNTS_FAILED,
      payload: e,
    });
  }
}

function* fetchCorridorEdgeAvailableRange(action: Action<string, EdgesRangeRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchCorridorEdgeAvailableRange },
    } = api as Api;
    const corridorEdgeAvailableRange: any = yield call(fetchCorridorEdgeAvailableRange, action.payload);

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE_SUCCEEDED,
      payload: { corridorEdgeAvailableRange },
    });
  } catch (e) {
    reportAboutErrorState(e, CorridorActionType.FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE_FAILED);

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE_FAILED,
      payload: e,
    });
  }
}

function* fetchCorridorEdgeDetails(action: Action<string, { timePeriod: string }>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchCorridorEdgeDetails },
    } = api as Api;
    const corridorEdgeDetails: any = yield call(fetchCorridorEdgeDetails, action.payload);

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_EDGE_DETAILS_SUCCEEDED,
      payload: { corridorEdgeDetails },
    });
  } catch (e) {
    reportAboutErrorState(e, CorridorActionType.FETCH_CORRIDOR_EDGE_DETAILS_FAILED);

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_EDGE_DETAILS_FAILED,
      payload: e,
    });
  }
}

function* fetchCorridorNodeIds(action: Action<string, { timePeriod: string }>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchCorridorNodeIds },
    } = api as Api;
    const status = yield call(fetchCorridorNodeIds, action.payload);

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_NODE_IDS_SUCCEEDED,
      payload: { status },
    });
  } catch (e) {
    reportAboutErrorState(e, CorridorActionType.FETCH_CORRIDOR_NODE_IDS_FAILED);

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_NODE_IDS_FAILED,
      payload: e,
    });
  }
}

function* fetchCorridorNodeCounts(action: Action<string, { timePeriod: string }>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchCorridorNodeCounts },
    } = api as Api;
    const corridorNodeCounts = (yield call(fetchCorridorNodeCounts, action.payload)) as CorridorNodeCountStats;

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_NODE_COUNTS_SUCCEEDED,
      payload: { corridorNodeCounts },
    });
  } catch (e) {
    reportAboutErrorState(e, CorridorActionType.FETCH_CORRIDOR_NODE_COUNTS_FAILED);

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_NODE_COUNTS_FAILED,
      payload: e,
    });
  }
}

function* fetchServiceOverlayLayers(): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchServiceOverlayLayers },
    } = api as Api;
    const serviceLayers: any = yield call(fetchServiceOverlayLayers);

    yield put({
      type: CorridorActionType.FETCH_SERVICE_OVERLAY_LAYERS_SUCCEEDED,
      payload: { serviceLayers },
    });
  } catch (e) {
    reportAboutErrorState(e, CorridorActionType.FETCH_SERVICE_OVERLAY_LAYERS_FAILED);

    yield put({
      type: CorridorActionType.FETCH_SERVICE_OVERLAY_LAYERS_FAILED,
      payload: e,
    });
  }
}

function* fetchCorridorHeatmapConfiguration(
  action: Action<string, CorridorHeatmapConfigurationRequestParams>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchCorridorHeatmapConfiguration },
    } = api as Api;
    const corridorHeatmapConfiguration: any = yield call(fetchCorridorHeatmapConfiguration, action.payload);

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_HEATMAP_CONFIGURATION_SUCCEEDED,
      payload: { corridorHeatmapConfiguration },
    });
  } catch (e) {
    reportAboutErrorState(e, CorridorActionType.FETCH_CORRIDOR_HEATMAP_CONFIGURATION_FAILED);

    yield put({
      type: CorridorActionType.FETCH_CORRIDOR_HEATMAP_CONFIGURATION_FAILED,
      payload: e,
    });
  }
}

export function* corridorSaga() {
  yield all([
    takeLatest(CorridorActionType.FETCH_CORRIDOR_METADATA, fetchCorridorMetadata),
    takeLatest(CorridorActionType.FETCH_CORRIDOR_EDGE_IDS, fetchCorridorEdgeIds),
    takeLatest(CorridorActionType.FETCH_CORRIDOR_EDGE_COUNTS, fetchCorridorEdgeCounts),
    takeLatest(CorridorActionType.FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE, fetchCorridorEdgeAvailableRange),
    takeLatest(CorridorActionType.FETCH_CORRIDOR_EDGE_DETAILS, fetchCorridorEdgeDetails),
    takeLatest(CorridorActionType.FETCH_CORRIDOR_NODE_IDS, fetchCorridorNodeIds),
    takeLatest(CorridorActionType.FETCH_CORRIDOR_NODE_COUNTS, fetchCorridorNodeCounts),
    takeLatest(CorridorActionType.FETCH_SERVICE_OVERLAY_LAYERS, fetchServiceOverlayLayers),
    takeLatest(CorridorActionType.FETCH_CORRIDOR_HEATMAP_CONFIGURATION, fetchCorridorHeatmapConfiguration),
  ]);
}
