import { FC } from "react";

import {
  Description,
  SubTitle,
  TextSection,
} from "./Table";

export const Screenlines: FC = () => (
  <>
    <Description>Screenlines summarize the traffic that crosses certain locations in specified directions. </Description>

    <Description>In OpenPaths Patterns, screenlines have the following <b>characteristics</b>:</Description>

    <TextSection>
      <ul style={{ marginLeft: "2rem" }}>
        <li>Screenline <b>geometry</b>, defined as a line string or closed polygon (for cordons). </li>
        <ul style={{ marginLeft: "2rem" }}>
          <li>
            The geometry must consist of a single part and not have self-intersections.
          </li>
          <li>
            The screenline geometries can be defined manually on the map, or imported from a shapefile.
          </li>
        </ul>

        <li>List of <b>intersecting segments</b> whose volume contributes to the screenline volume. </li>
        <ul style={{ marginLeft: "2rem" }}>
          <li>
            This list is automatically built based on selected road classes when defining or importing a screenline,
            but can then be adapted by adding/removing individual intersections.
          </li>
          <li>
            These changes can also be done in bulk by changing the selected road classes and then using the options
            to include all newly found intersections or removing all intersections that no longer match the road class filter.
          </li>
        </ul>

        <li><b>Type of volume:</b> Screenlines can be defined to summarize <b>vehicular</b> or <b>pedestrian</b> volumes.</li>
        <ul style={{ marginLeft: "2rem" }}>
          <li>
            <b>Pedestrian</b> screenlines do not distinguish intersection directions, given that pedestrian volumes are not
            differentiated by direction of movement.
          </li>
          <li>
            <b>Vehicular</b> screenlines summarize the volume per crossing direction and allow inclusion/exclusion of individual
            directional road segments.
          </li>
          <li>
            On the map, the <b>screenline volumes</b> are shown for the screenlines matching the <b>currently selected volume type</b>.
            Screenlines of the other type are shown as gray lines.
          </li>
          <li>
            In the screenline editor, only the screenlines matching the <b>currently selected volume type</b> can be <b>edited</b>.
            To edit the screenlines of the other type, the selected volume type for the map can be changed.
          </li>
        </ul>

        <li>
          <b>Intersection directions:</b> the direction of an intersecting segment is defined as to the left or right of the
          screenline, in the direction of the screenline geometry (viewed from the start point of the line).
        </li>
        <ul style={{ marginLeft: "2rem" }}>
          <li>
            For each screenline, labels for these directions are generated when the screenline is defined (e.g.,
            "East-bound" / "West-bound" for lines, or "inbound" / "outbound" for cordons), and can be customized as needed.
          </li>
          <li>
            Vehicular screenlines can optionally be defined to include only the volume in one of the intersection directions.
          </li>
        </ul>

        <li>
          <b>Cartographic display:</b> screenline volumes are shown as line width on the side of the line that the traffic is
          crossing <b>towards</b>. For the selected screenline, the intersecting segments and intersection points are shown.
        </li>
      </ul>
    </TextSection>

    <SubTitle>Usage</SubTitle>
    <TextSection style={{ marginBottom: "2rem" }}>
      <ul style={{ marginLeft: "2rem" }}>
        <li>
          <b>Filtering screenline volumes:</b> The screenline volume can be filtered both by the <b>category filters</b> (e.g.,
          selected day types or trip purposes) and the selection of <b>road classes.</b>
        </li>
        <ul style={{ marginLeft: "2rem" }}>
          <li>
            The road class filter can be used to <b>temporarily</b> filter the segment intersections defined for the screenline.
            Intersections that are assigned to the screenline, but do not match the current road class filter, are shown as grayed
            out on the map and the list of intersections, for the selected screenline, and do not contribute to the overall volume.
          </li>
        </ul>
        <li>
          <b>Saving screenlines:</b> screenlines can be saved to named "screenline documents." These screenline documents can be opened
          in the screenline editor and managed in folders on the "Datasets" page.
        </li>
        <ul style={{ marginLeft: "2rem" }}>
          <li>
            A screenline document can contain both vehicular and pedestrian screenlines.
          </li>
        </ul>
        <li>
          <b>Exporting screenlines:</b> screenlines are exported both as a shapefile with aggregated volumes (totals and per category,
          separate per direction in case of vehicular volumes) and as a CSV file with the volume by segment intersection. See the
          export reference documentation for details.
        </li>
        <ul style={{ marginLeft: "2rem" }}>
          <li>
            Note that only the screenlines that are defined for the <b>exported volume type</b> (vehicles or pedestrians) are included
            in the export.
          </li>
        </ul>
        <li>
          <b>Editing the screenline geometry:</b> the shape of screenlines can be modified by adding, moving, or deleting individual
          geometry vertices. The list of segment intersections is updated applying the following rules:
        </li>
        <ul style={{ marginLeft: "2rem" }}>
          <li>
            Stored intersecting segments that still intersect the updated geometry <b>remain included</b> (with updated
            intersection properties).
          </li>
          <li>
            Stored intersecting segments that no longer intersect the updated geometry <b>are removed</b>.
          </li>
          <li>
            Segments that intersect the updated geometry but were specifically excluded in the original list of
            intersections <b>remain excluded</b>.
          </li>
          <li>
            Segments that intersect the updated geometry, but did not intersect the original geometry, <b>are included if their
              road class matches</b> the current road class filter.
          </li>
        </ul>
      </ul>
    </TextSection>

    <SubTitle>Notes</SubTitle>
    <TextSection>
      <ul style={{ marginLeft: "2rem" }}>
        <li>
          <b>Relation to the entitlement area:</b> while screenlines can extend beyond the limits of an entitled area, only
          the volumes of segments that intersect that area are included in the summarized screenline volume.
        </li>
        <li>
          <b>Road network changes:</b> screenlines can be defined once and then be used for multiple time periods, even as
          the road network undergoes changes from year to year (refining road geometries and road classification, splitting
          and merging road features, adding new roads, etc.). Screenline intersections refer to road segments in a way that
          allows to dynamically find the best match on a given version of the road network.
        </li>
        <ul style={{ marginLeft: "2rem" }}>
          <li>The matching process is based on the following <b>criteria:</b></li>
          <ul style={{ marginLeft: "2rem" }}>
            <li>
              Segments are identified that cross the screenline within a maximum distance of the original intersection location,
              in the same intersection direction (left/right), and having a similar azimuth at the intersection location.
            </li>
            <li>
              Of these candidates, the closest is picked that has the same road class, or else the closest with a similar road
              class (same road category), or else the closest overall.
            </li>
          </ul>
          <li>
            Validation messages are produced in case these rules do not result in a match, or if the matching segment has
            noticeable differences (e.g., a significant offset or azimuth change at the intersection location, or a different
            road class). Often, these cases involve minor road classes for which there are the most frequent changes between
            network versions. For example, the 2023 vehicular road network includes many additional service roads but excludes
            pedestrian-only segments.
          </li>
          <li>
            It is worth revising the non-matched intersections. If a relevant segment was not found, there may be another
            intersection nearby which can be added to the screenline to make sure that its volume is included.
          </li>
        </ul>
        <li>
          Screenlines are not available on the Volumes map for <b>computed datasets</b>. To use screenlines in the subarea
          of a dataset, the containing area of interest can be opened.
        </li>
      </ul>
    </TextSection>
  </>
);
