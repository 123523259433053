import { LayerManager, useLayerManager } from "@daturon/mapboxgl-layer-manager";
import mapboxgl, { Layer, Popup, VectorSourceImpl } from "mapbox-gl";
import { Dispatch, MutableRefObject, RefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ZONE_BORDERS_LAYER_NAME } from "features/map/modules/od/map-data/od/layers";
import { getLayerFromZoom } from "features/map/utils";
import { ROAD_VMT_ZONE_SOURCE_ID } from "features/vmt/map-data/sources";

import { useAppDispatch, useAppSelector, usePrevious } from "hooks";

import { DataState } from "store/interfaces";
import { filtersActions } from "store/sections/filters";

import { MeasureType, RoadVmtZoneCounts, ZoneIds } from "types";

import { getCommonLayers } from "../common-map-data/layers";
import { getCommonSources } from "../common-map-data/sources";
import {
  useFetchMileageZoneDetails,
  useFetchRoadMileageZoneCounts,
  useFetchRoadVmtMetadata,
  useFetchRoadVmtZoneCounts,
  useFetchRoadVmtZoneDetails,
  useFetchZoneIds,
  useSetSelectedZone,
} from "./RoadVmtControllerCallbacks";
import { ZonePopupProps } from "./ZonePopupContent";
import { getRoadVmtHandlers } from "./map-data/handlers";
import { ROAD_VMT_LAYER_PREFIX, getCommonRoadVmtLayers, getRoadVmtLayers } from "./map-data/layers";
import { getRoadVmtSources } from "./map-data/sources";
import { getAvailableLayers, getColorScale } from "./utils";

interface Props {
  mapLoaded: boolean;
  map: MutableRefObject<mapboxgl.Map | null>;
  mapController: MutableRefObject<any>;
  zonePopupRef: MutableRefObject<HTMLDivElement | null>;
  setZonePopupRef: RefObject<Dispatch<SetStateAction<ZonePopupProps | null>>>;
  setColorScale: any;
  closeZoneAnalyticsPanelRef: RefObject<() => void>;
}

export const RoadVmtMapController = ({
  mapLoaded,
  map,
  mapController,
  zonePopupRef,
  setZonePopupRef,
  setColorScale,
  closeZoneAnalyticsPanelRef,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [mapboxLayerManager, setMapboxLayerManager] = useState<null | LayerManager>(null);
  const [deselectZone, setDeselectZone] = useState<() => void>(() => {});
  const [updateZoneCounts, setUpdateZoneCounts] = useState<Function | null>(null);
  const [scaleFn, setScaleFn] = useState<Function | null>(null);

  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const previousSelectedFocusAreaId = usePrevious(selectedFocusAreaId);
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const measure = useAppSelector((state) => state.filters.measure);

  const zoneIds = useAppSelector((state) => state.roadVmt.zoneIds);

  const roadVmtFilters = useAppSelector((state) => state.roadVmt.filters);

  const roadVmtMetadata = useAppSelector((state) => state.roadVmt.roadVmtMetadata);
  const roadVmtZoneCounts = useAppSelector((state) => state.roadVmt.roadVmtZoneCounts);
  const roadMileageZoneCounts = useAppSelector((state) => state.roadVmt.roadMileageZoneCounts);

  const selectedZone = useAppSelector((state) => state.roadVmt.selectedZone);
  const roadVmtZoneDetails = useAppSelector((state) => state.roadVmt.roadVmtZoneDetails);
  const mileageZoneDetails = useAppSelector((state) => state.roadVmt.mileageZoneDetails);

  const colorScheme = useAppSelector((state) => state.map.colorScheme);
  const opacityFactor = useAppSelector((state) => state.roadVmt.opacityFactor);

  const zoneIdsRef = useRef<ZoneIds | null>(null);
  const zoneVmtCountsRef = useRef<RoadVmtZoneCounts | null>(null);
  const zoneMileageCountsRef = useRef<RoadVmtZoneCounts | null>(null);
  const roadVmtLevelRef = useRef<string | null>(null);

  const mapboxZoneCountsHoverPopupRef = useRef<Popup>(null);

  const fetchRoadVmtMetadata = useFetchRoadVmtMetadata();
  const fetchZoneIds = useFetchZoneIds();
  const fetchRoadVmtZoneCounts = useFetchRoadVmtZoneCounts();
  const fetchRoadMileageZoneCounts = useFetchRoadMileageZoneCounts();
  const fetchRoadVmtZoneDetails = useFetchRoadVmtZoneDetails();
  const fetchMileageZoneDetails = useFetchMileageZoneDetails();
  const setSelectedZone = useSetSelectedZone();

  // Redirect to dashboard if no selectedArea (dataset has been deleted)
  useEffect(() => {
    if (focusAreas.state === DataState.AVAILABLE && selectedFocusAreaId && !selectedFocusArea) {
      toast.error("The dataset has been deleted", {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate("/dashboard");
      toast.clearWaitingQueue();
    }
  }, [focusAreas.state, selectedFocusArea, selectedFocusAreaId, navigate]);

  // Switch to first measure if currently selected is not available
  useEffect(() => {
    if (
      measure &&
      roadVmtMetadata.state === DataState.AVAILABLE &&
      !roadVmtMetadata.data?.measures?.find((m) => m.columnName === measure)
    ) {
      const firstMeasure = roadVmtMetadata.data?.measures?.[0]?.columnName;
      dispatch(filtersActions.setMeasure(firstMeasure as MeasureType));
    }
  }, [measure, roadVmtMetadata.state, roadVmtMetadata.data?.measures, dispatch]);

  // Update road VMT level ref
  useEffect(() => {
    if (roadVmtMetadata.data?.detailLevel) {
      roadVmtLevelRef.current = roadVmtMetadata.data.detailLevel;
    }
  }, [roadVmtMetadata.data?.detailLevel]);

  useEffect(() => {
    if (
      map.current &&
      zoneIds.state === DataState.AVAILABLE &&
      roadVmtZoneCounts.state === DataState.AVAILABLE &&
      roadVmtMetadata.state === DataState.AVAILABLE
    ) {
      const zoom = map.current.getZoom();
      const roadVmtTileLayers = getAvailableLayers(roadVmtMetadata.data);
      const layerZoomLevel = getLayerFromZoom(zoom, roadVmtTileLayers)?.level as string;
      const colorScaleFn = getColorScale(layerZoomLevel, zoneIds.data, roadVmtZoneCounts.data, colorScheme, true);
      setScaleFn(() => colorScaleFn);
      setColorScale(() => colorScaleFn);
    }
  }, [
    map,
    roadVmtMetadata.state,
    roadVmtMetadata.data,
    roadVmtZoneCounts.state,
    roadVmtZoneCounts.data,
    zoneIds.state,
    zoneIds.data,
    setColorScale,
    colorScheme,
  ]);

  // Change tileservice url on metadata load, if different from current
  useEffect(() => {
    if (roadVmtMetadata.data) {
      const source = map.current?.getSource(ROAD_VMT_ZONE_SOURCE_ID) as VectorSourceImpl | undefined;
      const tilesUrl = roadVmtMetadata.data.tileService.url + "/{z}/{x}/{y}.pbf";

      if (source?.tiles?.[0] !== tilesUrl) {
        source?.setTiles([tilesUrl]);
      }
    }
  }, [roadVmtMetadata.data, map]);

  // Initialize mapboxLayerManager
  useEffect(() => {
    if (
      mapLoaded &&
      !mapboxLayerManager &&
      selectedFocusArea &&
      roadVmtMetadata.state === DataState.AVAILABLE &&
      zoneIds.state === DataState.AVAILABLE
    ) {
      const commonSources = getCommonSources(selectedFocusArea.geometry);
      const roadVmtSources = getRoadVmtSources(roadVmtMetadata.data);

      const commonLayers = getCommonLayers();
      const roadVmtCommonLayers = getCommonRoadVmtLayers(roadVmtMetadata.data);
      const roadVmtLayers = getRoadVmtLayers(roadVmtMetadata.data, opacityFactor);

      /* eslint-disable react-hooks/rules-of-hooks */
      const layerManager = useLayerManager(
        map.current!,
        [...commonSources, ...roadVmtSources],
        [...roadVmtCommonLayers, ...roadVmtLayers, ...commonLayers],
      );
      /* eslint-enable react-hooks/rules-of-hooks */

      setMapboxLayerManager(layerManager);

      mapController.current = {
        layerManager,
        updateZoneCounts,
      };

      const roadVmtTileLayers = getAvailableLayers(roadVmtMetadata.data);
      const layerId = roadVmtTileLayers.find(({ level }) => level === roadVmtLevelRef.current)?.name;

      if (layerId) {
        const { deselectZone, updateCounts } = getRoadVmtHandlers(
          map,
          roadVmtTileLayers,
          layerId,
          zoneIdsRef,
          zoneVmtCountsRef,
          zoneMileageCountsRef,
          zonePopupRef,
          mapboxZoneCountsHoverPopupRef,
          setZonePopupRef,
          closeZoneAnalyticsPanelRef,
          setSelectedZone,
        );

        setUpdateZoneCounts(() => updateCounts);
        setDeselectZone(() => deselectZone);

        mapController.current.updateZoneCounts = updateCounts;
      }
    }
  }, [
    map,
    mapLoaded,
    mapController,
    mapboxLayerManager,
    selectedFocusArea,
    roadVmtMetadata.data,
    roadVmtMetadata.state,
    zoneIds.state,
    zonePopupRef,
    setZonePopupRef,
    updateZoneCounts,
    scaleFn,
    opacityFactor,
    closeZoneAnalyticsPanelRef,
    setSelectedZone,
  ]);

  // Fetch road VMT metadata
  useEffect(() => {
    if (mapLoaded && roadVmtMetadata.state === DataState.EMPTY) {
      fetchRoadVmtMetadata(timePeriod);
    }
  }, [mapLoaded, timePeriod, roadVmtMetadata.state, roadVmtMetadata.data, fetchRoadVmtMetadata]);

  //Fetch road VMT zones IDs
  useEffect(() => {
    if (
      selectedFocusArea &&
      !selectedFocusArea?.datasetId &&
      zoneIds.state === DataState.EMPTY &&
      roadVmtMetadata.state === DataState.AVAILABLE &&
      timePeriod
    ) {
      fetchZoneIds(timePeriod, roadVmtMetadata.data.tileService.layers, selectedFocusArea);
    } else if (zoneIds.state === DataState.AVAILABLE) {
      zoneIdsRef.current = zoneIds.data;
    }
  }, [selectedFocusArea, zoneIds.state, zoneIds.data, roadVmtMetadata, timePeriod, fetchZoneIds]);

  // Fetch road VMT zone counts
  useEffect(() => {
    if (
      roadVmtZoneCounts.state === DataState.EMPTY &&
      roadVmtMetadata.data?.detailLevel &&
      selectedFocusArea &&
      timePeriod &&
      roadVmtFilters
    ) {
      fetchRoadVmtZoneCounts(timePeriod, roadVmtMetadata.data.detailLevel, roadVmtFilters);
    } else if (roadVmtZoneCounts.state === DataState.AVAILABLE) {
      zoneVmtCountsRef.current = roadVmtZoneCounts.data;
    }
  }, [
    roadVmtZoneCounts.state,
    fetchRoadVmtZoneCounts,
    timePeriod,
    roadVmtMetadata.data?.detailLevel,
    selectedFocusArea,
    roadVmtZoneCounts.data,
    roadVmtFilters,
  ]);

  // Fetch road mileage zone counts
  useEffect(() => {
    if (
      roadMileageZoneCounts.state === DataState.EMPTY &&
      roadVmtMetadata.data?.detailLevel &&
      selectedFocusArea &&
      timePeriod &&
      roadVmtFilters
    ) {
      fetchRoadMileageZoneCounts(timePeriod, roadVmtMetadata.data.detailLevel, roadVmtFilters);
    } else if (roadMileageZoneCounts.state === DataState.AVAILABLE) {
      zoneMileageCountsRef.current = roadMileageZoneCounts.data;
    }
  }, [
    roadMileageZoneCounts.state,
    fetchRoadMileageZoneCounts,
    timePeriod,
    roadVmtMetadata.data?.detailLevel,
    selectedFocusArea,
    roadMileageZoneCounts.data,
    roadVmtFilters,
  ]);

  // Fetch road VMT zone details
  useEffect(() => {
    if (
      selectedZone &&
      roadVmtZoneDetails.state === DataState.EMPTY &&
      roadVmtMetadata.data?.measures &&
      timePeriod &&
      roadVmtFilters
    ) {
      const dimensions = roadVmtMetadata.data.measures
        .find((d) => d.columnName === measure)
        ?.dimensions.filter((d) => d.enabled)
        .map((d) => d.columnName);

      if (dimensions) {
        fetchRoadVmtZoneDetails(selectedZone.zoneId, timePeriod, roadVmtFilters, dimensions);
      }
    }
  }, [
    selectedZone,
    roadVmtZoneDetails.state,
    fetchRoadVmtZoneDetails,
    timePeriod,
    roadVmtFilters,
    roadVmtMetadata.data,
    measure,
  ]);

  // Fetch road mileage zone details
  useEffect(() => {
    if (
      selectedZone &&
      mileageZoneDetails.state === DataState.EMPTY &&
      roadVmtMetadata.data?.measures &&
      timePeriod &&
      roadVmtFilters
    ) {
      const dimensions = roadVmtMetadata.data.measures
        .find((d) => d.columnName === measure)
        ?.dimensions.filter((d) => d.enabled)
        .map((d) => d.columnName);

      if (dimensions) {
        fetchMileageZoneDetails(selectedZone.zoneId, timePeriod, roadVmtFilters, dimensions);
      }
    }
  }, [
    selectedZone,
    mileageZoneDetails.state,
    fetchMileageZoneDetails,
    timePeriod,
    roadVmtFilters,
    roadVmtMetadata.data,
    measure,
  ]);

  // Render road VMT layers
  useEffect(() => {
    if (
      mapboxLayerManager &&
      roadVmtMetadata.data?.detailLevel &&
      roadVmtMetadata.state === DataState.AVAILABLE &&
      zoneIds.state === DataState.AVAILABLE &&
      roadVmtZoneCounts.state === DataState.AVAILABLE &&
      roadMileageZoneCounts.state === DataState.AVAILABLE &&
      scaleFn
    ) {
      const level = roadVmtMetadata.data.detailLevel;
      const roadVmtTilesLayer = roadVmtMetadata.data?.tileService.layers.find(
        ({ level }) => roadVmtMetadata.data.detailLevel === level,
      );
      const roadVmtCommonLayers = getCommonRoadVmtLayers(roadVmtMetadata.data);
      const roadVmtMapLayers = getRoadVmtLayers(roadVmtMetadata.data, opacityFactor)
        .filter((layer: any) => layer.level === level)
        .map((layer: any) => {
          const currentLayer = map.current?.getStyle().layers.find((l: any) => l.id === layer.id);
          return currentLayer ? { ...currentLayer, level: layer.level } : layer;
        });

      const mapLayers = [...roadVmtCommonLayers, ...roadVmtMapLayers, ...getCommonLayers()];
      const settings: any = {};

      for (const mapLayer of mapLayers) {
        settings[mapLayer.id] = {
          filter: mapLayer.filter,
          paint: mapLayer.paint,
          layout: mapLayer.layout,
        };

        if (roadVmtTilesLayer) {
          if (
            mapLayer.id === `${ROAD_VMT_LAYER_PREFIX}_${roadVmtTilesLayer.name}` ||
            mapLayer.id === `${ROAD_VMT_LAYER_PREFIX}_${ZONE_BORDERS_LAYER_NAME}_${roadVmtTilesLayer.name}`
          ) {
            const ids = Array.from(zoneIds.data.get(mapLayer.level)?.keys() || []);
            settings[mapLayer.id].filter = ["in", roadVmtTilesLayer.idField, ...ids];
          }
        }
      }

      const beforeLayer = map.current?.getLayer("admin_country") || map.current?.getLayer("road-label");

      mapboxLayerManager.renderOrderedLayers(
        mapLayers.map((layer: Layer) => layer.id),
        settings,
        beforeLayer?.id,
      );

      if (typeof updateZoneCounts === "function") {
        updateZoneCounts(scaleFn);
      }
    }
  }, [
    map,
    mapboxLayerManager,
    roadVmtMetadata.state,
    zoneIds.state,
    roadVmtZoneCounts.state,
    roadMileageZoneCounts.state,
    roadVmtMetadata.data,
    zoneIds.data,
    roadVmtZoneCounts.data,
    roadMileageZoneCounts.data,
    updateZoneCounts,
    scaleFn,
    opacityFactor,
    deselectZone,
  ]);

  // Deselect zone after changing time period
  useEffect(() => {
    if (typeof deselectZone === "function" && timePeriod) {
      deselectZone();
    }
  }, [timePeriod, deselectZone]);

  useEffect(() => {
    if (
      mapLoaded &&
      mapboxLayerManager &&
      previousSelectedFocusAreaId &&
      selectedFocusAreaId &&
      previousSelectedFocusAreaId !== selectedFocusAreaId
    ) {
      setMapboxLayerManager(null);
    }
  }, [previousSelectedFocusAreaId, selectedFocusAreaId, mapboxLayerManager, mapLoaded]);

  return <>{null}</>;
};
