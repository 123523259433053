import { Layer } from "mapbox-gl";
import { MutableRefObject } from "react";

import theme from "theme";

import { RoadIntersectionLevel } from "types";

import { ROAD_INTERSECTIONS_SOURCE_ID } from "./sources";

export const ROAD_INTERSECTIONS_LAYER_ID = "ROAD_INTERSECTIONS_LAYER_ID";

export const getRoadIntersectionLayerNameForLevel = (level: RoadIntersectionLevel) => {
  return level.maxRadius ? `${ROAD_INTERSECTIONS_LAYER_ID}_${level.maxRadius}` : ROAD_INTERSECTIONS_LAYER_ID;
};

export const getRoadIntersectionSourceLayerNameForLevel = (level: RoadIntersectionLevel) => {
  return level.tileService.layerName;
};

export const getRoadIntersectionsLayerCircleRadiusExpression = (
  maxVolume: number,
  minZoomLevel: number,
  maxZoomLevel: number,
  widthFactor: number,
) => [
  // interpolate doesn't support variables apparently, so this needs to be inlined and then the whole expression updated with new values
  "interpolate",
  ["linear"],
  ["zoom"],
  minZoomLevel,
  [
    "case",
    [">", ["coalesce", ["feature-state", "volume"], 0], 0],
    [
      "*",
      ["coalesce", ["number", ["literal", widthFactor]], 1.0],
      [
        "interpolate",
        ["linear"],
        ["sqrt", ["feature-state", "volume"]],
        0,
        [
          "*",
          [
            "case",
            [
              "any",
              ["boolean", ["feature-state", "hover"], false],
              ["boolean", ["feature-state", "selectHighlight"], false],
            ],
            1.4,
            1,
          ],
          3,
        ],
        Math.sqrt(maxVolume),
        [
          "*",
          [
            "case",
            [
              "any",
              ["boolean", ["feature-state", "hover"], false],
              ["boolean", ["feature-state", "selectHighlight"], false],
            ],
            1.4,
            1,
          ],
          14,
        ],
      ],
    ],
    0, // hide while volumes are updating
  ],
  maxZoomLevel,
  [
    "case",
    [">", ["coalesce", ["feature-state", "volume"], 0], 0],
    [
      "*",
      ["coalesce", ["number", ["literal", widthFactor]], 1.0],
      [
        "interpolate",
        ["linear"],
        ["sqrt", ["feature-state", "volume"]],
        0,
        [
          "*",
          [
            "case",
            [
              "any",
              ["boolean", ["feature-state", "hover"], false],
              ["boolean", ["feature-state", "selectHighlight"], false],
            ],
            1.6,
            1,
          ],
          4,
        ],
        Math.sqrt(maxVolume),
        [
          "*",
          [
            "case",
            [
              "any",
              ["boolean", ["feature-state", "hover"], false],
              ["boolean", ["feature-state", "selectHighlight"], false],
            ],
            1.6,
            1,
          ],
          20,
        ],
      ],
    ],
    0, // hide while volumes are updating
  ],
];

export const getRoadIntersectionLayerCircleOpacityExpression = (opacityFactor: number = 1) => [
  "*",
  // First multiply: handle hover state and select highlight
  [
    "case",
    ["boolean", ["feature-state", "selectHighlight"], false],
    1.0,
    ["case", ["boolean", ["feature-state", "hover"], false], 0.8, 1.0],
  ], // Second multiply: handle volume existence
  [
    "case",
    [">", ["coalesce", ["feature-state", "volume"], 0], 0],
    1,
    0.0, // Lower opacity when no volume data
  ],
  opacityFactor,
];

export const getRoadIntersectionsLayerCircleColorExpression = (level: RoadIntersectionLevel, maxVolume: number) => {
  const levelKey = level.maxRadius || "base";
  return [
    "case",
    ["boolean", ["feature-state", "selectHighlight"], false],
    theme.palette.roadIntersections.selectHighlight[0], // Use the selection highlight color
    ["boolean", ["feature-state", "hover"], false],
    theme.palette.roadIntersections[levelKey][1], // Use the second color when hovered
    [
      "interpolate",
      ["linear"],
      ["sqrt", ["coalesce", ["feature-state", "volume"], 0]],
      0,
      theme.palette.roadIntersections[levelKey][0],
      Math.sqrt(maxVolume),
      theme.palette.roadIntersections[levelKey][1],
    ],
  ];
};

export const getRoadIntersectionsLayerCircleStrokeColorExpression = (level: RoadIntersectionLevel) => {
  return level.maxRadius
    ? ["case", ["boolean", ["feature-state", "hover"], false], "black", "transparent"]
    : [
        "case",
        [
          "any",
          ["boolean", ["feature-state", "hover"], false],
          ["boolean", ["feature-state", "selectHighlight"], false],
        ],
        "black",
        "transparent",
      ];
};

export const getRoadIntersectionsLayerCircleStrokeWidthExpression = (level: RoadIntersectionLevel) => {
  return level.maxRadius
    ? ["case", ["boolean", ["feature-state", "hover"], false], 1, 0]
    : [
        "case",
        [
          "any",
          ["boolean", ["feature-state", "hover"], false],
          ["boolean", ["feature-state", "selectHighlight"], false],
        ],
        1,
        0,
      ];
};

export const getRoadIntersectionsLayers = (
  showRoadIntersectionsRef: MutableRefObject<boolean | null>,
  minZoomLevel: number,
  maxZoomLevel: number,
  baseLevel: RoadIntersectionLevel | undefined,
  ids?: number[],
) => {
  if (!baseLevel) return [];
  return [
    {
      id: `${ROAD_INTERSECTIONS_LAYER_ID}`,
      type: "circle",
      source: ROAD_INTERSECTIONS_SOURCE_ID,
      "source-layer": baseLevel.tileService.layerName,
      paint: {
        "circle-color": getRoadIntersectionsLayerCircleColorExpression(baseLevel, 1),
        "circle-opacity": getRoadIntersectionLayerCircleOpacityExpression(1.0),
        "circle-radius": getRoadIntersectionsLayerCircleRadiusExpression(1, minZoomLevel, maxZoomLevel, 1.0),
        "circle-stroke-color": getRoadIntersectionsLayerCircleStrokeColorExpression(baseLevel),
        "circle-stroke-width": getRoadIntersectionsLayerCircleStrokeWidthExpression(baseLevel),
      },
      filter: ids ? ["in", baseLevel.tileService.idField, ...ids] : undefined,
      layout: {
        visibility: showRoadIntersectionsRef.current ? "visible" : "none",
      },
      minzoom: minZoomLevel,
    },
  ] as Layer[];
};

export const getRoadIntersectionClusterLayers = (
  showRoadIntersectionsRef: MutableRefObject<boolean | null>,
  clusterLevels: RoadIntersectionLevel[] | undefined,
  ids?: Map<number, Map<number, number>>,
) => {
  return clusterLevels
    ?.map((clusterLevel) => {
      if (!clusterLevel.maxRadius) return null;
      const level = clusterLevel.maxRadius;
      const clusterIds = Array.from(ids?.get(level)?.keys() || []);
      return {
        id: `${ROAD_INTERSECTIONS_LAYER_ID}_${clusterLevel.maxRadius}`,
        type: "circle",
        source: `${ROAD_INTERSECTIONS_SOURCE_ID}_${clusterLevel.maxRadius}`,
        "source-layer": clusterLevel.tileService.layerName,
        paint: {
          "circle-color": getRoadIntersectionsLayerCircleColorExpression(clusterLevel, 1),
          "circle-opacity": getRoadIntersectionLayerCircleOpacityExpression(1.0),
          "circle-radius": getRoadIntersectionsLayerCircleRadiusExpression(
            1,
            clusterLevel.minZoomLevel,
            clusterLevel.maxZoomLevel,
            1.0,
          ),
          "circle-stroke-color": getRoadIntersectionsLayerCircleStrokeColorExpression(clusterLevel),
          "circle-stroke-width": getRoadIntersectionsLayerCircleStrokeWidthExpression(clusterLevel),
        },
        filter: clusterIds ? ["in", clusterLevel.tileService.idField, ...clusterIds] : undefined,
        layout: {
          visibility: showRoadIntersectionsRef.current ? "visible" : "none",
        },
        minzoom: clusterLevel.minZoomLevel,
        maxzoom: clusterLevel.maxZoomLevel,
      } as Layer;
    })
    .filter((layer) => !!layer) as Layer[];
};
