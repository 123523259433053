import memoryStore, { MemoryStoreKeys } from "api/memoryStore";
import { debounce } from "lodash";
import { AnyLayer, MapLayerMouseEvent, Map as MapboxMap, Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, RefObject, SetStateAction } from "react";

import { updateFeatureState } from "features/map/utils";
import { RoadIntersectionPopupContentProps } from "features/road-intersections/RoadIntersectionPopupContent";

import { RoadIntersectionClusterVolumes, RoadIntersectionLevel, RoadIntersectionVolumes } from "types";

import { OPTIMAL_SIZE_OF_REMOVING_BATCHING, OPTIMAL_SIZE_OF_UPDATING_BATCHING } from "../../roads/map-data/handlers";
import {
  ROAD_INTERSECTIONS_LAYER_ID,
  getRoadIntersectionLayerNameForLevel,
  getRoadIntersectionSourceLayerNameForLevel,
} from "./layers";
import { ROAD_INTERSECTIONS_SOURCE_ID, getRoadIntersectionSourceNameForLevel } from "./sources";

export const getRoadIntersectionHandlers = (
  map: MapboxMap,
  volumesDetails: RoadIntersectionVolumes,
  clusterVolumesDetails: RoadIntersectionClusterVolumes,
  levels: RoadIntersectionLevel[],
  roadIntersectionPopupRef: MutableRefObject<HTMLDivElement | null>,
  mapboxRoadIntersectionHoverPopupRef: RefObject<Popup>,
  setIntersectionPopupPropsRef: MutableRefObject<Dispatch<
    SetStateAction<RoadIntersectionPopupContentProps | null>
  > | null>,
  suppressRoadVolumePopupAndStatesRef: MutableRefObject<boolean>,
  selectedIntersectionIdRef: MutableRefObject<string | undefined>,
  setVolumesLoading: Dispatch<SetStateAction<boolean>>,
  setSelectedIntersectionId?: (selectedIntersectionId: string | undefined) => void,
  closeRoadsAnalyticsPanelRef?: MutableRefObject<(() => void) | null>,
) => {
  const clusterLevels = levels.filter((level) => level.maxRadius);
  const baseSourceLayerId = levels.find((level) => !level.maxRadius)?.tileService.layerName;

  const allLayerIds = levels.map((level) => getRoadIntersectionLayerNameForLevel(level));
  const clusterLayerIds = clusterLevels.map((level) => getRoadIntersectionLayerNameForLevel(level));

  let featureStateSet = new Set<string>();
  // let isInteracting = false;
  let zoom = map.getZoom();
  let hoveredItem: { id: string; source: string; sourceLayer: string } | null = null;

  const getVisibleFeaturesForLayer = (layer?: string) => {
    return map.queryRenderedFeatures(undefined, {
      layers: layer ? [layer] : [],
    });
  };

  const getClusterLevelAtZoomLevel = (zoom: number): RoadIntersectionLevel | undefined => {
    return levels.find((level) => level.minZoomLevel <= zoom && level.maxZoomLevel > zoom);
  };

  // Finds either first cluster level or base intersection layer at current map zoom level
  // Assumes layers are not overlapping in zoom ranges
  const getIntersectionLayerAtZoomLevel = (zoom: number): AnyLayer | undefined => {
    const layers = map.getStyle().layers || [];
    return layers.find((layer: any) => {
      return (
        layer?.source?.startsWith(ROAD_INTERSECTIONS_SOURCE_ID) &&
        layer.minzoom <= zoom &&
        (!layer.maxzoom || layer.maxzoom > zoom)
      );
    });
  };

  const batchRemoveFeatureStates = (featureIds: string[], clusterLevel: RoadIntersectionLevel) => {
    return new Promise<void>((resolve) => {
      let index = 0;

      function removeNextBatch() {
        const nextBatch = featureIds.slice(index, index + OPTIMAL_SIZE_OF_REMOVING_BATCHING);

        nextBatch.forEach((id) => {
          ["volume"].forEach((featureName) => {
            map.removeFeatureState(
              {
                source: getRoadIntersectionSourceNameForLevel(clusterLevel),
                sourceLayer: getRoadIntersectionSourceLayerNameForLevel(clusterLevel),
                id,
              },
              featureName,
            );
          });
        });

        index += OPTIMAL_SIZE_OF_REMOVING_BATCHING;

        if (index < featureIds.length) {
          requestAnimationFrame(removeNextBatch);
        } else {
          resolve();
        }
      }

      removeNextBatch();
    });
  };

  const batchUpdateFeatureStates = (featureStates: [string, number][], clusterLevel: RoadIntersectionLevel) => {
    return new Promise<void>((resolve) => {
      let index = 0;

      function updateNextBatch() {
        const nextBatch = featureStates.slice(index, index + OPTIMAL_SIZE_OF_UPDATING_BATCHING);

        nextBatch.forEach(([id, volume]) => {
          map.setFeatureState(
            {
              source: getRoadIntersectionSourceNameForLevel(clusterLevel),
              sourceLayer: getRoadIntersectionSourceLayerNameForLevel(clusterLevel),
              id,
            },
            {
              volume,
            },
          );
        });

        index += OPTIMAL_SIZE_OF_UPDATING_BATCHING;

        if (index < featureStates.length) {
          requestAnimationFrame(updateNextBatch);
        } else {
          resolve();
          setVolumesLoading(false);
        }
      }

      updateNextBatch();
    });
  };

  const updateFeatureStatesByVolumes = (isForceUpdate: boolean) => {
    if (
      !map ||
      (volumesDetails?.size === 0 && clusterVolumesDetails?.size === 0)
      // || isInteracting
    ) {
      setVolumesLoading(false);
      return;
    }

    // Get all visible features at current zoom level
    const currentLayer = getIntersectionLayerAtZoomLevel(map.getZoom());
    const clusterLevel = getClusterLevelAtZoomLevel(map.getZoom());
    const allVisibleFeatures = getVisibleFeaturesForLayer(currentLayer?.id);

    if (!currentLayer || !clusterLevel) {
      return;
    }

    if ((isForceUpdate || allVisibleFeatures.length === 0) && featureStateSet.size > 0) {
      featureStateSet.clear();
    }

    // Filter out features that are already visible
    const visibleFeatures = allVisibleFeatures.filter((f) => {
      if (isForceUpdate) {
        return true;
      }

      if (featureStateSet.size > 0 && featureStateSet.has(String(f.id))) {
        return false;
      }

      return true;
    });

    if (visibleFeatures.length === 0) {
      setVolumesLoading(false);
      return;
    }

    // cluster ids are not unqiue across levels, but at each non-overlapping level they are
    const features: Record<number, number> = {};

    const volumes: Map<number, number> = memoryStore.getItem(MemoryStoreKeys.ROAD_INTERSECTION_VOLUMES) || new Map();
    const clusterVolumes: Map<number, Map<number, number>> = memoryStore.getItem(
      MemoryStoreKeys.ROAD_INTERSECTION_CLUSTER_VOLUMES,
    ) || new Map();

    const idField = clusterLevel.tileService.idField;
    const isBaseLevel = currentLayer.id === ROAD_INTERSECTIONS_LAYER_ID;

    visibleFeatures.forEach((feature: any) => {
      const featureId = feature.properties[idField] as number;
      if (isBaseLevel) {
        features[featureId] = volumes.get(featureId) || 0;
      } else {
        // should always be set
        if (clusterLevel.maxRadius) {
          features[featureId] = clusterVolumes.get(clusterLevel.maxRadius)?.get(featureId) || 0;
        }
      }
    });

    const newFeatureStateSet = new Set(allVisibleFeatures.map((f) => String(f.id)));
    const invisibleFeatures = Array.from(featureStateSet).filter((id) => !newFeatureStateSet.has(id));

    // Remove feature states that are not visible anymore
    batchRemoveFeatureStates(invisibleFeatures, clusterLevel).then(() => {
      // Update the feature states
      batchUpdateFeatureStates(Object.entries(features), clusterLevel).then(() => {
        zoom = map.getZoom();
      });

      // Update the feature state set
      featureStateSet = newFeatureStateSet;
    });
  };

  const updateVolumes = (forceUpdate: boolean = false) => {
    const clusterLevel = getClusterLevelAtZoomLevel(map.getZoom());
    if (!clusterLevel) {
      return;
    }

    let sourceId = getRoadIntersectionSourceNameForLevel(clusterLevel);
    let layerId = getRoadIntersectionLayerNameForLevel(clusterLevel);

    // maxRadius null or 0 means base level
    const areVolumesAvailable =
      clusterLevel.maxRadius || clusterLevel.maxRadius === 0
        ? clusterVolumesDetails?.size > 0
        : volumesDetails?.size > 0;

    const areSourceAndLayerAvailableAndVisible =
      !!map.getSource(sourceId) &&
      !!map.getLayer(layerId) &&
      map.getLayoutProperty(layerId, "visibility") === "visible" &&
      areVolumesAvailable;

    if (!map || !areSourceAndLayerAvailableAndVisible) {
      return;
    }

    const isZoomDiff = Math.abs(map.getZoom() - zoom) > 0.25;
    const isForceUpdate = isZoomDiff || forceUpdate === true;

    setVolumesLoading(true);

    if (isForceUpdate) {
      batchRemoveFeatureStates(Array.from(featureStateSet), clusterLevel).then(() => {
        updateFeatureStatesByVolumes(isForceUpdate);
      });
    } else {
      updateFeatureStatesByVolumes(isForceUpdate);
    }
  };

  const debounceUpdateVolumes = debounce(updateVolumes, 500);
  const idleDebounceUpdateVolumes = () => {
    map.once("idle", debounceUpdateVolumes);
  };

  const handleUpdateVolumesBySourceData = (e: any) => {
    if (!e.sourceId.startsWith(ROAD_INTERSECTIONS_SOURCE_ID)) {
      return;
    }

    if (!e.tile || e.tile.state !== "loaded") {
      return;
    }

    idleDebounceUpdateVolumes();
  };

  const handleUpdateVolumesByZoomEnd = () => {
    idleDebounceUpdateVolumes();
  };

  const handleInteractionStart = () => {
    // isInteracting = true;
  };

  const handleInteractionEnd = () => {
    // isInteracting = false;
    idleDebounceUpdateVolumes();
  };

  const updateRoadsIntersectionVolumes = () => {
    updateVolumes(true);
  };

  const setHoveredItem = (itemId: string | undefined, sourceId: string, sourceLayerId: string) => {
    if (map && map.getSource(sourceId)) {
      if (hoveredItem && hoveredItem.id !== itemId) {
        map.setFeatureState(
          {
            source: hoveredItem.source,
            sourceLayer: hoveredItem.sourceLayer,
            id: hoveredItem.id,
          },
          { hover: false },
        );
      }

      if (itemId) {
        if (sourceId === ROAD_INTERSECTIONS_SOURCE_ID) {
          map.getCanvas().style.cursor = "default";
        } else {
          map.getCanvas().style.cursor = "zoom-in";
        }
        map.setFeatureState(
          {
            source: sourceId,
            sourceLayer: sourceLayerId,
            id: itemId,
          },
          { hover: true },
        );
        hoveredItem = { id: itemId, source: sourceId, sourceLayer: sourceLayerId };
      }
    }
  };

  const setHoveredNodeId = (nodeId: string | undefined, level: RoadIntersectionLevel) => {
    const sourceLayerName = getRoadIntersectionSourceLayerNameForLevel(level);
    if (sourceLayerName) {
      setHoveredItem(nodeId, getRoadIntersectionSourceNameForLevel(level), sourceLayerName);
    }
  };

  const toggleSelectHighlightIntersection = (nodeId: string | undefined, status: boolean) => {
    updateFeatureState(map, ROAD_INTERSECTIONS_SOURCE_ID, baseSourceLayerId, nodeId, "selectHighlight", status);
  };

  const toggleHoverIntersection = (nodeId: string | undefined, status: boolean) => {
    updateFeatureState(map, ROAD_INTERSECTIONS_SOURCE_ID, baseSourceLayerId, nodeId, "hover", status);
  };

  const handleClickForClusterLayer = (e: MapLayerMouseEvent) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: clusterLayerIds,
    });
    const feature = features?.[0];

    if (feature && feature?.properties?.cluster_size > 1) {
      const clusterLevel = getClusterLevelAtZoomLevel(map.getZoom());
      if (map && clusterLevel?.maxRadius) {
        map.easeTo({
          center: e.lngLat,
          zoom: clusterLevel.maxZoomLevel + 1,
        });
      }
    }
  };

  const handleClickForBaseLayer = (e: MapLayerMouseEvent) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: [ROAD_INTERSECTIONS_LAYER_ID],
    });
    const featureId = features?.[0]?.id?.toString();

    if (featureId) {
      const previouslySelectedIntersectionId = selectedIntersectionIdRef.current?.toString();

      if (mapboxRoadIntersectionHoverPopupRef.current && mapboxRoadIntersectionHoverPopupRef.current.isOpen()) {
        mapboxRoadIntersectionHoverPopupRef.current.remove();
      }

      if (
        !map.getLayer(ROAD_INTERSECTIONS_LAYER_ID) ||
        !map.getLayoutProperty(ROAD_INTERSECTIONS_LAYER_ID, "visibility")
      ) {
        return;
      }

      // Close road analytics panel, if any
      closeRoadsAnalyticsPanelRef?.current?.();

      if (previouslySelectedIntersectionId) {
        toggleHoverIntersection(previouslySelectedIntersectionId, false);
        toggleSelectHighlightIntersection(previouslySelectedIntersectionId, false);
        hoveredItem = null;
      }

      if (featureId === previouslySelectedIntersectionId) {
        // unselect
        if (setSelectedIntersectionId) {
          setSelectedIntersectionId(undefined);
        }
      } else {
        // select new feature
        if (setSelectedIntersectionId) {
          setSelectedIntersectionId(featureId);
        }
        toggleSelectHighlightIntersection(featureId, true);
      }
    }
  };

  const handleMouseMove = (e: MapLayerMouseEvent) => {
    map.getCanvas().style.cursor = "default";
    const feature = e.features?.[0];
    const featureId = feature?.id?.toString();

    if (mapboxRoadIntersectionHoverPopupRef.current) {
      mapboxRoadIntersectionHoverPopupRef.current.remove();
    }

    if (featureId) {
      const clusterLevel = getClusterLevelAtZoomLevel(map.getZoom());
      if (clusterLevel && feature?.layer.id === getRoadIntersectionLayerNameForLevel(clusterLevel)) {
        setHoveredNodeId(featureId, clusterLevel);
        suppressRoadVolumePopupAndStatesRef.current = true;

        const { legs, cluster_size } = feature?.properties as any;

        const { volume } = map.getFeatureState({
          source: getRoadIntersectionSourceNameForLevel(clusterLevel),
          sourceLayer: getRoadIntersectionSourceLayerNameForLevel(clusterLevel),
          id: featureId,
        });

        setIntersectionPopupPropsRef.current?.({
          legs,
          size: cluster_size,
          volume,
        });

        if (roadIntersectionPopupRef.current) {
          (mapboxRoadIntersectionHoverPopupRef.current as mapboxgl.Popup) = new Popup({
            closeButton: false,
            closeOnClick: false,
            offset: 15,
          })
            .setLngLat(e.lngLat)
            .setDOMContent(roadIntersectionPopupRef.current as Node)
            .addTo(map);
        }
      }
    }
  };

  const handleMouseLeave = () => {
    if (hoveredItem && map) {
      const clusterLevel = getClusterLevelAtZoomLevel(map.getZoom());
      if (clusterLevel && map.getSource(getRoadIntersectionSourceNameForLevel(clusterLevel))) {
        map.getCanvas().style.cursor = "";

        if (mapboxRoadIntersectionHoverPopupRef.current) {
          mapboxRoadIntersectionHoverPopupRef.current.remove();
        }

        map.setFeatureState(
          {
            source: hoveredItem.source,
            sourceLayer: hoveredItem.sourceLayer,
            id: hoveredItem.id,
          },
          { hover: false },
        );
        hoveredItem = null;
        suppressRoadVolumePopupAndStatesRef.current = false;
      }
    }
  };

  map.on("mousemove", allLayerIds, handleMouseMove);
  map.on("mouseleave", allLayerIds, handleMouseLeave);

  map.on("sourcedata", handleUpdateVolumesBySourceData);
  map.on("zoomend", handleUpdateVolumesByZoomEnd);
  map.on("dragstart", handleInteractionStart);
  map.on("dragend", handleInteractionEnd);
  map.on("movestart", handleInteractionStart);
  map.on("moveend", handleInteractionEnd);

  map.on("click", clusterLayerIds, handleClickForClusterLayer);
  map.on("click", ROAD_INTERSECTIONS_LAYER_ID, handleClickForBaseLayer);

  return {
    cleanRoadIntersectionHandlers: () => {
      map.off("mousemove", allLayerIds, handleMouseMove);
      map.off("mouseleave", allLayerIds, handleMouseLeave);

      map.off("click", clusterLayerIds, handleClickForClusterLayer);
      map.off("click", ROAD_INTERSECTIONS_LAYER_ID, handleClickForBaseLayer);

      map.off("sourcedata", handleUpdateVolumesBySourceData);
      map.off("zoomend", handleUpdateVolumesByZoomEnd);
      map.off("dragstart", handleInteractionStart);
      map.off("dragend", handleInteractionEnd);
      map.off("movestart", handleInteractionStart);
      map.off("moveend", handleInteractionEnd);
    },
    updateRoadsIntersectionVolumes,
  };
};
