import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { MapController } from "./MapController";

interface GatesModuleComponentProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  isModeLoaded: boolean;
  setGatesModuleData: Dispatch<SetStateAction<ModuleData | null>>;
}

export const GatesModuleComponent = ({ map, isModeLoaded, setGatesModuleData }: GatesModuleComponentProps) => {
  return (
    <>
      <MapController map={map} isModeLoaded={isModeLoaded} setGatesModuleData={setGatesModuleData} />
    </>
  );
};
