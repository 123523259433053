import { Add, Delete, MoreVert, Place, PushPin } from "@mui/icons-material";
import { MenuItem, Tooltip, styled } from "@mui/material";
import gateSegmentsPlaceholderSVG from "assets/svg/gate-segments-placeholder.svg";
import { Button, DirectionIcon, Divider, IconButton, IconButtonProps, Menu, TextField } from "components_new";
import { Geometry } from "geojson";
import uniq from "lodash/uniq";
import { LngLatLike, Map, MapLayerMouseEvent, Marker } from "mapbox-gl";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { EditorRoads, EditorRoadsHighlightStatus } from "features";

import { useHighlightGateSegments } from "features/dataset-editor";

import { FlexContainer } from "components";

import { useAppDispatch, useAppSelector, useDebounce } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions, selectSortedDraftDatasetGates } from "store/sections/analytics";

import {
  Gate,
  GateSegment,
  GateSegmentProps,
  ODDatasetConfig,
  RoadClass,
  SegmentDirection,
  SegmentIndexesForIdsMapSource,
} from "types";

import {
  findGate,
  findGateFromSegmentId,
  normalizeFromToSegment,
  normalizeToFromSegment,
  parseGateSegments,
  unParseSegment,
} from "./utils";

export const GEN_GATES_LAYER_ID = "generated-gates";
export const LOCKED_GEN_GATES_LAYER_ID = "generated-gates-locked";

export interface GatesEditorProps {
  map: Map | null;
  draw: any;
  mapLoaded: boolean;
  selectedRoadClasses: RoadClass[] | null;
  isRoadsSourceInitialized: boolean;
  setSegmentFeatureState: (
    id: number,
    state: {
      [key: string]: boolean;
    },
  ) => void;
  setIsGateEditorOpen: Dispatch<SetStateAction<boolean>>;
}

const Container = styled("div")`
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 40px auto 45px 320px;
`;

const TitlePanel = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const TitleBlock = styled("div")`
  display: flex;
  align-items: center;
  height: 30px;

  button {
    margin-left: 8px;
  }
`;

const SegmentsTitlePanel = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
`;

const EllipsisText = styled("div")`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const GateId = styled("div")`
  display: flex;
  align-items: center;
  margin: 5px 0;
  width: 60px;
  font-size: 13px;
  color: var(--color-textSecondary);
  max-height: 80px;
`;

const GateStreetName = styled(EllipsisText)`
  font-size: 14px;
  font-weight: 700;
  max-width: 225px;
  margin-bottom: 5px;
`;

const GateListPlaceHolder = styled("div")`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & h3 {
    margin-bottom: 0.5rem;
  }

  & p {
    font-size: 14px;
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const ManageGatePlaceHolder = styled("div")`
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  color: var(--color-textSecondary);
  border: 1px solid var(--color-gray-100);
  border-radius: 8px;
  background: var(--color-text-field-gray);

  & h3 {
    margin-bottom: 0.25rem;
  }

  & span {
    font-size: 13px;
  }
`;

const ManageGatePlaceHolderContainer = styled("div")`
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: fit-content(10px) auto;
`;

const ManageGatePlaceHolderSVG = styled("img")`
  margin-bottom: 1rem;
  width: 150px;
  align-self: center;
`;

const GateList = styled("ul")`
  min-height: 230px;
  height: auto;
  overflow-y: auto;
  border: 1px solid var(--color-gray-100);
  border-radius: 8px;
  background: var(--color-text-field-gray);
  padding: 5px;
  margin-bottom: 0.25rem;
`;

const GateListItem = styled("li", {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 10px 20px 20px;
  align-items: center;
  padding: 6px 4px;
  border-radius: 4px;
  background: ${({ isSelected }) => (isSelected ? "#ffffff" : "transparent")};

  & > .pin-gate-button {
    visibility: ${({ isSelected }) => (isSelected ? "visible" : "hidden")};
  }
`;

const GateName = styled(EllipsisText, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected?: boolean }>`
  color: ${({ isSelected }) => (isSelected ? "var(--color-primaryLight)" : "var(--color-textSecondary)")};
  font-size: 13px;
  font-weight: ${({ isSelected }) => (isSelected ? 700 : 500)};
  cursor: pointer;
`;

const GateDetailTools = styled("div")`
  width: 106px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SegmentHeader = styled("span")`
  font-size: 14px;
  font-weight: 600;
`;

const SegmentName = styled(EllipsisText)`
  font-size: 12px;
  font-weight: 500;
  max-width: 120px;
`;

const SegmentType = styled(EllipsisText)`
  font-size: 10px;
  font-weight: 400;
  color: var(--color-gray-500);
`;

const SegmentsList = styled("ul")`
  height: auto;
  overflow-y: auto;
  padding: 0.25rem 0;
`;

const SegmentItem = styled("li", { shouldForwardProp: (prop) => prop !== "isSelected" })<{ isSelected?: boolean }>`
  width: 100%;
  margin: 0.25rem 0;
  padding: 0.25rem 1rem;
  border: ${({ isSelected }) => (isSelected ? "1px solid var( --color-primary)" : "1px solid var(--color-gray-300)")};
  border-radius: 8px;
  box-shadow: var(--box-shadow-xs);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const SegmentDirectionContainer = styled("div")`
  min-width: 50px;
`;

const StyledIconButton = styled((props: IconButtonProps) => <IconButton size="small" {...props} />, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{
  selected?: boolean;
}>(({ selected, theme }) => ({
  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
  borderRadius: "4px",
  height: "30px",
  width: "30px",
  backgroundColor: selected ? theme.palette.primary.main : theme.palette.background.paper,
  color: selected ? theme.palette.primary.contrastText : theme.palette.primary.main,

  "&:hover": {
    backgroundColor: selected ? theme.palette.primary.main : "default",
    color: selected ? theme.palette.primary.contrastText : "default",
  },
}));

const DeleteButton = styled(StyledIconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 10px;
  height: 20px;
  cursor: pointer;
  color: #000000;
  border: none;
  transition: color 350ms ease;
  box-shadow: none;

  &:hover {
    background-color: transparent;
    color: var(--color-primaryLight);
  }
`;

const RegenerateGatesButton = styled(Button)`
  width: 100%;
  min-height: 32px;
  height: 32px;
  justify-self: flex-end;
  align-self: center;
`;

const PinGateButton = styled(IconButton)(({ theme }) => ({
  width: "20px",
  height: "20px",
  color: theme.palette.divider,
  rotate: "45deg",
  fontSize: "14px",

  "&.pinned": {
    visibility: "visible !important",
    rotate: "0deg",
    color: theme.palette.primary.main,
  },
}));

const ManageGatesContainer = styled("div")`
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  row-gap: 8px;
  grid-template-rows:
    fit-content(10px) fit-content(40px) fit-content(40px) 45px fit-content(10px)
    fit-content(40px) fit-content(140px);
`;

const ADD_MODE_INIT_STATE = {
  gate: false,
  segments: false,
};

const GatesEditorComponent: FC<GatesEditorProps> = ({
  map,
  draw,
  mapLoaded,
  selectedRoadClasses,
  isRoadsSourceInitialized,
  setSegmentFeatureState,
  setIsGateEditorOpen,
}) => {
  const dispatch = useAppDispatch();

  const [gateDescription, setGateDescription] = useState("");

  const hoveredGateRef = useRef<Gate | null>(null);
  const selectedGateRef = useRef<Gate | null>(null);
  const gateMarker = useRef(new Marker({ scale: 0.8, offset: [0, -22], color: "purple" }));
  const gatesLayerEventsFirstLoaded = useRef(false);

  const roadClasses = useAppSelector((state) => state.analytics.roadsMetadata.data?.roadClasses);

  //--- ODDatasetConfiguration ---
  const ODDatasetConfiguration = useAppSelector((state) => state.analytics.ODDatasetConfig);
  const ODDatasetConfigurationDataRef = useRef<ODDatasetConfig | null>(null);

  useEffect(() => {
    ODDatasetConfigurationDataRef.current = ODDatasetConfiguration.data;
  }, [ODDatasetConfiguration.data]);
  //------------------------------

  const roadsTileService = useAppSelector((state) => state.analytics.roadsMetadata.data?.tileService); // TODO 1583553 - always use vehicular tile service here
  const segmentsIdToIdxMapData = useAppSelector((state) => state.analytics.datasetSegmentsIdToIdxMap.data);
  const segmentsIdToIdxMapDataRef = useRef<typeof segmentsIdToIdxMapData>(segmentsIdToIdxMapData);

  const subareaState = useAppSelector((state) => state.analytics.subareaState);

  const datasetGates = useAppSelector(selectSortedDraftDatasetGates);
  const generatedGatesDataRef = useRef<Gate[] | null>(null);

  const allGatesSegmentsIds = useMemo(() => {
    const segmentsIds: { [key: string]: boolean } = {};
    ODDatasetConfiguration.data?.gates?.forEach((gate) => {
      gate.segments?.forEach((s) => (segmentsIds[s.id] = true));
    });
    return segmentsIds;
  }, [ODDatasetConfiguration.data?.gates]);

  const allGatesSegmentsIdsRef = useRef<{ [key: string]: boolean } | null>(null);

  const newGate = useAppSelector((state) => state.analytics.newGate);
  const loadingGates = useAppSelector((state) => state.analytics.loadingGeneratedGates);

  const [selectedGateId, setSelectedGateId] = useState<string | null>(null);
  const [selectedSegment, setSelectedSegment] = useState<GateSegment | null>(null);

  const [addMode, setAddMode] = useState(ADD_MODE_INIT_STATE);
  const addModeRef = useRef<"gate" | "segments" | null>(null);

  const gateItemsRefs = useMemo(
    () =>
      ODDatasetConfiguration.data?.gates?.reduce((gates: { [key: string]: React.RefObject<any> }, gate) => {
        gates[gate.identifier] = React.createRef();
        return gates;
      }, {}) || {},
    [ODDatasetConfiguration.data?.gates],
  );

  //---Polygon Ref---
  const polygonRef = useRef<Geometry | null>(null);
  useEffect(() => {
    polygonRef.current = ODDatasetConfiguration.data?.subAreaGeometry || null;
  }, [ODDatasetConfiguration.data?.subAreaGeometry]);
  //------------------

  useEffect(() => {
    if (ODDatasetConfiguration.data?.gates && selectedGateId !== null) {
      setGateDescription(findGate(selectedGateId, ODDatasetConfiguration.data.gates)?.description || "");
    }
  }, [ODDatasetConfiguration.data?.gates, selectedGateId]);

  // Update segmentsIdToIdxMapDataRef with the latest segmentsIdToIdxMapData
  useEffect(() => {
    segmentsIdToIdxMapDataRef.current = segmentsIdToIdxMapData;
  }, [segmentsIdToIdxMapData]);

  const selectedGate = useMemo(
    () =>
      selectedGateId !== null && ODDatasetConfiguration.data?.gates
        ? findGate(selectedGateId, ODDatasetConfiguration.data.gates)
        : null,
    [selectedGateId, ODDatasetConfiguration.data?.gates],
  );

  const selectedGateParsedSegments = useMemo(() => parseGateSegments(selectedGate?.segments), [selectedGate?.segments]);

  const isRoadsSourceLoaded = map?.getSource("roads");

  const setGateSegmentsFeatureState = useCallback(
    (segments: GateSegment[], state: { [key: string]: boolean }) => {
      segments.forEach((segment) => {
        const idx = segmentsIdToIdxMapDataRef.current?.get(segment.id);

        if (idx) {
          setSegmentFeatureState(idx, state);
        }
      });
    },
    [setSegmentFeatureState, segmentsIdToIdxMapDataRef],
  );

  useHighlightGateSegments(
    selectedSegment,
    selectedGate?.segments || null,
    segmentsIdToIdxMapData,
    setSegmentFeatureState,
    setGateSegmentsFeatureState,
  );

  const handleZoomOnGate = useCallback(
    (lngLat: LngLatLike, zoom: number = 14) => {
      if (map) {
        map.flyTo({
          center: lngLat,
          zoom,
        });
      }
    },
    [map],
  );

  const handleSelectGate = useCallback(
    (gateId: string | null, coords?: LngLatLike) => {
      setSelectedGateId(gateId);
      setSelectedSegment(null);
      if (gateId) {
        setIsGateEditorOpen(true);
        if (coords) handleZoomOnGate(coords, map?.getZoom());
      }

      if (draw.getMode() === "draw_line_string") draw.changeMode("simple_select");
    },
    [map, draw, setIsGateEditorOpen, handleZoomOnGate],
  );

  const handleGenerateGates = () => {
    if (
      ODDatasetConfiguration.data?.subAreaGeometry &&
      ODDatasetConfiguration.data?.timePeriod &&
      subareaState.state === DataState.AVAILABLE &&
      ODDatasetConfiguration.state === DataState.AVAILABLE &&
      selectedRoadClasses
    ) {
      dispatch(
        analyticsActions.fetchGeneratedGates({
          polygon: ODDatasetConfiguration.data?.subAreaGeometry,
          timePeriod: ODDatasetConfiguration.data?.timePeriod,
          gateRoadClasses: selectedRoadClasses.map((rc) => rc.id),
          existingGates: ODDatasetConfiguration.data?.gates?.filter((gate) => gate.pinned) || [],
        }),
      );
    }
  };

  const handleToggleLockGate = (gateId: string) => {
    dispatch(analyticsActions.toggleLockGate(gateId));
  };

  const debouncedGateDescriptionUpdate = useDebounce(() => {
    if (selectedGateId) {
      dispatch(analyticsActions.updateGateDescription(selectedGateId, gateDescription));
    }
  }, 500);

  const handleDescriptionUpdate = (gateId: string, description: string) => {
    setGateDescription(description);
    debouncedGateDescriptionUpdate();
  };

  const handleHoverOnGate = useCallback(
    (e: MapLayerMouseEvent) => {
      const feature = e.features?.[0];
      if (map && feature?.id && generatedGatesDataRef.current) {
        map.getCanvas().style.cursor = "default";

        const gate = findGate(feature.id, generatedGatesDataRef.current);

        if (hoveredGateRef.current) {
          map.setFeatureState(
            {
              source: GEN_GATES_LAYER_ID,
              id: hoveredGateRef.current.identifier,
            },
            { hover: false },
          );
          setGateSegmentsFeatureState(hoveredGateRef.current.segments, {
            [EditorRoadsHighlightStatus.GateHover]: false,
          });
        }

        if (gate) {
          map.setFeatureState(
            {
              source: GEN_GATES_LAYER_ID,
              id: gate.identifier,
            },
            { hover: true },
          );
          setGateSegmentsFeatureState(gate.segments, {
            [EditorRoadsHighlightStatus.GateHover]: true,
          });
        }

        hoveredGateRef.current = gate;
      }
    },
    [map, setGateSegmentsFeatureState],
  );

  const handleHoverOffGate = useCallback(() => {
    if (map && hoveredGateRef.current) {
      map.getCanvas().style.cursor = "";

      map.setFeatureState(
        {
          source: GEN_GATES_LAYER_ID,
          id: hoveredGateRef.current.identifier,
        },
        { hover: false },
      );
      setGateSegmentsFeatureState(hoveredGateRef.current.segments, {
        [EditorRoadsHighlightStatus.GateHover]: false,
      });
      hoveredGateRef.current = null;
    }
  }, [map, setGateSegmentsFeatureState]);

  const handleClick = useCallback(
    (e: MapLayerMouseEvent) => {
      if (map) {
        const features = map.queryRenderedFeatures(e.point, {
          layers: [GEN_GATES_LAYER_ID],
        });
        const feature = features?.[0];
        const featureId = feature?.id as string;
        if (featureId) {
          handleSelectGate(featureId);
        } else if (addModeRef.current !== "segments") {
          handleSelectGate(null);
        }
      }
    },
    [map, handleSelectGate],
  );

  const handleDoubleClickOnGate = useCallback(
    (e: MapLayerMouseEvent) => {
      handleZoomOnGate(e.lngLat);
    },
    [handleZoomOnGate],
  );

  const toggleAddMode = (mode: "gate" | "segments") => {
    if (map) {
      if (addMode[mode]) {
        draw.changeMode("simple_select");
        map.off("draw.create", handleAddGate);
        removeHighlightedSegmentsLayerEvents();
        addGatesLayerEvents();
        setAddMode({ ...addMode, [mode]: false });
      } else {
        if (mode === "gate") {
          map.on("draw.create", handleAddGate);
          draw.changeMode("draw_line_string");
          removeGatesLayerEvents();
          removeHighlightedSegmentsLayerEvents();
        }
        if (mode === "segments") {
          addHighlightedSegmentsLayerEvents();
          addGatesLayerEvents();
        }
        setAddMode({ ...ADD_MODE_INIT_STATE, [mode]: true });
      }
    }
  };

  const addGatesLayerEvents = useCallback(() => {
    if (map) {
      map.on("mousemove", GEN_GATES_LAYER_ID, handleHoverOnGate);
      map.on("mouseleave", GEN_GATES_LAYER_ID, handleHoverOffGate);
      map.on("click", handleClick);
      map.on("dblclick", GEN_GATES_LAYER_ID, handleDoubleClickOnGate);
    }
  }, [map, handleHoverOnGate, handleHoverOffGate, handleClick, handleDoubleClickOnGate]);

  const removeGatesLayerEvents = useCallback(() => {
    if (map) {
      map.off("mousemove", GEN_GATES_LAYER_ID, handleHoverOnGate);
      map.off("mouseleave", GEN_GATES_LAYER_ID, handleHoverOffGate);
      map.off("click", handleClick);
      map.off("dblclick", GEN_GATES_LAYER_ID, handleDoubleClickOnGate);
    }
  }, [map, handleHoverOnGate, handleHoverOffGate, handleClick, handleDoubleClickOnGate]);

  const handleAddGate = useCallback(
    (e: MapLayerMouseEvent) => {
      const feature = e.features?.[0];
      if (
        feature &&
        feature.geometry.type === "LineString" &&
        addModeRef.current === "gate" &&
        ODDatasetConfigurationDataRef.current?.timePeriod
      ) {
        dispatch(
          analyticsActions.fetchAddedGate({
            intersectingLine: feature.geometry,
            timePeriod: ODDatasetConfigurationDataRef.current?.timePeriod,
            gateRoadClasses: ODDatasetConfigurationDataRef.current?.gateRoadClasses || [],
            existingGates: generatedGatesDataRef.current || [],
            includeUnchanged: true,
            polygon: ODDatasetConfigurationDataRef.current?.subAreaGeometry || null,
          }),
        );

        draw.delete(feature.id);
        setAddMode({
          gate: false,
          segments: false,
        });
        addGatesLayerEvents();
      }
    },
    [draw, addGatesLayerEvents, dispatch],
  );

  const handleAddSegments = useCallback(
    (segments: GateSegmentProps[]) => {
      if (selectedGateRef.current && ODDatasetConfigurationDataRef.current?.timePeriod) {
        dispatch(
          analyticsActions.addGateSegments(selectedGateRef.current.identifier, segments, {
            segments: [...selectedGateRef.current.segments, ...segments],
            polygon: polygonRef.current,
            timePeriod: ODDatasetConfigurationDataRef.current?.timePeriod,
          }),
        );
      }
    },
    [dispatch],
  );

  const handleDeleteSegments = useCallback(
    (segments: GateSegmentProps[] | GateSegment[]) => {
      if (selectedGateRef.current && ODDatasetConfigurationDataRef.current?.timePeriod) {
        const ids = segments.map((s) => s.id);
        const firstSegmentIdx = segmentsIdToIdxMapDataRef.current?.get(segments[0].id);

        if (firstSegmentIdx) {
          setSegmentFeatureState(firstSegmentIdx, {});
        }

        dispatch(
          analyticsActions.deleteGateSegments(selectedGateRef.current.identifier, ids, {
            segments: selectedGateRef.current.segments.filter((s) => !ids.includes(s.id)),
            polygon: polygonRef.current,
            timePeriod: ODDatasetConfigurationDataRef.current?.timePeriod,
          }),
        );
      }
    },
    [segmentsIdToIdxMapDataRef, setSegmentFeatureState, dispatch],
  );

  const hoveredSegmentIdx = useRef<number | null>(null);

  const handleHoverOnSegment = useCallback(
    (e: MapLayerMouseEvent) => {
      if (hoveredSegmentIdx.current) {
        setSegmentFeatureState(hoveredSegmentIdx.current, {
          [EditorRoadsHighlightStatus.HoverAdd]: false,
          [EditorRoadsHighlightStatus.HoverDelete]: false,
        });
      }

      if (!roadsTileService) return;

      const feature = e.features?.[0];
      const featureId = feature?.properties?.[roadsTileService.fromToSegmentIdField];
      const featureIdx = feature?.properties?.[roadsTileService.fromToSegmentIndexField];

      dispatch(analyticsActions.addSegmentIdToIdxMap(featureId, featureIdx, SegmentIndexesForIdsMapSource.DATASET));

      if (map && featureIdx) {
        map.getCanvas().style.cursor = "default";
        if (
          allGatesSegmentsIdsRef.current?.[featureId] &&
          selectedGateRef.current?.segments.find((s) => s.id === featureId)
        ) {
          setSegmentFeatureState(featureIdx, {
            [EditorRoadsHighlightStatus.HoverDelete]: true,
          });
        } else if (!allGatesSegmentsIdsRef.current?.[featureId]) {
          setSegmentFeatureState(featureIdx, {
            [EditorRoadsHighlightStatus.HoverAdd]: true,
          });
        }
        hoveredSegmentIdx.current = featureIdx;
      }
    },
    [map, roadsTileService, setSegmentFeatureState, dispatch],
  );

  const handleHoverOffSegment = useCallback(() => {
    if (map && hoveredSegmentIdx.current) {
      map.getCanvas().style.cursor = "";

      setSegmentFeatureState(hoveredSegmentIdx.current, {
        [EditorRoadsHighlightStatus.HoverAdd]: false,
        [EditorRoadsHighlightStatus.HoverDelete]: false,
      });
    }
  }, [map, setSegmentFeatureState]);

  const handleClickOnSegment = useCallback(
    (e: MapLayerMouseEvent) => {
      if (map) {
        const feature = e.features?.[0];
        if (feature && roadsTileService) {
          const ftSegment = normalizeFromToSegment(feature.properties, roadsTileService);
          const tfSegment = normalizeToFromSegment(feature.properties, roadsTileService);

          dispatch(
            analyticsActions.addSegmentIdToIdxMap(
              ftSegment.id,
              feature.properties?.[roadsTileService.fromToSegmentIndexField],
              SegmentIndexesForIdsMapSource.DATASET,
            ),
          );

          const segments = [ftSegment, ...(feature.properties?.seg_pair ? [tfSegment] : [])];

          if (addModeRef.current === "segments" && allGatesSegmentsIdsRef.current && selectedGateRef.current) {
            const isSegmentTaken = segments.find((s) => allGatesSegmentsIdsRef.current?.[s.id]);
            const segmentOwnerGate = findGateFromSegmentId(ftSegment.id, generatedGatesDataRef.current || []);
            const gateOwnesSegment = segmentOwnerGate?.identifier === selectedGateRef.current.identifier;

            if (!isSegmentTaken) {
              handleAddSegments(segments);
            } else if (gateOwnesSegment && parseGateSegments(segmentOwnerGate.segments).length > 1) {
              handleDeleteSegments(segments);
            }
          }
        }
      }
    },
    [map, roadsTileService, handleAddSegments, handleDeleteSegments, dispatch],
  );

  const addHighlightedSegmentsLayerEvents = useCallback(() => {
    if (map) {
      map.on("click", EditorRoads.HighlightedSegmentsLayerId, handleClickOnSegment);
      map.on("mousemove", EditorRoads.HighlightedSegmentsLayerId, handleHoverOnSegment);
      map.on("mouseleave", EditorRoads.HighlightedSegmentsLayerId, handleHoverOffSegment);
    }
  }, [map, handleClickOnSegment, handleHoverOnSegment, handleHoverOffSegment]);

  const removeHighlightedSegmentsLayerEvents = useCallback(() => {
    if (map) {
      map.off("click", EditorRoads.HighlightedSegmentsLayerId, handleClickOnSegment);
      map.off("mousemove", EditorRoads.HighlightedSegmentsLayerId, handleHoverOnSegment);
      map.off("mouseleave", EditorRoads.HighlightedSegmentsLayerId, handleHoverOffSegment);
    }
  }, [map, handleClickOnSegment, handleHoverOnSegment, handleHoverOffSegment]);

  //Cleanup
  useEffect(() => {
    return () => {
      dispatch(analyticsActions.clearNewGate());
    };
  }, [dispatch]);

  // Keeping updated generatedGatesDataRef with current state. The ref is used to pass the generated gates to the event listener.
  useEffect(() => {
    if (isRoadsSourceLoaded) {
      generatedGatesDataRef.current?.forEach((gate) => {
        gate.segments?.forEach((s) => {
          const segmentIdx = segmentsIdToIdxMapDataRef.current?.get(s.id);

          if (segmentIdx) {
            setSegmentFeatureState(segmentIdx, {
              [EditorRoadsHighlightStatus.AllGateSegments]: false,
            });
          }
        });
      });
      ODDatasetConfiguration.data?.gates?.forEach((gate) => {
        gate.segments?.forEach((s) => {
          const segmentIdx = segmentsIdToIdxMapDataRef.current?.get(s.id);

          if (segmentIdx) {
            setSegmentFeatureState(segmentIdx, {
              [EditorRoadsHighlightStatus.AllGateSegments]: true,
            });
          }
        });
      });

      generatedGatesDataRef.current = ODDatasetConfiguration.data?.gates || null;
      setSelectedSegment(null);
    }
  }, [isRoadsSourceLoaded, segmentsIdToIdxMapDataRef, ODDatasetConfiguration.data?.gates, setSegmentFeatureState]);

  useEffect(() => {
    if (isRoadsSourceLoaded) {
      const allLockedGates =
        ODDatasetConfiguration.data?.gates?.filter((gate) => gate.pinned).map((gate) => gate.identifier) || [];

      map?.setFilter(LOCKED_GEN_GATES_LAYER_ID, ["in", "identifier", ...allLockedGates]);
    }
  }, [map, isRoadsSourceLoaded, ODDatasetConfiguration.data?.gates]);

  useEffect(() => {
    if (newGate.data) setSelectedGateId(newGate.data.identifier);
  }, [newGate]);

  useEffect(() => {
    addModeRef.current = addMode.gate ? "gate" : addMode.segments ? "segments" : null;
  }, [addMode]);

  useEffect(() => {
    if (selectedGateRef.current) {
      map?.setFeatureState(
        {
          source: GEN_GATES_LAYER_ID,
          id: selectedGateRef.current.identifier,
        },
        {
          selected: false,
        },
      );
    }

    if (selectedGate) {
      map?.setFeatureState(
        {
          source: GEN_GATES_LAYER_ID,
          id: selectedGate.identifier,
        },
        {
          selected: true,
        },
      );
    }

    selectedGateRef.current = selectedGate;
  }, [map, selectedGate]);

  useEffect(() => {
    if (
      selectedGate?.identifier &&
      gateItemsRefs[selectedGate.identifier] &&
      gateItemsRefs[selectedGate.identifier].current
    ) {
      gateItemsRefs[selectedGate.identifier].current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selectedGate, gateItemsRefs]);

  useEffect(() => {
    allGatesSegmentsIdsRef.current = allGatesSegmentsIds;
  }, [allGatesSegmentsIds]);

  useEffect(() => {
    gateMarker.current.remove();
    if (map && selectedGate) {
      gateMarker.current.setLngLat([selectedGate.lon, selectedGate.lat]).addTo(map);
    }
  }, [map, selectedGate]);

  useLayoutEffect(() => {
    if (map && mapLoaded && isRoadsSourceInitialized && ODDatasetConfiguration.state === DataState.AVAILABLE) {
      if (map.getLayer(LOCKED_GEN_GATES_LAYER_ID)) {
        map.removeLayer(LOCKED_GEN_GATES_LAYER_ID);
      }
      if (map.getLayer(GEN_GATES_LAYER_ID)) {
        map.removeLayer(GEN_GATES_LAYER_ID);
        map.removeSource(GEN_GATES_LAYER_ID);
      }

      map.addSource(GEN_GATES_LAYER_ID, {
        type: "geojson",
        promoteId: "identifier",
        data: {
          type: "FeatureCollection",
          features:
            ODDatasetConfiguration.data?.gates?.map((gate) => ({
              type: "Feature",
              properties: {
                identifier: gate.identifier,
                lat: gate.lat,
                lon: gate.lon,
              },
              geometry: {
                type: "Point",
                coordinates: [gate.lon, gate.lat],
              },
            })) || [],
        },
      });

      map.addLayer({
        id: GEN_GATES_LAYER_ID,
        type: "circle",
        source: GEN_GATES_LAYER_ID,
        paint: {
          "circle-color": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            "#1e40af",
            ["boolean", ["feature-state", "selected"], false],
            "purple",
            "#139eec",
          ],
          "circle-radius": ["interpolate", ["exponential", 1.6], ["zoom"], 6, 4, 10, 7, 16, 8, 20, 10],
          "circle-stroke-width": ["case", ["boolean", ["feature-state", "selected"], false], 3, 2],
          "circle-stroke-color": "#ffffff",
        },
      });

      map.addLayer({
        id: LOCKED_GEN_GATES_LAYER_ID,
        type: "symbol",
        source: GEN_GATES_LAYER_ID,
        layout: {
          "icon-image": "pin",
          "icon-size": 0.8,
          "icon-allow-overlap": true,
          "icon-offset": [14, -14],
        },
        filter: ["in", "identifier", ""],
      });

      if (!gatesLayerEventsFirstLoaded.current) {
        addGatesLayerEvents();
        gatesLayerEventsFirstLoaded.current = true;
      }
    }
  }, [
    map,
    mapLoaded,
    isRoadsSourceInitialized,
    ODDatasetConfiguration.state,
    ODDatasetConfiguration.data?.gates,
    addGatesLayerEvents,
    setSegmentFeatureState,
  ]);

  const getGateTitle = (gate: Gate | null) =>
    gate
      ? `${
          uniq(
            gate.segments
              ?.map((segment) => segment.name)
              .filter((v) => v)
              .sort(),
          ).join(", ") || "(no street name)"
        }`
      : "";

  const handleDeleteAllGates = () => {
    dispatch(analyticsActions.deleteAllGates());
  };

  return (
    <Container>
      <TitlePanel>
        <TitleBlock>
          <h3>Gates</h3>
        </TitleBlock>
        <TitleBlock>
          <Tooltip
            placement="left"
            title="Add or redefine Gate by drawing line across road segments (finish line with double-click)"
          >
            <span>
              <StyledIconButton selected={addMode.gate} onClick={() => toggleAddMode("gate")}>
                <Add fontSize="inherit" />
              </StyledIconButton>
            </span>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title="Delete all gates"
            disableHoverListener={ODDatasetConfiguration.data?.gates?.filter((gate) => !gate?.pinned)?.length === 0}
          >
            <span>
              <Button
                variant="outlined"
                disabled={!ODDatasetConfiguration.data?.gates?.length}
                onClick={handleDeleteAllGates}
              >
                Delete All
              </Button>
            </span>
          </Tooltip>
        </TitleBlock>
      </TitlePanel>
      <GateList>
        {datasetGates.length ? (
          datasetGates.map((gate, i) => (
            <GateListItem
              key={gate.identifier}
              ref={gateItemsRefs[gate.identifier]}
              isSelected={selectedGate?.identifier === gate.identifier}
              onClick={() => handleSelectGate(gate.identifier, [gate.lon, gate.lat])}
              onDoubleClick={() => handleZoomOnGate([gate.lon, gate.lat])}
            >
              <GateName isSelected={selectedGate?.identifier === gate.identifier}>
                {`${gate.identifier}. ${getGateTitle(gate)}`}
              </GateName>
              <div />
              <PinGateButton
                className={`pin-gate-button ${gate.pinned ? " pinned" : ""}`}
                onClick={() => handleToggleLockGate(gate.identifier)}
              >
                <PushPin fontSize="inherit" />
              </PinGateButton>
              <Menu
                control={(handleOpen) => (
                  <IconButton onClick={handleOpen}>
                    <MoreVert fontSize="inherit" />
                  </IconButton>
                )}
              >
                <MenuItem onClick={() => dispatch(analyticsActions.deleteGate(gate.identifier))}>Delete</MenuItem>
              </Menu>
            </GateListItem>
          ))
        ) : (
          <GateListPlaceHolder>
            <h3>Defining Gates</h3>
            <p>Gates capture trips that exit or enter the sub-area via selected roads.</p>

            <p>
              You can <strong>generate</strong> gates for selected road classes that cross the sub-area, and{" "}
              <strong>add</strong>, <strong>modify</strong> and <strong>remove</strong> individual gates.
            </p>

            <p>
              Gates can be <strong>pinned</strong> to keep them when re-generating gates, e.g. after changing the
              sub-area.
            </p>
          </GateListPlaceHolder>
        )}
      </GateList>
      <RegenerateGatesButton
        variant="outlined"
        onClick={handleGenerateGates}
        loading={loadingGates}
        disabled={subareaState.state === DataState.EMPTY || !ODDatasetConfiguration.data?.subAreaGeometry}
      >
        {ODDatasetConfiguration.data?.gates?.length ? "Regenerate Gates" : "Generate Gates"}
      </RegenerateGatesButton>

      <>
        {selectedGate ? (
          <ManageGatesContainer>
            <Divider />
            <TitlePanel>
              <GateStreetName>{getGateTitle(selectedGate)}</GateStreetName>
            </TitlePanel>
            <TitlePanel>
              <GateId>{selectedGate?.identifier ? `Gate ${selectedGate.identifier}` : ""}</GateId>
              <GateDetailTools>
                <Tooltip placement="bottom" title="Zoom to gate" disableHoverListener={!selectedGate}>
                  <span>
                    <StyledIconButton
                      disabled={!selectedGate}
                      onClick={() =>
                        selectedGate ? handleZoomOnGate([selectedGate.lon, selectedGate.lat]) : undefined
                      }
                    >
                      <Place fontSize="inherit" />
                    </StyledIconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title={selectedGate.pinned ? "Unpin gate" : "Pin gate"}
                  disableHoverListener={!selectedGate}
                >
                  <span>
                    <StyledIconButton
                      disabled={!selectedGate}
                      onClick={() => handleToggleLockGate(selectedGate?.identifier as string)}
                    >
                      <PushPin
                        fontSize="inherit"
                        sx={{
                          rotate: selectedGate.pinned ? "0deg" : "45deg",
                          color: selectedGate.pinned ? "primary" : "divider",
                        }}
                      />
                    </StyledIconButton>
                  </span>
                </Tooltip>
                <Tooltip placement="bottom" title="Delete gate" disableHoverListener={!selectedGate}>
                  <span>
                    <StyledIconButton
                      disabled={!selectedGate}
                      onClick={() =>
                        selectedGate?.identifier
                          ? dispatch(analyticsActions.deleteGate(selectedGate.identifier))
                          : undefined
                      }
                    >
                      <Delete fontSize="inherit" />
                    </StyledIconButton>
                  </span>
                </Tooltip>
              </GateDetailTools>
            </TitlePanel>
            <TextField
              fullWidth
              multiline
              placeholder="Gate description..."
              rows={1}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                selectedGate ? handleDescriptionUpdate(selectedGate.identifier, e.target.value) : undefined
              }
              value={gateDescription || ""}
            />
            <Divider />
            <SegmentsTitlePanel>
              <div>
                <SegmentHeader>{`Road segments (${selectedGate?.segments?.length || 0})`}</SegmentHeader>
              </div>
              <Tooltip placement="bottom" title="Add or remove segments" disableHoverListener={!selectedGate}>
                <span>
                  <StyledIconButton
                    selected={addMode.segments}
                    disabled={!selectedGate}
                    onClick={() => toggleAddMode("segments")}
                  >
                    <Add fontSize="inherit" />
                  </StyledIconButton>
                </span>
              </Tooltip>
            </SegmentsTitlePanel>
            <SegmentsList>
              {selectedGateParsedSegments.map((segment) => (
                <SegmentItem
                  key={segment.id}
                  isSelected={selectedSegment?.id === segment.id}
                  onClick={() =>
                    setSelectedSegment((selectedSegment) => (selectedSegment?.id === segment.id ? null : segment))
                  }
                >
                  <FlexContainer>
                    <SegmentDirectionContainer>
                      <DirectionIcon direction={segment.direction as SegmentDirection} fontSize="small" />
                      <DirectionIcon direction={segment.toFromSeg?.direction as SegmentDirection} fontSize="small" />
                    </SegmentDirectionContainer>
                    <div>
                      <SegmentName>{segment.name}</SegmentName>
                      <SegmentType>{roadClasses?.find((rc) => rc.id === segment.roadClass)?.label}</SegmentType>
                    </div>
                  </FlexContainer>
                  {selectedGateParsedSegments.length > 1 && (
                    <DeleteButton onClick={() => handleDeleteSegments(unParseSegment(segment))}>
                      <Delete fontSize="inherit" />
                    </DeleteButton>
                  )}
                </SegmentItem>
              ))}
            </SegmentsList>
          </ManageGatesContainer>
        ) : ODDatasetConfiguration.data?.gates?.length ? (
          <ManageGatePlaceHolderContainer>
            <Divider style={{ marginBottom: "0.5rem" }} />
            <ManageGatePlaceHolder>
              <ManageGatePlaceHolderSVG src={gateSegmentsPlaceholderSVG} alt="Gate Segments Placeholder" />
              <h3>No gate selected</h3>
              <span>
                <strong>Select</strong> a gate on the map or in the Gates list.
              </span>
            </ManageGatePlaceHolder>
          </ManageGatePlaceHolderContainer>
        ) : null}
      </>
    </Container>
  );
};

export const GatesEditor = React.memo(GatesEditorComponent);
