export const screenlineShapeFileInfo = (
  <div>
    {" "}
    Screenlines with aggregated volumes for the selected volume metric (vehicles or pedestrians). Vehicle volumes are
    provided per crossing direction (towards the left or right side along the screenline geometry), using the prefixes
    "L_" and "R_", respectively for the "VOL..." fields. Pedestrian volumes are not differentiated by direction along a
    road, so a single volume value is provided per category.{" "}
  </div>
);

export const screenlineShapefileColumns = [
  {
    column: "id",
    type: "Text",
    description: "User-defined unique identifier for the screenline",
  },
  {
    column: "name",
    type: "Text",
    description: "User-defined name for the screenline",
  },
  {
    column: "desc",
    type: "Text",
    description: "User-defined description for the screenline",
  },
  {
    column: "directions",
    type: "Text",
    description:
      "Left only/Right only: only the volumes of segment intersections where the segment crosses to the left/right of the screenline are included in the totals. Both: segment volumes are included regardless of the crossing direction at intersection points.",
  },
  {
    column: "L_label/R_label",
    type: "Text",
    description:
      'Customizable labels for the crossing directions of segments at intersections (e.g. "West-bound" for linear screenlines, or "inbound" for cordons)',
  },
  {
    column: "VOLDT1, VOLDT2, VOLDT3, VOLDT4",
    type: "Text",
    description: "Volume by day type DT1: Mon-Thu, DT2: Fri, DT3: Sat, DT4: Sun",
  },
  {
    column: "VOLH00-VOLH23",
    type: "Integer",
    description: "Volume by hour of day",
  },
  {
    column: "VOLHW, VOLWH, VOLHO, VOLWO, VOLOW, VOLNHB",
    type: "Integer",
    description: "Volume by detailed trip purpose H: Home, W: Work, O:Other, NHB: Non home based",
  },
  {
    column: "VOLHBW, VOLHBO, VOLNHB",
    type: "Integer",
    description: "Volume by main purpose",
  },
];
