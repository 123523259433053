import { createSelector } from "@reduxjs/toolkit";
import { produce } from "immer";
import { ToastContent, ToastOptions, TypeOptions as ToastSeverity } from "react-toastify";
import { Reducer } from "redux";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { RootState } from "store";

import { FocusAreaItem, MapLayerContainerId, ROUTES } from "types";

import { Action, ActionsUnion, createAction } from "../actionHelpers";
import {
  AnalyticsActionType,
  CorridorActionType,
  DatasetFoldersActionType,
  FiltersActionType,
  GlobalActionType,
  MapActionType,
  RoadIntersectionsActionType,
  RoadVmtActionType,
  ScreenlinesActionType,
} from "./actionTypes";
import { fetchFocusAreasAndDatasets } from "./analytics";
import { selectIsEditScreenlineMode, selectScreenlinesPendingChangesPromptMessage } from "./screenlines";

export interface GlobalState {
  selectedFocusAreaId: string | null;
  selectedFocusArea: FocusAreaItem | null;
  timePeriod: string | null;
  searchParams: { key: string; value: string }[] | null;
  toastMessage: ToastMessage | null;
  redirectUrl: string | null;

  // Definig two separate lists of collapsed containers, in order to avoid unecessary re-render when toggling state
  //
  // List of the string ids of the collapsed map layer containers
  collapsedMapLayerContainers: MapLayerContainerId[];
  // List of the string ids of the collapsed dimension containers
  collapsedDimensionContainers: string[];
  // List of the string ids of the collapsed roadclasses containers
  collapsedRoadclassContainers: string[];
}

interface SetSelectedFocusAreaIdPayload {
  focusAreaId: string;
  timePeriod?: string;
  redirectUrl?: string;
  reset?: boolean;
}

interface ToastMessage {
  content: ToastContent;
  severity: ToastSeverity;
  options?: Omit<ToastOptions, "type">;
}

const initialState: GlobalState = {
  selectedFocusAreaId: null,
  selectedFocusArea: null,
  timePeriod: null,
  searchParams: null,
  toastMessage: null,
  redirectUrl: null,
  collapsedMapLayerContainers: [],
  collapsedDimensionContainers: [],
  collapsedRoadclassContainers: [],
};

export type GlobalAction = ActionsUnion<typeof globalActions>;

export const globalActions = {
  fullReset: () => createAction(GlobalActionType.FULL_RESET),

  //Focus area id actions
  setSelectedFocusAreaId: (payload: SetSelectedFocusAreaIdPayload) =>
    createAction(GlobalActionType.SET_SELECTED_FOCUS_AREA_ID, payload),
  setSelectedFocusAreaIdSucceeded: (payload: string) =>
    createAction(GlobalActionType.SET_SELECTED_FOCUS_AREA_ID_SUCCEEDED, payload),

  //Focus area actions
  setSelectedFocusArea: (payload: FocusAreaItem | null) =>
    createAction(GlobalActionType.SET_SELECTED_FOCUS_AREA, payload),

  //Time period actions
  setTimePeriod: (payload: string) => createAction(GlobalActionType.SET_TIME_PERIOD, payload),
  setTimePeriodSucceeded: (payload: string) => createAction(GlobalActionType.SET_TIME_PERIOD_SUCCEEDED, payload),

  //Toast message actions
  setToastMessage: (payload: ToastMessage | null) => createAction(GlobalActionType.SET_TOAST_MESSAGE, payload),

  //Redirect url actions
  setRedirectUrl: (url: string | null) => createAction(GlobalActionType.SET_REDIRECT_URL, url),

  //Collapsed containers actions
  toggleLayerContainerCollapsedState: (layerContainerId: MapLayerContainerId) =>
    createAction(GlobalActionType.TOGGLE_LAYER_CONTAINER_COLLAPSED_STATE, layerContainerId),
  toggleDimensionContainerCollapsedState: (dimensionId: string) =>
    createAction(GlobalActionType.TOGGLE_DIMENSION_CONTAINER_COLLAPSED_STATE, dimensionId),
  toggleRoadclassesContainerCollapsedState: (roadclassesId: string) =>
    createAction(GlobalActionType.TOGGLE_ROADCLASS_CONTAINER_COLLAPSED_STATE, roadclassesId),
};

const reducer: Reducer<GlobalState, GlobalAction> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GlobalActionType.SET_SELECTED_FOCUS_AREA_ID_SUCCEEDED: {
        draft.selectedFocusAreaId = action.payload;
        return;
      }
      case GlobalActionType.SET_SELECTED_FOCUS_AREA: {
        draft.selectedFocusArea = action.payload;
        return;
      }
      case GlobalActionType.SET_TIME_PERIOD_SUCCEEDED: {
        draft.timePeriod = action.payload;
        return;
      }
      case GlobalActionType.SET_TOAST_MESSAGE: {
        draft.toastMessage = action.payload;
        return;
      }
      case GlobalActionType.SET_REDIRECT_URL: {
        draft.redirectUrl = action.payload;
        return;
      }
      case GlobalActionType.TOGGLE_LAYER_CONTAINER_COLLAPSED_STATE: {
        if (draft.collapsedMapLayerContainers.includes(action.payload)) {
          draft.collapsedMapLayerContainers = draft.collapsedMapLayerContainers.filter((id) => id !== action.payload);
        } else {
          draft.collapsedMapLayerContainers.push(action.payload);
        }
        return;
      }
      case GlobalActionType.TOGGLE_DIMENSION_CONTAINER_COLLAPSED_STATE: {
        if (draft.collapsedDimensionContainers.includes(action.payload)) {
          draft.collapsedDimensionContainers = draft.collapsedDimensionContainers.filter((id) => id !== action.payload);
        } else {
          draft.collapsedDimensionContainers.push(action.payload);
        }
        return;
      }
      case GlobalActionType.TOGGLE_ROADCLASS_CONTAINER_COLLAPSED_STATE: {
        if (draft.collapsedRoadclassContainers.includes(action.payload)) {
          draft.collapsedRoadclassContainers = draft.collapsedRoadclassContainers.filter((id) => id !== action.payload);
        } else {
          draft.collapsedRoadclassContainers.push(action.payload);
        }
        return;
      }
      default:
        return state;
    }
  });

export default reducer;

function* fullReset(): Generator {
  yield put({
    type: MapActionType.CLEAR_MAP_BOUNDS,
  });

  yield put({
    type: AnalyticsActionType.CLEAR_DATASET_METADATA,
  });

  yield put({
    type: FiltersActionType.CLEAR_FILTERS,
  });

  yield put({
    type: CorridorActionType.CLEAR_CORRIDOR_METADATA,
  });

  yield put({
    type: CorridorActionType.SET_CORRIDOR_FILTERS,
    payload: null,
  });

  yield put({
    type: RoadVmtActionType.CLEAR_ROAD_VMT_METADATA,
  });

  yield put({
    type: RoadVmtActionType.SET_ROAD_VMT_FILTERS,
    payload: null,
  });

  yield put({
    type: ScreenlinesActionType.RESET_TO_INITIAL_STATE,
  });

  yield put({
    type: RoadIntersectionsActionType.RESET_TO_DEFAULT,
  });

  yield put({
    type: DatasetFoldersActionType.RESET_LOAD_CONFIG_DOCUMENT,
  });
}

function* setSelectedFocusAreaId(action: Action<string, SetSelectedFocusAreaIdPayload>): Generator {
  const { arePendingChanges, msg }: any = yield select((state) => selectArePendingChanges(state, "aoi"));

  if (arePendingChanges && !window.confirm(msg)) return;

  let { focusAreaId, timePeriod, redirectUrl, reset = true } = action.payload;
  let areas: any = yield select((state) => state.analytics.focusAreasAndDatasets);
  let focusArea: any = areas.data?.find((area: any) => area.id === focusAreaId);

  // Fetch focus areas and datasets if no focus area is found (in case of a newly computed dataset)
  if (!focusArea) {
    yield call(fetchFocusAreasAndDatasets);
  }

  // Re-assigning areas variables to get updated data
  areas = yield select((state) => state.analytics.focusAreasAndDatasets);
  focusArea = areas.data.find((area: any) => area.id === focusAreaId);

  // If focus area is still not found (dataset has probably been deleted) set focus area to the first in the list, set toast message, and redirect to map
  if (!focusArea) {
    focusAreaId = areas.data[0].id;
    focusArea = areas.data[0];
    redirectUrl = ROUTES.Map;

    yield put({
      type: GlobalActionType.SET_TOAST_MESSAGE,
      payload: {
        content: "Dataset not found",
        severity: "warning",
      },
    });
  }

  const availableTimePeriods = focusArea.timePeriods;
  const currentTimePeriod: any = yield select((state) => state.global.timePeriod);
  const timePeriodToApply = timePeriod || currentTimePeriod;
  const isTimePeriodAvailable = availableTimePeriods.includes(timePeriodToApply);

  // Set licensed area id in session storage for use in api calls header
  sessionStorage.setItem("licensedAreaId", focusArea.licensedAreaId.toString());

  // If time period is explicitly set or current time period is not available, set time period to the first in the list
  if (timePeriod || !isTimePeriodAvailable) {
    yield put({
      type: GlobalActionType.SET_TIME_PERIOD_SUCCEEDED,
      payload: isTimePeriodAvailable ? timePeriod : availableTimePeriods[0],
    });
  }

  // Reset redux store triggering a re-fetch of metadata, zones/segments ids, counts/volumes, ecc.
  if (reset) {
    yield put({
      type: GlobalActionType.FULL_RESET,
    });
  }

  yield put({
    type: GlobalActionType.SET_SELECTED_FOCUS_AREA_ID_SUCCEEDED,
    payload: focusAreaId,
  });

  yield put({
    type: GlobalActionType.SET_SELECTED_FOCUS_AREA,
    payload: focusArea,
  });

  if (redirectUrl) {
    yield put({
      type: GlobalActionType.SET_REDIRECT_URL,
      payload: redirectUrl,
    });
  }
}

function* setTimePeriod(action: Action<string, string>): Generator {
  yield put({
    type: AnalyticsActionType.CLEAR_DATASET_METADATA,
  });

  yield put({
    type: CorridorActionType.CLEAR_CORRIDOR_METADATA,
  });

  yield put({
    type: RoadVmtActionType.CLEAR_ROAD_VMT_METADATA,
  });

  yield put({
    type: GlobalActionType.SET_TIME_PERIOD_SUCCEEDED,
    payload: action.payload,
  });

  yield put({
    type: ScreenlinesActionType.RESET_AFTER_TIME_PERIOD_CHANGE,
  });
}

export function* globalSaga() {
  yield all([
    takeLatest(GlobalActionType.FULL_RESET, fullReset),
    takeLatest(GlobalActionType.SET_SELECTED_FOCUS_AREA_ID, setSelectedFocusAreaId),
    takeLatest(GlobalActionType.SET_TIME_PERIOD, setTimePeriod),
  ]);
}

export type ContextChangeAction = "aoi" | "navigate";

export const selectArePendingChanges = createSelector(
  [
    selectScreenlinesPendingChangesPromptMessage,
    (state: RootState, contextChangeAction: ContextChangeAction) => contextChangeAction,
  ],
  (screenlinesPendingChangesPromptMessage, contextChangeAction) => {
    if (screenlinesPendingChangesPromptMessage)
      return { arePendingChanges: true, msg: screenlinesPendingChangesPromptMessage };

    return { arePendingChanges: false, msg: "" };
  },
);

export const selectIsEditing = createSelector(
  [selectIsEditScreenlineMode],
  (isEditScreenlineMode) => isEditScreenlineMode,
);
