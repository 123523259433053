import { useAuth0 } from "@auth0/auth0-react";
import { LayerManager, useLayerManager } from "@daturon/mapboxgl-layer-manager";
import memoryStore, { MemoryStoreKeys } from "api/memoryStore";
import mapboxgl, { Layer, Popup, VectorSourceImpl } from "mapbox-gl";
import { Dispatch, MutableRefObject, RefObject, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppDispatch, useAppSelector, usePrevious, useStateRef } from "hooks";

import { DataState } from "store/interfaces";
import { filtersActions } from "store/sections/filters";
import { globalActions } from "store/sections/global";

import { DataDetail, MeasureType } from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

import { getCommonLayers } from "../common-map-data/layers";
import { getCommonSources } from "../common-map-data/sources";
import {
  useFetchCorridorEdgeCounts,
  useFetchCorridorEdgeIds,
  useFetchCorridorHeatmapConfiguration,
  useFetchCorridorMetadata,
  useFetchCorridorNodeCounts,
  useFetchCorridorNodeIds,
  useFetchEdgeDetails,
  useFetchServiceOverlayLayers,
  useSetSelectedEdge,
} from "./ControllerCallbacks";
import { EdgePopupProps } from "./EdgePopupContent";
// import { NodeCountsPopupProps } from "./NodeCountsPopupWrapper";
import { getCorridorHandlers } from "./map-data/corridor/handlers";
import { CORRIDOR_EDGE_LAYER_ID, CORRIDOR_NODE_LAYER_ID, getCorridorLayers } from "./map-data/corridor/layers";
import { CORRIDOR_EDGE_SOURCE_ID, CORRIDOR_NODE_SOURCE_ID, getCorridorSources } from "./map-data/corridor/sources";

interface Props {
  mapLoaded: boolean;
  map: MutableRefObject<mapboxgl.Map | null>;
  mapController: MutableRefObject<any>;
  edgePopupRef: MutableRefObject<HTMLDivElement | null>;
  setEdgePopupRef: RefObject<Dispatch<SetStateAction<EdgePopupProps | null>>>;
  closeEdgeAnalyticsPanelRef: RefObject<() => void>;
  overlayPopupRef: MutableRefObject<HTMLDivElement | null>;
  setOverlayPopupRef: RefObject<Dispatch<SetStateAction<any | null>>>;
  overlayLayerIds: MutableRefObject<string[]>;
}

export const MapController = ({
  mapLoaded,
  map,
  mapController,
  edgePopupRef,
  setEdgePopupRef,
  closeEdgeAnalyticsPanelRef,
  overlayPopupRef,
  setOverlayPopupRef,
  overlayLayerIds,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth0();

  const [mapboxLayerManager, setMapboxLayerManager] = useState<null | LayerManager>(null);
  const [deselectEdge, setDeselectEdge] = useState<() => void>(() => {});
  const [updateEdgeCounts, setUpdateEdgeCounts] = useState<(forceUpdate: boolean) => void>(() => {});
  const [updateNodeCounts, setUpdateNodeCounts] = useState<(forceUpdate: boolean) => void>(() => {});

  const permissions = useAppSelector((state) => state.license.permissions);

  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const previousSelectedFocusAreaId = usePrevious(selectedFocusAreaId);
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const measure = useAppSelector((state) => state.filters.measure);

  const corridorMetadata = useAppSelector((state) => state.corridor.corridorMetadata);
  const corridorEdgeIds = useAppSelector((state) => state.corridor.corridorEdgeIds);
  const corridorEdgeCounts = useAppSelector((state) => state.corridor.corridorEdgeCounts);
  const corridorEdgeDetails = useAppSelector((state) => state.corridor.corridorEdgeDetails);
  const corridorNodeIds = useAppSelector((state) => state.corridor.corridorNodeIds);
  const corridorNodeCounts = useAppSelector((state) => state.corridor.corridorNodeCounts);
  const corridorHeatmapConfiguration = useAppSelector((state) => state.corridor.corridorHeatmapConfiguration);
  const serviceOverlayLayers = useAppSelector((state) => state.corridor.serviceLayers);
  const corridorFilters = useAppSelector((state) => state.corridor.filters);
  const selectedCorridorEdge = useAppSelector((state) => state.corridor.selectedCorridorEdge);
  const edgeCountsAvailableRangeState = useAppSelector((state) => state.corridor.corridorEdgeAvailabeRange.state);

  const edgesWidthFactor = useAppSelector((state) => state.corridor.edgesWidthFactor);
  const edgesWidthFactorRef = useStateRef(edgesWidthFactor);
  const edgesOpacityFactor = useAppSelector((state) => state.corridor.edgesOpacityFactor);
  const edgesOpacityFactorRef = useStateRef(edgesOpacityFactor);
  const heatmapIntensityFactor = useAppSelector((state) => state.corridor.heatmapIntensityFactor);
  const heatmapIntensityFactorRef = useStateRef(heatmapIntensityFactor);
  const heatmapRadiusFactor = useAppSelector((state) => state.corridor.heatmapRadiusFactor);
  const heatmapRadiusFactorRef = useStateRef(heatmapRadiusFactor);
  const heatmapOpacityFactor = useAppSelector((state) => state.corridor.heatmapOpacityFactor);
  const heatmapOpacityFactorRef = useStateRef(heatmapOpacityFactor);

  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const maxEdgeCountsRef = useRef<number>(1);
  const maxNodeCountsRef = useRef<number>(1);
  const corridorLevelRef = useRef<string | null>(null);
  const corridorLevelsRef = useRef<Record<string, any> | null>(null);

  const mapboxEdgeCountsHoverPopupRef = useRef<Popup>(null);

  const fetchCorridorMetadata = useFetchCorridorMetadata();
  const fetchCorridorEdgeIds = useFetchCorridorEdgeIds();
  const fetchCorridorEdgeCounts = useFetchCorridorEdgeCounts();
  const fetchCorridorNodeIds = useFetchCorridorNodeIds();
  const fetchCorridorNodeCounts = useFetchCorridorNodeCounts();
  const fetchCorridorHeatmapConfiguration = useFetchCorridorHeatmapConfiguration();
  const fetchServiceOverlayLayers = useFetchServiceOverlayLayers();
  const fetchEdgeDetails = useFetchEdgeDetails();
  const setSelectedEdge = useSetSelectedEdge();

  const cleanMapboxLayerManager = useCallback(() => {
    if (mapboxLayerManager) {
      mapboxLayerManager.removeLayers(mapboxLayerManager.getActiveCustomLayerIds());
      mapboxLayerManager.removeSources(mapboxLayerManager.getActiveCustomSourceIds());
      setMapboxLayerManager(null);
    }
  }, [mapboxLayerManager]);

  // Change tileservice url on metadata load, if different from current
  useEffect(() => {
    if (corridorMetadata.data) {
      const corridorLevels = corridorMetadata.data.corridorLevels;

      Object.keys(corridorLevels).forEach((zoningLevelId) => {
        const edgesSource = map.current?.getSource(`${CORRIDOR_EDGE_SOURCE_ID}_${zoningLevelId}`) as
          | VectorSourceImpl
          | undefined;
        const nodesSource = map.current?.getSource(`${CORRIDOR_NODE_SOURCE_ID}_${zoningLevelId}`) as
          | VectorSourceImpl
          | undefined;
        const edgeTilesUrl = corridorLevels[zoningLevelId].edgeTileServiceLayer.url + "/{z}/{x}/{y}.pbf";
        const nodeTilesUrl = corridorLevels[zoningLevelId].nodeTileServiceLayer.url + "/{z}/{x}/{y}.pbf";

        if (edgesSource?.tiles?.[0] !== edgeTilesUrl) {
          edgesSource?.setTiles([edgeTilesUrl]);
        }

        if (nodesSource?.tiles?.[0] !== nodeTilesUrl) {
          nodesSource?.setTiles([nodeTilesUrl]);
        }
      });
    }
  }, [corridorMetadata.data, map]);

  // Redirect to dashboard if no selectedArea (dataset has been deleted)
  useEffect(() => {
    if (focusAreas.state === DataState.AVAILABLE && selectedFocusAreaId && !selectedFocusArea) {
      toast.error("The dataset has been deleted", {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate("/dashboard");
      toast.clearWaitingQueue();
    }
  }, [focusAreas.state, selectedFocusArea, selectedFocusAreaId, navigate]);

  // Switch to first measure if currently selected is not available
  useEffect(() => {
    if (
      measure &&
      corridorMetadata.state === DataState.AVAILABLE &&
      !corridorMetadata.data?.measures?.find((m) => m.columnName === measure)
    ) {
      const firstMeasure = corridorMetadata.data?.measures?.[0]?.columnName;
      dispatch(filtersActions.setMeasure(firstMeasure as MeasureType));
    }
  }, [measure, corridorMetadata.state, corridorMetadata.data?.measures, dispatch]);

  // Update corridor level ref
  useEffect(() => {
    if (corridorMetadata.data?.detailLevel && corridorMetadata.data.detailLevel !== corridorLevelRef.current) {
      corridorLevelRef.current = corridorMetadata.data.detailLevel;
    }
  }, [corridorMetadata.data?.detailLevel]);

  // Initialize mapboxLayerManager
  useEffect(() => {
    if (
      mapLoaded &&
      selectedFocusArea &&
      !mapboxLayerManager &&
      corridorMetadata.state === DataState.AVAILABLE &&
      corridorHeatmapConfiguration.state === DataState.AVAILABLE
    ) {
      const commonSources = getCommonSources(selectedFocusArea.geometry);
      const corridorSources = getCorridorSources(corridorMetadata.data?.corridorLevels);

      const commonLayers = getCommonLayers();
      const corridorLayers = getCorridorLayers(
        corridorMetadata.data?.corridorLevels,
        corridorHeatmapConfiguration.data,
        edgesWidthFactorRef,
        edgesOpacityFactorRef,
        heatmapIntensityFactorRef,
        heatmapRadiusFactorRef,
        heatmapOpacityFactorRef,
      );

      /* eslint-disable react-hooks/rules-of-hooks */
      const layerManager = useLayerManager(
        map.current!,
        [...commonSources, ...corridorSources],
        [...commonLayers, ...corridorLayers],
      );
      /* eslint-enable react-hooks/rules-of-hooks */

      setMapboxLayerManager(layerManager);

      mapController.current = {
        layerManager,
        updateEdgeCounts,
      };

      const { deselectEdge, updateCounts, updateNodeCounts } = getCorridorHandlers(
        map,
        corridorLevelsRef,
        maxEdgeCountsRef,
        corridorLevelRef,
        maxNodeCountsRef,
        edgePopupRef,
        setEdgePopupRef,
        mapboxEdgeCountsHoverPopupRef,
        closeEdgeAnalyticsPanelRef,
        overlayPopupRef,
        setOverlayPopupRef,
        overlayLayerIds,
        setSelectedEdge,
      );

      setUpdateEdgeCounts(() => updateCounts);
      setUpdateNodeCounts(() => updateNodeCounts);
      setDeselectEdge(() => deselectEdge);

      mapController.current.updateEdgeCounts = updateCounts;
    }
  }, [
    map,
    mapLoaded,
    mapController,
    mapboxLayerManager,
    selectedFocusArea,
    corridorMetadata.data?.corridorLevels,
    corridorMetadata.state,
    corridorHeatmapConfiguration,
    edgePopupRef,
    setEdgePopupRef,
    closeEdgeAnalyticsPanelRef,
    overlayPopupRef,
    setOverlayPopupRef,
    overlayLayerIds,
    edgesWidthFactorRef,
    edgesOpacityFactorRef,
    heatmapIntensityFactorRef,
    heatmapRadiusFactorRef,
    heatmapOpacityFactorRef,
    updateEdgeCounts,
    setSelectedEdge,
  ]);

  // Fetch corridor metadata
  useEffect(() => {
    if (mapLoaded && corridorMetadata.state === DataState.EMPTY) {
      fetchCorridorMetadata(timePeriod);
    } else if (corridorMetadata.state === DataState.AVAILABLE) {
      corridorLevelsRef.current = corridorMetadata.data.corridorLevels;
    }
  }, [mapLoaded, timePeriod, corridorMetadata, fetchCorridorMetadata]);

  // Fetch corridor edge ids
  useEffect(() => {
    if (corridorEdgeIds.state === DataState.EMPTY && corridorMetadata.data?.detailLevel && timePeriod) {
      fetchCorridorEdgeIds(timePeriod, corridorMetadata.data.detailLevel);
    }
  }, [corridorEdgeIds.state, fetchCorridorEdgeIds, timePeriod, corridorMetadata.data?.detailLevel]);

  // Fetch corridor edge counts
  useEffect(() => {
    if (
      corridorEdgeCounts.state === DataState.EMPTY &&
      corridorMetadata.data?.detailLevel &&
      timePeriod &&
      corridorFilters
    ) {
      fetchCorridorEdgeCounts(timePeriod, corridorMetadata.data.detailLevel, corridorFilters);
    } else if (corridorEdgeCounts.state === DataState.AVAILABLE) {
      maxEdgeCountsRef.current = corridorEdgeCounts.data?.maxVolume || 1;
    }
  }, [corridorEdgeCounts, fetchCorridorEdgeCounts, timePeriod, corridorMetadata.data?.detailLevel, corridorFilters]);

  // Fetch corridor node ids
  useEffect(() => {
    if (corridorNodeIds.state === DataState.EMPTY && corridorMetadata.data?.detailLevel && timePeriod) {
      fetchCorridorNodeIds(timePeriod, corridorMetadata.data.detailLevel);
    }
  }, [corridorNodeIds.state, timePeriod, corridorMetadata.data?.detailLevel, fetchCorridorNodeIds]);

  // Fetch corridor node counts
  useEffect(() => {
    if (
      corridorNodeCounts.state === DataState.EMPTY &&
      corridorMetadata.data?.detailLevel &&
      timePeriod &&
      corridorFilters
    ) {
      fetchCorridorNodeCounts(timePeriod, corridorMetadata.data.detailLevel, corridorFilters);
    } else if (corridorNodeCounts.state === DataState.AVAILABLE) {
      maxNodeCountsRef.current = corridorNodeCounts.data?.maxVolume || 1;
    }
  }, [corridorNodeCounts, fetchCorridorNodeCounts, timePeriod, corridorMetadata.data?.detailLevel, corridorFilters]);

  // Fetch corridor heatmap configuration
  useEffect(() => {
    if (
      corridorHeatmapConfiguration.state === DataState.EMPTY &&
      corridorMetadata.data?.detailLevel &&
      timePeriod &&
      corridorFilters
    ) {
      fetchCorridorHeatmapConfiguration(timePeriod, corridorMetadata.data.detailLevel, corridorFilters);
    }
  }, [
    corridorMetadata.state,
    fetchCorridorHeatmapConfiguration,
    timePeriod,
    corridorMetadata.data?.detailLevel,
    corridorFilters,
    corridorHeatmapConfiguration,
  ]);

  // Fetch service overlay layers
  useEffect(() => {
    if (
      serviceOverlayLayers.state === DataState.EMPTY &&
      corridorEdgeCounts.state === DataState.AVAILABLE &&
      corridorNodeCounts.state === DataState.AVAILABLE &&
      edgeCountsAvailableRangeState === DataState.AVAILABLE &&
      corridorHeatmapConfiguration.state === DataState.AVAILABLE &&
      selectedFocusAreaId
    ) {
      fetchServiceOverlayLayers();
    }
  }, [
    selectedFocusAreaId,
    serviceOverlayLayers.state,
    fetchServiceOverlayLayers,
    corridorEdgeCounts.state,
    corridorNodeCounts.state,
    edgeCountsAvailableRangeState,
    corridorHeatmapConfiguration.state,
  ]);

  // Fetch corridor edge details
  useEffect(() => {
    if (
      selectedCorridorEdge &&
      corridorEdgeDetails.state === DataState.EMPTY &&
      corridorMetadata.data?.detailLevel &&
      corridorMetadata.data?.measures &&
      timePeriod &&
      corridorFilters
    ) {
      const dimensions = corridorMetadata.data.measures
        .find((d) => d.columnName === measure)
        ?.dimensions.filter((d) => d.enabled)
        .map((d) => d.columnName);

      if (dimensions) {
        fetchEdgeDetails(
          selectedCorridorEdge.id,
          timePeriod,
          corridorMetadata.data.detailLevel,
          corridorFilters,
          dimensions,
        );
        addCustomGAEvent("corridor", "edges", "show_edge_details", user, userOrganizationName);
      }
    }
  }, [
    selectedCorridorEdge,
    corridorEdgeDetails.state,
    fetchEdgeDetails,
    timePeriod,
    corridorMetadata.data?.detailLevel,
    corridorFilters,
    corridorMetadata.data,
    user,
    userOrganizationName,
    measure,
  ]);

  // Render corridor layers
  useEffect(() => {
    if (
      mapboxLayerManager &&
      corridorMetadata.data?.detailLevel &&
      corridorMetadata.state === DataState.AVAILABLE &&
      corridorEdgeIds.state === DataState.AVAILABLE &&
      corridorEdgeCounts.state === DataState.AVAILABLE &&
      corridorNodeIds.state === DataState.AVAILABLE &&
      corridorNodeIds.data &&
      corridorNodeCounts.state === DataState.AVAILABLE &&
      edgeCountsAvailableRangeState === DataState.AVAILABLE &&
      corridorHeatmapConfiguration.state === DataState.AVAILABLE &&
      serviceOverlayLayers.state === DataState.AVAILABLE
    ) {
      const corridorNodeIdsData = memoryStore.getItem(MemoryStoreKeys.CORRIDOR_DISCOVERY_NODE_IDS) as Set<number>;
      const corridorNodeCountsData = memoryStore.getItem(MemoryStoreKeys.CORRIDOR_DISCOVERY_NODE_COUNTS) as Map<
        number,
        number
      >;
      const corridorLayer = corridorMetadata.data?.corridorLevels[corridorMetadata.data.detailLevel];
      const corridorLayers = getCorridorLayers(
        corridorMetadata.data?.corridorLevels,
        corridorHeatmapConfiguration.data,
        edgesWidthFactorRef,
        edgesOpacityFactorRef,
        heatmapIntensityFactorRef,
        heatmapRadiusFactorRef,
        heatmapOpacityFactorRef,
      )
        .filter((layer: any) => layer.level === corridorMetadata.data.detailLevel)
        .map((layer: any) => {
          const currentLayer = map.current?.getStyle().layers.find((l: any) => l.id === layer.id);
          return currentLayer ? currentLayer : layer;
        });
      const availableOverlayLayerIds = serviceOverlayLayers.data
        .map((layer) => [layer.serviceLayerId, layer.serviceLayerId + "_highlight"])
        .flat(1);
      const allMapLayers = map.current?.getStyle().layers;

      const overlayLayersOnTheMap =
        allMapLayers?.filter((layer) => availableOverlayLayerIds?.find((layerId) => layer.id === layerId)) || [];

      const layers = [...getCommonLayers(), ...corridorLayers, ...overlayLayersOnTheMap];
      const corridorNodeIdsFiltered = Array.from(corridorNodeIdsData)?.filter((id: number) => {
        const nodeCount = (corridorNodeCountsData.get(id) || 0) + (corridorNodeCountsData.get(-id) || 0);
        return nodeCount > 0;
      });
      const settings: any = {};

      for (const layer of layers) {
        settings[layer.id] = {
          filter: layer.filter,
          paint: layer.paint,
          layout: layer.layout,
        };

        if (
          layer.id === `${CORRIDOR_EDGE_LAYER_ID}_${corridorMetadata.data.detailLevel}` ||
          layer.id === `${CORRIDOR_EDGE_LAYER_ID}_${corridorMetadata.data.detailLevel}_hightlighted_hairline` ||
          layer.id === `${CORRIDOR_EDGE_LAYER_ID}_${corridorMetadata.data.detailLevel}_hightlighted_volume` ||
          layer.id === `${CORRIDOR_EDGE_LAYER_ID}_${corridorMetadata.data.detailLevel}_hairline`
        ) {
          settings[layer.id].filter = ["in", corridorLayer.edgeTileServiceLayer.idField, ...[]];
        }

        if (layer.id === `${CORRIDOR_NODE_LAYER_ID}_${corridorMetadata.data.detailLevel}`) {
          settings[layer.id].filter = [
            "in",
            corridorLayer.nodeTileServiceLayer.idField,
            ...(corridorNodeIdsFiltered || []),
          ];
        }
      }

      const beforeLayer = map.current?.getLayer("admin_country") || map.current?.getLayer("road-label");

      mapboxLayerManager.renderOrderedLayers(
        layers.map((layer: Layer) => layer.id),
        settings,
        beforeLayer?.id,
      );

      if (typeof updateEdgeCounts === "function") {
        updateEdgeCounts(true);
      }

      if (typeof updateNodeCounts === "function") {
        updateNodeCounts(true);
      }
    }
  }, [
    map,
    mapboxLayerManager,
    corridorMetadata.state,
    corridorMetadata.data?.corridorLevels,
    corridorEdgeIds,
    corridorMetadata.data?.detailLevel,
    corridorNodeIds,
    corridorEdgeCounts.state,
    corridorNodeCounts.state,
    corridorHeatmapConfiguration,
    edgeCountsAvailableRangeState,
    serviceOverlayLayers,
    edgesWidthFactorRef,
    edgesOpacityFactorRef,
    heatmapIntensityFactorRef,
    heatmapRadiusFactorRef,
    heatmapOpacityFactorRef,
    deselectEdge,
    updateEdgeCounts,
    updateNodeCounts,
  ]);

  // Deselect edge after changing time period
  useEffect(() => {
    if (typeof deselectEdge === "function" && timePeriod) {
      deselectEdge();
    }
  }, [timePeriod, deselectEdge]);

  useEffect(() => {
    if (
      mapLoaded &&
      mapboxLayerManager &&
      previousSelectedFocusAreaId &&
      selectedFocusAreaId &&
      previousSelectedFocusAreaId !== selectedFocusAreaId
    ) {
      cleanMapboxLayerManager();
    }
  }, [previousSelectedFocusAreaId, selectedFocusAreaId, mapboxLayerManager, mapLoaded, cleanMapboxLayerManager]);

  //Switching to allowed licensed area if the selected has no data permission
  useEffect(() => {
    const getDetailFn = (dataDetail: DataDetail) => dataDetail.corridorDetail;
    const isLicensedAreaAllowed = permissions.data?.licensedAreas?.find(
      (area) => area.licensedAreaId.toString() === selectedFocusArea?.licensedAreaId && getDetailFn(area.dataDetail),
    );
    const firstAllowedLicensedArea = permissions.data?.licensedAreas?.find((area) => getDetailFn(area.dataDetail));
    if ((selectedFocusArea?.datasetId || !isLicensedAreaAllowed) && firstAllowedLicensedArea) {
      dispatch(
        globalActions.setSelectedFocusAreaId({
          focusAreaId: isLicensedAreaAllowed
            ? (selectedFocusArea?.licensedAreaId as string)
            : firstAllowedLicensedArea?.licensedAreaId.toString(),
        }),
      );
    }
  }, [dispatch, selectedFocusArea?.datasetId, selectedFocusArea?.licensedAreaId, permissions.data?.licensedAreas]);

  return <>{null}</>;
};
