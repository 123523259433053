import { MeasureBreakdownResult } from "api/analytics";
import { MapControlContainer } from "components_new";
import React, { FC } from "react";

import { BarChart } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { globalActions } from "store/sections/global";

import { Dimension } from "types";

import { getLabelByCategories, getLabelByDimension } from "./ODAnalytics";

interface AnalyticsBreakdownsProps {
  breakdowns: MeasureBreakdownResult[];
  dimensions: Dimension[];
  containersIdPrefix: string;
}

const AnalyticsBreakdowns: FC<AnalyticsBreakdownsProps> = ({ breakdowns, dimensions, containersIdPrefix }) => {
  const dispatch = useAppDispatch();
  const collapsedDimensionContainers = useAppSelector((state) => state.global.collapsedDimensionContainers);

  return (
    <>
      {breakdowns.length > 0 &&
        breakdowns.map((breakdown, index) => {
          const dimension = breakdown.dimensions[0];
          const data = breakdown.rows.map((row) => ({
            name: getLabelByCategories(row.categories[0], dimension, dimensions || []),
            value: row.value,
          }));

          return data.length > 0 ? (
            <MapControlContainer
              key={index}
              title={getLabelByDimension(dimension, dimensions)}
              collapse
              expanded={!collapsedDimensionContainers.includes(`${containersIdPrefix}_${dimension}`)}
              onChange={() =>
                dispatch(globalActions.toggleDimensionContainerCollapsedState(`${containersIdPrefix}_${dimension}`))
              }
            >
              <BarChart key={index} data={data} layout="vertical" />
            </MapControlContainer>
          ) : null;
        })}
    </>
  );
};

export { AnalyticsBreakdowns };
