export const roadAdtVmtFileColumns = [
  {
    column: "time_period",
    type: "Text",
    description: "Year i.e. “2019”, “2022” ",
  },
  {
    column: "zone_id",
    type: "Integer",
    description: "County ID (FIPS Code)",
  },
  {
    column: "road_urban_rural",
    type: "Text",
    description: (
      <div>
        Road type (“Urban”, “Rural”)<sup>1</sup>
      </div>
    ),
  },
  {
    column: "road_ramp",
    type: "Text",
    description: "Ramp (“Ramp”, “Non-ramp”)",
  },
  {
    column: "fhwa_class",
    type: "Integer",
    description: (
      <div>
        FHWA functional class<sup>2</sup>
      </div>
    ),
  },
  {
    column: "adt_vmt",
    type: "Long",
    description: (
      <div>
        Sum of the result of the multiplication of ADT of each segment with its length in miles for all segments
        touching a zone, aggregated by zone ID <sup>4</sup>
      </div>
    ),
  },
];
