import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { isEqual } from "lodash";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useMemo, useRef, useState } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { useAppDispatch, useAppSelector, usePrevious, useStateRef } from "hooks";

import { DataState } from "store/interfaces";
import { filtersActions } from "store/sections/filters";

import { Counts, FlowsSettings, MeasureType, SelectedArea, ZoneIds } from "types";

import {
  useChangeShowZoneCounts,
  useChangeZonesFillOpacity,
  useCloseODAnalyticsPanel,
  useFilterGatesByRange,
  useFilterZonesByRange,
  useGetODCounts,
  useGetODCountsByZoneId,
  useGetODIds,
  useGetODZoneDetails,
  useHandleSelectZone,
  useHandleSetColorScale,
  useHandleSetZoningLevel,
  useHideTopFlows,
  useShowTopFlows,
} from "./ControllerCallbacks";
import { getODLayers } from "./map-data/od/layers";
import { getODSources } from "./map-data/od/sources";

interface Props {
  map: MutableRefObject<mapboxgl.Map | null>;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  flowsSettings: FlowsSettings;
  closeODAnalyticsPanelRef: React.RefObject<(selectedZone: SelectedArea | null) => void>;
  setODModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  updateODModeCounts: React.MutableRefObject<(() => void) | null>;
}

export const MapController = ({
  map,
  layerManagerRef,
  flowsSettings,
  closeODAnalyticsPanelRef,
  setODModuleData,
  updateODModeCounts,
}: Props) => {
  const dispatch = useAppDispatch();

  // Global
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const measure = useAppSelector((state) => state.filters.measure);

  // OD
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const ODIds = useAppSelector((state) => state.analytics.ODIds);
  const ODCounts = useAppSelector((state) => state.analytics.ODCounts);
  const ODCountsByZoneId = useAppSelector((state) => state.analytics.ODCountsByZoneId);
  const selectedZone = useAppSelector((state) => state.analytics.selectedZone);
  const queryType = useAppSelector((state) => state.filters.queryType);
  const currentODFilters = useAppSelector((state) => state.filters.ODFilters);
  const zoneDetails = useAppSelector((state) => state.analytics.zoneDetails);
  const colorScheme = useAppSelector((state) => state.map.colorScheme);
  const showZoneCounts = useAppSelector((state) => state.map.showZoneCounts);
  const ODRange = useAppSelector((state) => state.filters.ODRange);
  const odOacityFactor = useAppSelector((state) => state.analytics.ODOpacityFactor);
  const odOpacityFactorRef = useStateRef(odOacityFactor);

  const [blockedZoomLevel, setBlockedZoomLevel] = useState<number | null>(selectedZone ? selectedZone.zoom : null);

  //Dataset
  const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);
  const datasetIds = useAppSelector((state) => state.analytics.datasetIds);
  const datasetCounts = useAppSelector((state) => state.analytics.datasetCounts);
  const datasetCountsByZoneId = useAppSelector((state) => state.analytics.datasetCountsByZoneId);
  const currentDatasetFilters = useAppSelector((state) => state.filters.datasetFilters);
  const gates = useAppSelector((state) => state.analytics.datasetGates);
  const datasetRange = useAppSelector((state) => state.filters.datasetRange);

  const previousFlowSettings = usePrevious(flowsSettings);

  const datasetZoningLevels = useMemo(() => {
    if (datasetMetadata.data?.zoningLevels) {
      return selectedFocusArea?.zoningLevel === "Custom" && ODMetadata.data?.zoningLevels?.[0]
        ? [...datasetMetadata.data.zoningLevels, ODMetadata.data?.zoningLevels?.[0]]
        : datasetMetadata.data.zoningLevels;
    }
  }, [ODMetadata.data?.zoningLevels, datasetMetadata.data?.zoningLevels, selectedFocusArea?.zoningLevel]);

  const zoningLevels = useMemo(
    () => (selectedFocusArea?.datasetId ? datasetZoningLevels : ODMetadata.data?.zoningLevels),
    [selectedFocusArea?.datasetId, datasetZoningLevels, ODMetadata.data?.zoningLevels],
  );

  // Refs
  const ODCountsRef = useRef<Counts>({ zones: new Map(), gates: new Map() });
  const ODSelectedZoneRef = useRef<SelectedArea | null>(selectedZone);
  const ODZoningLevelBlockedZoom = useRef<number | null>(selectedZone ? selectedZone.zoom : null);
  const queryTypeRef = useStateRef(queryType);
  const showZoneCountsRef = useRef<boolean>(showZoneCounts);
  const colorSchemeRef = useStateRef(colorScheme);

  const isDataset = Boolean(selectedFocusArea?.datasetId);

  const isODDataAvailable = useMemo(
    () =>
      Boolean(
        ODMetadata.state === DataState.AVAILABLE &&
          ODMetadata.data?.tileService &&
          ODIds.state === DataState.AVAILABLE &&
          ODIds.data &&
          ((!selectedZone && ODCounts.state === DataState.AVAILABLE && ODCounts.data) ||
            (selectedZone && ODCountsByZoneId.state === DataState.AVAILABLE && ODCountsByZoneId.data)),
      ),
    [ODCounts, ODIds, ODMetadata, ODCountsByZoneId, selectedZone],
  );

  const isDatasetDataAvailabe = useMemo(
    () =>
      Boolean(
        datasetMetadata.state === DataState.AVAILABLE &&
          datasetMetadata.data?.tileService &&
          datasetIds.state === DataState.AVAILABLE &&
          datasetIds.data &&
          ((!selectedZone && datasetCounts.state === DataState.AVAILABLE && datasetCounts.data) ||
            (selectedZone && datasetCountsByZoneId.state === DataState.AVAILABLE && datasetCountsByZoneId)) &&
          gates.state === DataState.AVAILABLE &&
          gates.data,
      ),
    [selectedZone, datasetCounts, datasetCountsByZoneId, datasetIds, datasetMetadata, gates],
  );

  const ODLayers = useMemo(
    () => (isDataset ? datasetMetadata.data?.tileService.layers : ODMetadata.data?.tileService.layers),
    [ODMetadata.data?.tileService.layers, datasetMetadata.data?.tileService.layers, isDataset],
  );

  // OD callbacks
  const getODIds = useGetODIds(ODMetadata.data, selectedFocusArea, timePeriod);
  const getODCounts = useGetODCounts(queryType, ODMetadata.data, selectedFocusArea, timePeriod);
  const getODCountsByZoneId = useGetODCountsByZoneId(queryType, timePeriod);
  const getODZoneDetails = useGetODZoneDetails(queryType, timePeriod);
  const handleSelectZone = useHandleSelectZone();
  const closeODAnalyticsPanel = useCloseODAnalyticsPanel(handleSelectZone);
  const showTopFlows = useShowTopFlows(layerManagerRef);
  const hideTopFlows = useHideTopFlows(layerManagerRef);
  const changeShowZoneCounts = useChangeShowZoneCounts(layerManagerRef, ODLayers, ODZoningLevelBlockedZoom);
  const handleSetColorScale = useHandleSetColorScale();
  const handleSetZoningLevel = useHandleSetZoningLevel();

  //Dataset callbacks
  const getDatasetIds = useGetODIds(datasetMetadata.data, selectedFocusArea, timePeriod);
  const getDatasetCounts = useGetODCounts(queryType, datasetMetadata.data, selectedFocusArea, timePeriod);
  const getDatasetCountsByZoneId = useGetODCountsByZoneId(queryType, timePeriod);

  //Common callbacks
  const filterZonesByRange = useFilterZonesByRange(map, layerManagerRef);
  const filterGatesByRange = useFilterGatesByRange(map, layerManagerRef);
  const changeZonesFillOpacity = useChangeZonesFillOpacity(map, layerManagerRef);

  // Switch to first measure if currently selected is not available
  useEffect(() => {
    if (
      measure &&
      (isDataset ? datasetMetadata.state === DataState.AVAILABLE : ODMetadata.state === DataState.AVAILABLE) &&
      !ODMetadata.data?.measures.find((m) => m.columnName === measure)
    ) {
      const firstMeasure = isDataset
        ? datasetMetadata.data?.measures[0].columnName
        : ODMetadata.data?.measures[0].columnName;
      dispatch(filtersActions.setMeasure(firstMeasure as MeasureType));
    }
  }, [
    isDataset,
    measure,
    ODMetadata.state,
    ODMetadata.data?.measures,
    datasetMetadata.state,
    datasetMetadata.data?.measures,
    dispatch,
  ]);

  // Fetch OD ids
  useEffect(() => {
    if (ODIds.state === DataState.EMPTY && !isDataset) {
      getODIds();
    }
  }, [ODIds.state, isDataset, getODIds]);

  // Fetch OD counts
  useEffect(() => {
    if (
      !isDataset &&
      ODCounts.state !== DataState.LOADING &&
      ODCountsByZoneId.state !== DataState.LOADING &&
      selectedFocusArea &&
      currentODFilters &&
      ODMetadata.state === DataState.AVAILABLE
    ) {
      if (selectedZone && ODCountsByZoneId.state === DataState.EMPTY) {
        ODSelectedZoneRef.current = selectedZone;

        getODCountsByZoneId({
          filters: currentODFilters,
          requestMeasure: MeasureType.AADT,
          selectedZoneId: selectedZone.id,
          level: selectedZone.level,
        });
      } else if (
        !selectedZone &&
        ODCounts.state === DataState.EMPTY &&
        // Check if all dimensions are present in the filters, if not then updating will be done in the Filters component
        isEqual(
          Object.keys(currentODFilters),
          ODMetadata.data.measures
            .find((d) => d.columnName === measure)
            ?.dimensions.filter((d) => d.enabled)
            .map((d) => d.columnName),
        )
      ) {
        getODCounts(currentODFilters);
      }

      if (ODCounts.state === DataState.AVAILABLE && !selectedZone) {
        ODCountsRef.current = ODCounts.data;
        ODSelectedZoneRef.current = null;

        if (typeof updateODModeCounts.current === "function") {
          updateODModeCounts.current();
        }
      }

      if (ODCountsByZoneId.state === DataState.AVAILABLE && selectedZone) {
        ODCountsRef.current = ODCountsByZoneId.data.counts;

        if (typeof updateODModeCounts.current === "function") {
          updateODModeCounts.current();
        }
      }
    }
  }, [
    isDataset,
    ODMetadata.state,
    ODMetadata.data?.measures,
    ODCounts,
    ODCountsByZoneId,
    selectedFocusArea,
    currentODFilters,
    selectedZone,
    getODCounts,
    getODCountsByZoneId,
    updateODModeCounts,
    measure,
  ]);

  // Update query showZoneCounts ref
  useEffect(() => {
    showZoneCountsRef.current = showZoneCounts;
  }, [showZoneCounts]);

  // Fetch dataset ids
  useEffect(() => {
    if (datasetIds.state === DataState.EMPTY && isDataset) {
      getDatasetIds(selectedFocusArea!.datasetId);
    }
  }, [datasetIds.state, isDataset, selectedFocusArea, getDatasetIds]);

  // Fetch dataset counts
  useEffect(() => {
    if (
      isDataset &&
      datasetCounts.state !== DataState.LOADING &&
      datasetCountsByZoneId.state !== DataState.LOADING &&
      currentDatasetFilters &&
      datasetMetadata.state === DataState.AVAILABLE
    ) {
      if (selectedZone && datasetCountsByZoneId.state === DataState.EMPTY) {
        ODSelectedZoneRef.current = selectedZone;

        getDatasetCountsByZoneId({
          filters: currentDatasetFilters,
          requestMeasure: MeasureType.AADT,
          selectedZoneId: selectedZone.id,
          level: selectedZone.level,
          datasetId: selectedFocusArea!.datasetId,
          isGate: selectedZone.itemType === "gate",
        });
      } else if (
        !selectedZone &&
        datasetCounts.state === DataState.EMPTY &&
        // Check if all dimensions are present in the filters, if not then updating will be done in the Filters component
        isEqual(
          Object.keys(currentDatasetFilters),
          datasetMetadata.data.measures
            .find((d) => d.columnName === measure)
            ?.dimensions.filter((d) => d.enabled)
            .map((d) => d.columnName),
        )
      ) {
        getDatasetCounts(currentDatasetFilters, selectedFocusArea!.datasetId);
      }

      if (datasetCounts.state === DataState.AVAILABLE && !selectedZone) {
        ODCountsRef.current = datasetCounts.data;
        ODSelectedZoneRef.current = null;

        if (typeof updateODModeCounts.current === "function") {
          updateODModeCounts.current();
        }
      }

      if (datasetCountsByZoneId.state === DataState.AVAILABLE && selectedZone) {
        ODCountsRef.current = datasetCountsByZoneId.data.counts;

        if (typeof updateODModeCounts.current === "function") {
          updateODModeCounts.current();
        }
      }
    }
  }, [
    isDataset,
    datasetMetadata.state,
    datasetMetadata.data?.measures,
    datasetCounts.state,
    datasetCounts.data,
    datasetCountsByZoneId.state,
    datasetCountsByZoneId.data,
    selectedFocusArea,
    currentDatasetFilters,
    selectedZone,
    getDatasetCounts,
    getDatasetCountsByZoneId,
    updateODModeCounts,
    measure,
  ]);

  // Fetch zone details
  useEffect(() => {
    if (
      selectedZone &&
      flowsSettings &&
      (zoneDetails.state === DataState.EMPTY || previousFlowSettings !== flowsSettings)
    ) {
      const measures = isDataset ? datasetMetadata.data?.measures : ODMetadata.data?.measures;
      const dimensions = measures?.[0].dimensions.filter((d) => d.enabled).map((d) => d.columnName) || [];
      const breakdowns = dimensions.map((d) => ({
        dimensions: [d],
        includeUnfiltered: false,
      }));

      const filters = isDataset ? currentDatasetFilters : currentODFilters;

      getODZoneDetails(
        {
          filters: filters ?? {},
          measure: MeasureType.AADT,
          selectedZoneId: selectedZone.id,
          level: selectedZone.level ?? "",
          breakdowns,
          isGate: selectedZone.itemType === "gate",
        },
        flowsSettings,
        isDataset ? selectedFocusArea?.datasetId : undefined,
      );
    }
  }, [
    isDataset,
    selectedFocusArea?.datasetId,
    ODMetadata.data?.measures,
    datasetMetadata.data?.measures,
    currentODFilters,
    currentDatasetFilters,
    flowsSettings,
    previousFlowSettings,
    selectedZone,
    zoneDetails.state,
    getODZoneDetails,
  ]);

  // Update OD analytics panel ref function
  useEffect(() => {
    (closeODAnalyticsPanelRef as MutableRefObject<(selectedZone: SelectedArea | null) => void>).current =
      closeODAnalyticsPanel;
  }, [closeODAnalyticsPanelRef, closeODAnalyticsPanel]);

  // Show/hide top flows
  useEffect(() => {
    if (!(map.current as any)?.style || !(layerManagerRef.current?.getMapInstance() as any)?.style) return;

    if (zoneDetails.state === DataState.AVAILABLE && selectedZone && flowsSettings) {
      hideTopFlows();
      showTopFlows(zoneDetails.data, queryType, selectedZone);
    } else {
      hideTopFlows();
    }
  }, [map, layerManagerRef, zoneDetails, selectedZone, flowsSettings, hideTopFlows, showTopFlows, queryType]);

  // Block zoom level when ODRange or datasetRange or selectedZone is active
  useEffect(() => {
    if (ODRange || datasetRange || selectedZone) {
      if (blockedZoomLevel) return;
      const zoom = selectedZone?.zoom || map.current?.getZoom() || null;
      setBlockedZoomLevel(zoom);
      ODZoningLevelBlockedZoom.current = zoom;
    } else {
      setBlockedZoomLevel(null);
      ODZoningLevelBlockedZoom.current = null;
    }
  }, [map, ODRange, datasetRange, selectedZone, blockedZoomLevel]);

  // Set module data
  useEffect(() => {
    const isAllDataAvailable = isDataset ? isDatasetDataAvailabe : isODDataAvailable;

    if (selectedFocusArea && isAllDataAvailable) {
      const ODTileservice: any = isDataset ? datasetMetadata.data?.tileService : ODMetadata.data?.tileService;
      const ids: ZoneIds | null = isDataset ? datasetIds.data : ODIds.data;

      const ODSources = getODSources(ODTileservice);
      const ODLayers = getODLayers(ODTileservice, odOpacityFactorRef).zoningLayers;

      setODModuleData((data) => {
        if (data && data.data.ODTileservice.url === ODTileservice.url) {
          return data;
        } else {
          return {
            sources: ODSources,
            layers: ODLayers,
            data: {
              ODTileservice,
              ids,
              ODCountsRef,
              ODSelectedZoneRef,
              queryTypeRef,
              ODZoningLevelBlockedZoom,
              zoningLevels,
              showZoneCountsRef,
              colorSchemeRef,
              updateODModeCounts,
              odOpacityFactorRef,
              handleSelectZone,
              changeShowZoneCounts,
              handleSetColorScale,
              handleSetZoningLevel,
              filterZonesByRange,
              filterGatesByRange,
              changeZonesFillOpacity,
            },
          };
        }
      });
    }
  }, [
    isDataset,
    isDatasetDataAvailabe,
    isODDataAvailable,
    selectedFocusArea,
    datasetMetadata.data,
    datasetIds.data,
    ODMetadata.data,
    ODIds.data,
    zoningLevels,
    queryTypeRef,
    colorSchemeRef,
    updateODModeCounts,
    odOpacityFactorRef,
    setODModuleData,
    handleSelectZone,
    changeShowZoneCounts,
    handleSetColorScale,
    handleSetZoningLevel,
    filterZonesByRange,
    filterGatesByRange,
    changeZonesFillOpacity,
  ]);

  return null;
};
