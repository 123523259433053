import { bbox, bboxPolygon } from "@turf/turf";
import {
  CandidateIntersectionSegmentProperties,
  LoadedScreenline,
  Screenline,
  ScreenlineValidationMessage,
  SegmentIntersection,
  ValidatedSegmentIntersection,
} from "api/analytics/index.d";
import { Map } from "mapbox-gl";

import {
  SCREENLINES_INTERSECTIONS_LAYER_ID,
  SCREENLINES_SEGMENTS_LAYER_ID,
  SCREENLINES_VOLUMES_LAYER_ID,
  getIntersectionsLayerCircleColorExpression,
  getScreenlinesLeftVolumesLayerOffsetExpression,
  getScreenlinesLeftVolumesLayerWidthExpression,
  getScreenlinesRightVolumesLayerOffsetExpression,
  getScreenlinesRightVolumesLayerWidthExpression,
  getSegmentsLayerLineColorExpression,
} from "features/map/modules/screenlines/map-data/layers";
import { IntersectionGroupElemet } from "features/screenline/ScreenlineIntersection";

import { DEFAULT_ROAD_NETWORK_TYPE } from "types";

// import { getFromToSegmentIndex } from "utils/parse";

// export const getIntersectionGroupId = (fromToSegmentIndex: number, intersection: SegmentIntersection) =>
//   `${fromToSegmentIndex} ${intersection.intersection.fraction}`;

export const getIntersectionLabel = (intersection: SegmentIntersection, leftLabel: string, rightLabel: string) =>
  intersection.intersection.direction === "left" ? leftLabel : rightLabel;

export const getIntersectionGroups = (
  candidateSegments: CandidateIntersectionSegmentProperties[],
  validatedSegmentIntersections: ValidatedSegmentIntersection[],
  validationMessages: ScreenlineValidationMessage[],
  filterFractions?: number[],
) => {
  const intersectionGroups: {
    [key: string]: IntersectionGroupElemet[];
  } = {};

  candidateSegments.forEach((segment) => {
    segment.segmentIntersections.forEach((intersection) => {
      if (
        filterFractions?.length &&
        !filterFractions.find((fraction) => fraction === intersection?.intersection.fraction)
      )
        return;

      const intersectionGroupId = `${intersection.intersection.fraction}`;
      const intersectionGroupElement: IntersectionGroupElemet = {
        intersectionId: 0,
        intersection,
        candidate: true,
        resolved: true,
        segmentIdx: segment.segmentIndex,
      };
      if (!intersectionGroups[intersectionGroupId])
        intersectionGroups[intersectionGroupId] = [intersectionGroupElement];
      else intersectionGroups[intersectionGroupId].push(intersectionGroupElement);
    });
  });

  validatedSegmentIntersections.forEach((intersection) => {
    const isResolved = !!intersection.resolvedIntersection;
    if (
      filterFractions?.length &&
      !filterFractions.find(
        (fraction) =>
          fraction ===
          (isResolved
            ? intersection.resolvedIntersection?.intersection.fraction
            : intersection?.intersectionPoint.fraction),
      )
    )
      return;

    const intersectionGroupId = intersection.resolvedIntersection
      ? `${intersection.resolvedIntersection.intersection.fraction}`
      : `${intersection.intersectionPoint.fraction}`;
    const parsedIntersection = (intersection.resolvedIntersection || {
      ...intersection,
      intersection: intersection.intersectionPoint,
    }) as SegmentIntersection;
    const segmentIdx = intersection.resolvedIntersection?.segmentIndex || null;

    const intersectionGroupElement: IntersectionGroupElemet = {
      intersectionId: intersection.id,
      intersection: parsedIntersection,
      candidate: false,
      resolved: isResolved,
      segmentIdx,
      validationMessages: validationMessages.filter((msg) => msg.intersectionId === intersection.id),
    };

    if (!intersectionGroups[intersectionGroupId]) intersectionGroups[intersectionGroupId] = [intersectionGroupElement];
    else intersectionGroups[intersectionGroupId].push(intersectionGroupElement);
  });

  return Object.entries(intersectionGroups).sort(([aKey, aValue], [bKey, bValue]) => Number(aKey) - Number(bKey));
};

export const changeScreenlineLayersWidthFactor = (map: Map, widthFactor: number, maxVolume: number) => {
  map.setPaintProperty(
    `${SCREENLINES_VOLUMES_LAYER_ID}_left`,
    "line-width",
    getScreenlinesLeftVolumesLayerWidthExpression(widthFactor, maxVolume),
  );

  map.setPaintProperty(
    `${SCREENLINES_VOLUMES_LAYER_ID}_left`,
    "line-offset",
    getScreenlinesLeftVolumesLayerOffsetExpression(widthFactor, maxVolume),
  );

  map.setPaintProperty(
    `${SCREENLINES_VOLUMES_LAYER_ID}_right`,
    "line-width",
    getScreenlinesRightVolumesLayerWidthExpression(widthFactor, maxVolume),
  );

  map.setPaintProperty(
    `${SCREENLINES_VOLUMES_LAYER_ID}_right`,
    "line-offset",
    getScreenlinesRightVolumesLayerOffsetExpression(widthFactor, maxVolume),
  );
};

export const setScreenlineLayersRoadClasses = (map: Map, roadClasses: number[] | null) => {
  map.setPaintProperty(SCREENLINES_SEGMENTS_LAYER_ID, "line-color", getSegmentsLayerLineColorExpression(roadClasses));

  map.setPaintProperty(
    SCREENLINES_INTERSECTIONS_LAYER_ID,
    "circle-color",
    getIntersectionsLayerCircleColorExpression(roadClasses),
  );
};

export const getScreenlinesBboxPolygon = (screenlines: Screenline[]) => {
  const geometries = screenlines.map(({ geometry }) => geometry);
  const box = bbox({ type: "GeometryCollection", geometries });
  const polygon = bboxPolygon(box).geometry;

  return polygon;
};

export const mergeScreenlines = (screenlines: Screenline[], newScreenlines: Screenline[]) => {
  const sortedScreenlines = [...screenlines].sort((a, b) => Number(a.id) - Number(b.id));

  newScreenlines.forEach((screenline: Screenline) => {
    const lastId = Number(sortedScreenlines[sortedScreenlines.length - 1].id);
    if (sortedScreenlines.find((s) => s.id === screenline.id))
      sortedScreenlines.push({ ...screenline, id: String(lastId + 1) });
    else sortedScreenlines.push(screenline);
  });
  return sortedScreenlines;
};

export const normalizeLoadedScreenlines = (loadedScreenlines: LoadedScreenline[]): Screenline[] => {
  return loadedScreenlines.map((screenline) => ({
    ...screenline,
    visible: screenline.visible === undefined ? true : screenline.visible,
    network: screenline.network || DEFAULT_ROAD_NETWORK_TYPE,
  }));
};
